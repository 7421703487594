<template>
  <div
    class="technical-wrapper mt-c"
    style="background-image: url('img/technicalSupportLeft.png')"
  >
    <div style="padding: 5%">
      <div class="grid">
        <div class="col-12 lg:col-8">
          <div class="grid">
            <div class="col-12 lg:col-2">
              <img
                :src="getImgUrl(support.attributes.image)"
                alt="hero screen "
              />
            </div>
            <div class="col-12 lg:col-10">
              <div class="p-l-12">
                <span
                  style="
                    font-weight: 700;
                    font-size: 28px;
                    text-align: center;
                    color: #ffffff;
                  "
                  >{{ support.attributes.label }}</span
                >
                <div class="m-t-8">
                  <p style="font-weight: 400; font-size: 16px; color: #ffffff">
                    {{ support.attributes.description }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 lg:col-4 mt-a-e">
         <a :href="'contact-us'">
          <Button
            class="yellow-button"
            style="padding: 0 30px 0 30px; height: 45px"
          >
            {{ support.attributes.btnTeam }}</Button
          >
         </a>
        </div>
      </div>
      <!-- <div
        class=""
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <div style="display: flex; align-items: center">
          <img src="img/questionTech.svg" alt="hero screen" />

          <div class="m-l-24">
            <span
              class="
                font-weight-700 font-size-28
                line-height-33
                color-white
                text-center
              style="
                font-weight: 700;
                font-size: 28px;
                text-align: center;
                color: #ffffff;
              "
              >Technical Support</span
            >
            <div class="m-t-8">
              <span
                class="font-weight-400 font-size-16 line-height-24 color-white"
                >If you have some additional question, please contact us</span
              >
              <p style="font-weight: 400; font-size: 16px; color: #ffffff">
                If you have some additional question, please contact us
              </p>
            </div>
          </div>
        </div>
        <Button
          class="yellow-button justify-content-center"
          style="width: 231px; height: 45px"
          >Contact Our Team</Button
        >
      </div> -->
    </div>
  </div>
</template>

<script>
import { API_BASE_URL } from "../../config.js";
export default {
  props: ["support"],
  data() {
    return {};
  },
  methods: {
    getImgUrl(pic) {
      return API_BASE_URL + pic.data.attributes.url;
    },
  },
};
</script>
<style>
.technical-wrapper {
  border-radius: 40px;
  background-repeat: no-repeat;
  background-color: #6459a0;
}
</style>
