<template>
  <footer>
    <div class="content">
      <div class="grid">
        <div
          @click="reloadPage()"
          class="col-12 lg:col-3 md:col-6 flex flex-colusmn m-ai-c jc-text-media cursor-pointer"
        >
          <img :src="getImgUrl(footer.logo)" class="" alt="" />
          <span class="logo_name m-l-5" style="color: #fff">
            {{ footer.logo_name }}
          </span>
        </div>
        <div class="col-12 lg:col-6 flex flex-colusmn">
          <p class="font-weight-400 text-base line-height-24 color-white tm-c">
            {{ footer.description }}
          </p>
        </div>
        <div class="col-12 lg:col-3 flex flex-colusmn m-j-c">
          <Button
            @click="goToSignup()"
            class="yellow-button justify-content-center"
            style="width: 231px; height: 45px"
          >
            {{ footer.trial_button_name }}
          </Button>
        </div>
      </div>

      <hr class="opacity-01 border-white" />
      <div class="link-boxes m-t-37">
        <ul v-for="menu in footer.body" :key="menu.id" class="box">
          <li class="link_name">{{ menu.label }}</li>
          <li v-for="subMenu in menu.sub_menus.data" :key="subMenu.id">
            <a @click="$router.push({ path: subMenu.attributes.ref })">
              {{ subMenu.attributes.label }}
            </a>
          </li>
        </ul>
        <ul class="box">
          <li class="link_name">
            {{ footer.need_help_text }}
          </li>
          <li>
            <a :href="footer.customer_care_ref">
              {{ footer.customer_care_text }}
            </a>
          </li>
          <span class="link_name">
            {{ footer.contact }}
          </span>

          <li class="mt-4">
            <a :href="footer.live_support_ref">
              {{ footer.live_support_text }}
            </a>
          </li>
          <span class="link_name m-t-10 lg:text-lg text-base">
            <a :href="'mailto:' + footer.email" class="link_name">{{
              footer.email
            }}</a>
          </span>
        </ul>
        <ul class="box input-box">
          <li class="link_name">
            {{ footer.keep_in_touch_text }}
          </li>
          <span style="color: white">
            <li class="flex justify-content-between align-items-center">
              {{ footer.news_update_text }}
              <Button
                icon="pi pi-arrow-right"
                class="p-button-rounded bg-white color-black border-none hover-icon"
                @click="sendupdateNews"
              />
              <Dialog
                v-model:visible="visible"
                modal
                :style="{ width: '35vw' }"
                :breakpoints="{ '960px': '50vw', '641px': '100vw' }"
                header="Don't Miss New Updates From Us"
                class="email-subcribed-modal"
              >
                <div class="email-card">
                  <form @submit.prevent="submitForm">
                    <!-- <h2>Don't Miss New Updates From Us</h2> -->
                    <InputText
                      id="username"
                      class="w-100 mb-2 input-email"
                      type="text"
                      placeholder="Enter You Email"
                      v-model="form.email"
                      required
                    />
                    <Button
                      type="submit"
                      class="button-email-send mt-2"
                      @click="sendEmail"
                    >
                      SUBSCRIBE
                    </Button>
                  </form>
                </div>
              </Dialog>
            </li>
          </span>

          <hr class="opacity-01 border-white" />
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-evenly;
            "
          >
            <li v-for="link in footer.share" :key="link.id">
              <a target="_blank" :href="link.ref">
                <img :src="getIconUrl(link.icon)" class="mx-auto" alt="" />
              </a>
            </li>
          </div>
        </ul>
        <br />
      </div>
    </div>
    <div class="bottom-details pb-2">
      <div class="bottom_text">
        <span
          class="copyright_text md:text-base text-sm flex sm:flex-row flex-column"
        >
          {{ footer.copyright_text }} &nbsp;
          <a
            :href="footer.copyright_ref"
            style="color: #5ba5cf"
            @click="reloadPage()"
            class="cursor-pointer text-base"
          >
            {{ footer.copyright_logo_text }}
          </a></span
        >
        <span
          class="policy_terms md:text-base text-sm flex sm:flex-row flex-column"
        >
          <a
            class="cursor-pointer"
            @click="$router.push({ path: footer.privacy_ref })"
          >
            {{ footer.privacy_text }} |
          </a>
          <a
            class="cursor-pointer"
            @click="$router.push({ path: footer.terms_ref })"
            >{{ footer.terms_text }}
          </a>
        </span>
      </div>
    </div>
  </footer>
</template>
<script>
import { BASE_URL } from "../../config.js";
import { Core } from "../../api.service";
// import Strapi from "strapi-sdk-javascript";
export default {
  name: "Footer",
  data() {
    return {
      Core: Core,
      endpoint: `${BASE_URL}footer?populate=deep`,
      endpointSubcribers: `${BASE_URL}subscribers`,

      footer: [],
      visible: false,

      form: {
        email: null,
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getImgUrl(pic) {
      if (pic) {
        return Core.getImage(pic);
      }
    },
    goToSignup() {
      location.href = this.footer.trial_button_ref;
    },
    getData() {
      Core.get(this.endpoint).then((res) => {
        this.footer = res.data.attributes;
         // console.log("this.footer", this.footer);
      });
    },
    getIconUrl(pic) {
      if (pic) {
        return Core.getImage(pic);
      }
    },
    sendupdateNews() {
      this.visible = true;
      this.form.email = null;
    },
    submitForm() {
      Core.post(this.endpointSubcribers, this.form).then(() => {
        this.form.email = null;
        this.visible = false;
        this.$toast.add({
          severity: "success",
          summary: "Success Message",
          detail: "Thank You for Subscribe",
          group: "br",
          life: 3000,
        });
      });
    },
    reloadPage() {
      if (window.location.pathname == "/") {
        window.location.reload();
      } else {
        this.$router.push("/");
      }
    },
  },
  computed: {
    logoColor() {
      if (this.$appState.darkTheme) return "white";
      return "dark";
    },
  },
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
body {
  width: 100%;
}
footer {
  background: #2d3748;
  width: 100%;
  bottom: 0;
  left: 0;
}
footer .content {
  /* max-width: 1440px; */
  margin: auto;
  padding: 30px 120px 40px 120px;
  /* padding: 30px 0px 40px 0px; */
}
footer .content .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
}
.content .top .logo-details {
  color: #fff;
  font-size: 30px;
}
.content .top .media-icons {
  display: flex;
}
.content .top .media-icons a {
  height: 40px;
  width: 40px;
  margin: 0 8px;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  color: #fff;
  font-size: 17px;
  text-decoration: none;
  transition: all 0.4s ease;
}
.top .media-icons a:nth-child(1) {
  background: #4267b2;
}
.top .media-icons a:nth-child(1):hover {
  color: #4267b2;
  background: #fff;
}
.top .media-icons a:nth-child(2) {
  background: #1da1f2;
}
.top .media-icons a:nth-child(2):hover {
  color: #1da1f2;
  background: #fff;
}
.top .media-icons a:nth-child(3) {
  background: #e1306c;
}
.top .media-icons a:nth-child(3):hover {
  color: #e1306c;
  background: #fff;
}
.top .media-icons a:nth-child(4) {
  background: #0077b5;
}
.top .media-icons a:nth-child(4):hover {
  color: #0077b5;
  background: #fff;
}
.top .media-icons a:nth-child(5) {
  background: #ff0000;
}
.top .media-icons a:nth-child(5):hover {
  color: #ff0000;
  background: #fff;
}
footer .content .link-boxes {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
footer .content .link-boxes .box {
  width: calc(100% / 5 - 10px);
}
.content .link-boxes .box .link_name {
  /* font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px; */
  color: #ffffff;

  margin-bottom: 17px;
}
.content .link-boxes .box li {
  margin: 6px 0;
  list-style: none;
}
.content .link-boxes .box li a {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #d5d7da;
  cursor: pointer;
}
.content .link-boxes .box li a:hover {
  opacity: 1;
  text-decoration: underline;
}
.content .link-boxes .input-box {
  margin-right: 55px;
}
.link-boxes .input-box input {
  height: 40px;
  width: calc(100% + 55px);
  outline: none;
  border: 2px solid #afafb6;
  background: #140b5c;
  border-radius: 4px;
  padding: 0 15px;
  font-size: 15px;
  color: #fff;
  margin-top: 5px;
}
.link-boxes .input-box input::placeholder {
  color: #afafb6;
  font-size: 16px;
}
.link-boxes .input-box input[type="button"] {
  background: #fff;
  color: #140b5c;
  border: none;
  font-size: 18px;
  font-weight: 500;
  margin: 4px 0;
  opacity: 0.8;
  cursor: pointer;
  transition: all 0.4s ease;
}
.input-box input[type="button"]:hover {
  opacity: 1;
}
footer .bottom-details {
  /* width: 100%; */
  padding: 0px 120px;
  /* background: #0f0844; */
}
footer .bottom-details .bottom_text {
  /* max-width: 1250px; */
  margin: auto;
  /* padding: 20px 120px; */
  display: flex;
  justify-content: space-between;
}
.bottom-details .bottom_text span,
.bottom-details .bottom_text a {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  // font-size: 14px;
  line-height: 16px;
  color: #d5d7da;
}
.bottom-details .bottom_text a:hover {
  opacity: 1;
  text-decoration: underline;
}
.bottom-details .bottom_text a {
  margin-right: 10px;
}
@media (max-width: 1096px) {
  footer .content {
    padding: 30px 60px 40px 60px;
  }
  footer .bottom-details {
    padding: 20px 60px;
  }
}
@media (max-width: 990px) {
  footer .content {
    padding: 30px 40px 40px 40px;
  }
  footer .bottom-details {
    padding: 20px 40px;
  }
  footer .content .link-boxes {
    flex-wrap: wrap;
    padding: 10px;
  }
  footer .content .link-boxes .input-box {
    width: 40%;
    margin-top: 10px;
  }
}
@media (max-width: 700px) {
  footer {
    position: relative;
  }
  .content .top .logo-details {
    font-size: 26px;
  }
  .content .top .media-icons a {
    height: 35px;
    width: 35px;
    font-size: 14px;
    line-height: 35px;
  }
  footer .content .link-boxes .box {
    width: calc(100% / 3 - 10px);
  }
  footer .content .link-boxes .input-box {
    width: 60%;
  }
  .bottom-details .bottom_text span,
  .bottom-details .bottom_text a {
    // font-size: 12px;
  }
}
@media (max-width: 520px) {
  footer::before {
    top: 145px;
  }
  footer .content .top {
    flex-direction: column;
  }
  .content .top .media-icons {
    margin-top: 16px;
  }
  footer .content .link-boxes .box {
    width: calc(100% / 2 - 10px);
  }
  footer .content .link-boxes .input-box {
    width: 100%;
  }
}
/** CSS using pseudo-elements: **/
@media (max-width: 390px) {
  footer .content {
    padding: 30px 10px 0px 10px !important;
  }
  .content .link-boxes {
    flex-direction: column;
  }
  // footer .content .link-boxes .input-box {
  //   width: calc(100% / 2 - 20px);
  // }
  footer .content .link-boxes .box {
    width: calc(100% - 50px);
  }
}
#A {
  background: #0074d9;
}

#B {
  background: #7fdbff;
}

#A::after {
  content: "";
  position: relative;
  left: -3rem;
  /* padding * -1 */
  top: calc(3rem - 4rem / 2);
  /* padding - height/2 */
  float: left;
  display: block;
  height: 4rem;
  width: 100vw;
  background: hsla(0, 0%, 100%, 0.5);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 70 500 60' preserveAspectRatio='none'%3E%3Crect x='0' y='0' width='500' height='500' style='stroke: none; fill: %237FDBFF;' /%3E%3Cpath d='M0,100 C150,200 350,0 500,100 L500,00 L0,0 Z' style='stroke: none; fill: %230074D9;'%3E%3C/path%3E%3C/svg%3E");
  background-size: 100% 100%;
}

/** Cosmetics **/

* {
  margin: 0;
}

#A,
#B {
  padding: 3rem;
}

div {
  font-family: monospace;
  font-size: 1.2rem;
  line-height: 1.2;
}

#A {
  color: white;
}
.p-button:enabled:active {
  background: none !important;
}
.email-card {
  padding: 1rem;
  margin: 0 auto;
  text-align: center;
}

.img-email {
  width: 100%;
  margin-bottom: 1rem;
}

.button-email-send {
  background: #485096;
  border-radius: 100px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
  width: 15rem;
  color: white !important;
  justify-content: center;
}

.input-email {
  background: #e9ecef;
  border-radius: 10px !important;
}

.email-subcribed-modal {
  .p-dialog-content {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}
</style>
