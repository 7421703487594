<template>
  <div v-if="detail">
    <div class="py-4 px-4 lg:px-8 mx-0 lg:mx-6">
      <div class="lg:mt-4 pb-2 md:pb-8 lg:flex text-center">
        <div
          class="col-12 lg:col-6 my-auto flex flex-column md:px-8 lg:px-0 lg:text-left"
        >
          <span class="section-heading lg:text-4xl text-3xl">
            {{ detail.integrations.heading }}
          </span>
          <span
            class="font-weight-400 font-size-16 line-height-24 body-text-color m-t-24"
          >
            {{ detail.integrations.description }}
          </span>

          <span
            class="font-weight-400 font-size-16 line-height-24 body-text-color m-t-24"
            >{{ detail.integrations.subDescription }}
          </span>
        </div>
        <div
          class="col-12 lg:col-6 p-0 flex-order-1 lg:flex-order-0 lg:text-right"
        >
          <img
            :src="Core.getImage(detail.integrations.image)"
            class="mx-auto lg:w-10 w-9 md:w-7 lg:mt-4 mt-1"
          />
        </div>
      </div>

      <div
        class="lg:mt-4 pb-2 md:pb-8 flex flex-column lg:flex-row text-center lg:text-left"
      >
        <div class="col-12 lg:col-6 p-0 flex-order-2 lg:flex-order-1">
          <img
            :src="Core.getImage(detail.inventory.image)"
            class="mx-auto lg:w-10 w-9 md:w-7 lg:mt-4 mt-1"
          />
        </div>
        <!-- style="width: 100%" -->

        <div
          class="col-12 lg:col-6 my-auto flex flex-column md:px-8 lg:px-0 flex-order-1 lg:flex-order-2"
        >
          <span class="section-heading lg:text-4xl text-3xl">
            {{ detail.inventory.heading }}
          </span>
          <span
            class="font-weight-400 font-size-16 line-height-24 body-text-color m-t-24"
          >
            {{ detail.inventory.description }}
          </span>

          <span
            class="font-weight-400 font-size-16 line-height-24 body-text-color m-t-24"
            >{{ detail.inventory.subDescription }}
          </span>
        </div>
      </div>

      <div class="grid justify-content-around md:mt-0 md:p-3">
        <a class="m-tria-btn">
          <Button
            :label="detail.button_3"
            class="yellow-button px-6 py-3"
            @click="goTo(detail.button_1_ref)"
          ></Button
        ></a>
      </div>

      <div class="lg:mt-4 pb-2 md:pb-8 lg:flex text-center">
        <div
          class="col-12 lg:col-6 my-auto flex flex-column md:px-8 lg:px-0 lg:text-left"
        >
          <span class="section-heading">
            {{ detail.listings.heading }}
          </span>
          <span
            class="font-weight-400 font-size-16 line-height-24 body-text-color m-t-24"
          >
            {{ detail.listings.description }}
          </span>

          <span
            class="font-weight-400 font-size-16 line-height-24 body-text-color m-t-24"
            >{{ detail.listings.subDescription }}
          </span>
        </div>
        <div
          class="col-12 lg:col-6 p-0 flex-order-1 lg:flex-order-0 lg:text-right"
        >
          <img
            :src="Core.getImage(detail.listings.image)"
            class="mx-auto lg:w-10 w-9 md:w-7 lg:mt-4 mt-1"
          />
        </div>
      </div>

      <div
        class="lg:mt-4 pb-2 md:pb-8 flex flex-column lg:flex-row text-center lg:text-left"
      >
        <div class="col-12 lg:col-6 p-0 flex-order-2 lg:flex-order-1">
          <img
            :src="Core.getImage(detail.bundles.image)"
            class="mx-auto lg:w-10 w-9 md:w-7 lg:mt-4 mt-1"
          />
        </div>
        <!-- style="width: 100%" -->

        <div
          class="col-12 lg:col-6 my-auto flex flex-column md:px-8 lg:px-0 flex-order-1 lg:flex-order-2"
        >
          <span class="section-heading lg:text-4xl text-3xl">
            {{ detail.bundles.heading }}
          </span>
          <span
            class="font-weight-400 font-size-16 line-height-24 body-text-color m-t-24"
          >
            {{ detail.bundles.description }}
          </span>

          <span
            class="font-weight-400 font-size-16 line-height-24 body-text-color m-t-24"
            >{{ detail.bundles.subDescription }}
          </span>
        </div>
      </div>

      <div class="grid justify-content-around md:mt-0 md:p-3">
        <a class="">
          <Button
            :label="detail.button_2"
            class="yellow-button px-6 py-3"
            @click="goTo(detail.button_2_ref)"
          ></Button
        ></a>
      </div>

      <div class="lg:mt-4 pb-2 md:pb-8 lg:flex text-center">
        <div
          class="col-12 lg:col-6 my-auto flex flex-column md:px-8 lg:px-0 lg:text-left"
        >
          <span class="section-heading lg:text-4xl text-3xl">
            {{ detail.reporting.heading }}
          </span>
          <span
            class="font-weight-400 font-size-16 line-height-24 body-text-color m-t-24"
          >
            {{ detail.reporting.description }}
          </span>

          <span
            class="font-weight-400 font-size-16 line-height-24 body-text-color m-t-24"
            >{{ detail.reporting.subDescription }}
          </span>
        </div>
        <div
          class="col-12 lg:col-6 p-0 flex-order-1 lg:flex-order-0 lg:text-right"
        >
          <img
            :src="Core.getImage(detail.reporting.image)"
            class="mx-auto lg:w-10 w-9 md:w-7 lg:mt-4 mt-1"
          />
        </div>
      </div>

      <div
        class="lg:mt-4 pb-2 md:pb-8 flex flex-column lg:flex-row text-center lg:text-left"
      >
        <div class="col-12 lg:col-6 p-0 flex-order-2 lg:flex-order-1">
          <img
            :src="Core.getImage(detail.automation.image)"
            class="mx-auto lg:w-10 w-9 md:w-7 lg:mt-4 mt-1"
          />
        </div>
        <!-- style="width: 100%" -->

        <div
          class="col-12 lg:col-6 my-auto flex flex-column md:px-8 lg:px-0 flex-order-1 lg:flex-order-2"
        >
          <span class="section-heading lg:text-4xl text-3xl">
            {{ detail.automation.heading }}
          </span>
          <span
            class="font-weight-400 font-size-16 line-height-24 body-text-color m-t-24"
          >
            {{ detail.automation.description }}
          </span>

          <span
            class="font-weight-400 font-size-16 line-height-24 body-text-color m-t-24"
            >{{ detail.automation.subDescription }}
          </span>
        </div>
      </div>

      <div class="grid justify-content-around md:mt-0 md:p-3">
        <a class="">
          <Button
            :label="detail.button_3"
            class="yellow-button px-6 py-3"
            @click="goTo(detail.button_3_ref)"
          ></Button
        ></a>
      </div>

      <!--  -->
      <!-- <div class="grid mt-8 pb-2 md:pb-8">
        <div
          @mousemove="Core.onMousemove('container1', 'inner1')"
          id="container1"
          class="border-radius-8 col-12 lg:col-6 p-0 flex-order-1 lg:flex-order-0"
        >
          <img
            id="inner1"
            :src="Core.getImage(detail.pick_pack.image)"
            class="mx-auto w-10"
            />
          </div>

        <div class="col-12 lg:col-6 my-auto flex flex-column">
          <span class="section-heading">
            {{ detail.pick_pack.heading }}
          </span>
          <span
            class="font-weight-400 font-size-16 line-height-24 body-text-color m-t-24"
            style="max-width: 650px"
          >
            {{ detail.pick_pack.description }}
          </span>

          <span
            class="font-weight-400 font-size-16 line-height-24 body-text-color m-t-24"
            style="max-width: 650px"
            >{{ detail.pick_pack.subDescription }}
          </span>
        </div>
      </div> -->

      <!-- <div class="grid">
        <div
          class="col-12 lg:col-6 my-auto flex flex-column lg:align-items-start align-items-center"
        >
          <span class="section-heading">
            {{ detail.integrate.heading }}
          </span>
          <span class="section-body"> {{ detail.integrate.description }} </span>

          <span class="section-body"
            >{{ detail.integrate.subDescription }}
          </span>
        </div>

        <div
          class="flex justify-content-end flex-order-1 sm:flex-order-2 col-12 lg:col-6 p-0"
          style="border-radius: 8px"
          id="container2"
          @mousemove="Core.onMousemove('container2', 'inner2')"
        >
          <img
            :src="Core.getImage(detail.integrate.image)"
            class="mx-auto w-10"
            id="inner2"
            alt=""
          />
        </div>
      </div> -->
      <!-- </div>
    <div class="py-4 px-4 lg:px-8 mx-0 lg:mx-8">
      <div class="grid mt-8 pb-2 md:pb-8">
        <div
          class="col-12 lg:col-6 p-0 flex-order-1 lg:flex-order-0"
          style="border-radius: 8px"
          id="container3"
          @mousemove="Core.onMousemove('container3', 'inner3')"
        >
          <img
            :src="Core.getImage(detail.upload_variety.image)"
            class="mx-auto w-10"
            id="inner3"
            alt=""
          />
        </div>

        <div class="col-12 lg:col-6 my-auto flex flex-column">
          <span
            class="font-weight-700 font-size-32 line-height-38 body-text-color m-t-24 font-color-text"
          >
            {{ detail.upload_variety.heading }}
          </span>
          <span
            class="font-weight-400 font-size-16 line-height-24 body-text-color m-t-24"
            style="max-width: 650px"
          >
          </span>

          <span
            class="font-weight-400 font-size-16 line-height-24 body-text-color m-t-24"
            style="max-width: 650px"
          >
            {{ detail.upload_variety.subDescription }}
          </span>
        </div>
      </div>

      <div class="grid">
        <div
          class="col-12 lg:col-6 my-auto flex flex-column lg:align-items-start align-items-center"
        >
          <span class="section-heading">
            {{ detail.better_control.heading }}</span
          >
          <span class="section-body"
            >{{ detail.better_control.description }}
          </span>

          <span class="section-body"
            >{{ detail.better_control.subDescription }}
          </span>
        </div>

        <div
          class="flex justify-content-end flex-order-1 sm:flex-order-2 col-12 lg:col-6 p-0 border-radius-8"
          id="container4"
          @mousemove="Core.onMousemove('container4', 'inner4')"
        >
          <img
            :src="Core.getImage(detail.better_control.image)"
            id="inner4"
            alt=""
            class="w-10"
          />
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { BASE_URL } from "../../config.js";
import { Core } from "../../api.service";
export default {
  name: "PickAndPack",
  data() {
    return {
      Core: Core,
      endpoint: `${BASE_URL}home-detail?populate=deep`,
      detail: null,
    };
  },
  created() {
    this.getHomeDetail();
  },
  methods: {
    goTo(link) {
      window.open(
        link,
        "_blank" // <- This is what makes it open in a new window.
      );
      // location.href = link
    },
    getHomeDetail() {
      Core.get(this.endpoint).then((res) => {
        this.detail = res.data.attributes;
         // console.log("detail-detail", this.detail);
      });
    },
  },
};
</script>

<style>
#container1 {
  perspective: 50px;
}

#inner1 {
  transition: transform 0.5s;
  -webkit-transition: transform 0.5s;
}
#container1:hover {
  cursor: pointer;
}

#container4 {
  perspective: 30px;
}

#inner4 {
  transition: transform 0.5s;
  -webkit-transition: transform 0.5s;
}

#container4:hover {
  cursor: pointer;
}
#container3 {
  perspective: 30px;
}

#inner3 {
  transition: transform 0.5s;
  -webkit-transition: transform 0.5s;
}

#container3:hover {
  cursor: pointer;
}
#container2 {
  perspective: 30px;
}

#inner2 {
  transition: transform 0.5s;
  -webkit-transition: transform 0.5s;
}

#container2:hover {
  cursor: pointer;
}
#container1:hover .demo-overlay {
  opacity: 1;
}
@media screen and (max-width: 600px) {
  .w100 {
    width: 100% !important;
  }
}
</style>
