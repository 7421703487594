<template>
  <div class="px-4 lg:px-8 support-section-conatiner" v-if="list">
    <div class="py-3">
      <span>{{ list.values.length }} </span><span> Search results for: </span
      ><span
        ><b>{{ list.key }}</b></span
      >
    </div>

    <div
      class="col-12 lg:col-12 md:p-3 p-b-15"
      v-for="item in list.values"
      :key="item"
    >
      <div
        class="flex align-items-center border-200 marketplace"
        style="border-radius: 22px"
      >
        <div>
          <span
            class="font-weight-500 font-size-18 line-height-21 font-color-text"
            >{{ item.attributes.heading }}
          </span>
          <br />
          <br />
          <span>{{ item.attributes.description }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["list"],
  data() {
    return {};
  },
  mounted() {},
};
</script>
