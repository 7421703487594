<template>
  <div class="preloader" v-if="loading">
    <div class="preloader-content"></div>
  </div>
  <div class="" v-else>
    <HeroCommon v-if="blog" :data="blog.header" />
    <CustomerStoryDetail v-if="blogDetail" :data="blogDetail"/>
    <CustomerStory v-if="blog" :data="blog" />
  </div>
</template>

<script>
import CustomerStory from "../components/customerStory/CustomerStory.vue";
import CustomerStoryDetail from "../components/customerStory/CustomerStoryDetail.vue";
import HeroCommon from "../components/header/HeroCommon.vue";
import { BASE_URL } from "../config.js";
import { Core } from "../api.service";
export default {
  data() {
    return {
      endpointBlog: `${BASE_URL}customer-story-section?populate=deep`,

      blog: null,
      blogId: null,
      blogDetail: null,
      loading: false,
    };
  },
  components: {
    CustomerStory,
    CustomerStoryDetail,
    HeroCommon,
  },
  methods: {
    async getData() {
      this.loading = true;
      await Core.get(this.endpointBlog).then((res) => {
        this.blog = res.data.attributes;
         // console.log("customer-section", this.blog);
      });
      await Core.get(`${BASE_URL}customer-stories/${this.blogId}?populate=deep`).then((res) => {
        this.blogDetail = res.data.attributes;
         // console.log("blog-blogDetail", this.blogDetail);
      });
      this.loading = false;
    },
  },
  computed: {},
  created() {
    this.blogId = this.$route.params.id;
    this.getData();
  },
};
</script>

<style lang="scss"></style>
