<template>
  <Toast position="bottom-right" group="br" />
  <div class="py-4 lg:px-8 md:px-6 px-4 price-section-conatiner" v-if="pricing">
    <div class="text-center lg:px-8 px-3">
      <h2
        class="font-weight-400 text-base line-height-19 text-transform-uppercase body-text-color"
      >
        {{ pricing.heading }}
      </h2>
      <span class="font-weight-700 text-4xl line-height-38 font-color-text"
        >{{ pricing.subHeading }}
      </span>
      <div style="margin-top: 15px">
        <span
          class="font-weight-400 text-base line-height-24 body-text-color"
          >{{ pricing.description }}</span
        >
      </div>
    </div>

    <div class="my-4" style="display: flex; justify-content: center">
      <div class="pricing-toggle h-4rem px-1">
        <div
          @click="billMethod(true)"
          :class="[isMonthly ? monthlyClass : yearlyClass, 'cursor-pointer']"
          class="lg:px-5 md:px-3 sm:px-1 px-3 py-3 text-base"
        >
          {{ pricing.button_left }}
        </div>
        <div
          @click="billMethod(false)"
          :class="[isMonthly ? yearlyClass : monthlyClass, 'cursor-pointer']"
          class="lg:px-5 md:px-3 sm:px-1 px-3 py-3 text-base"
        >
          {{ pricing.button_right }}
        </div>
      </div>
    </div>
    <div style="margin: 74px 0px 33px 0px">
      <vue-slider
        v-model="value"
        :tooltip="'always'"
        :min="0"
        :max="2000"
        :interval="100"
        :tooltip-formatter="formatter1"
        style="padding: 7px 12px"
      />
    </div>
    <!-- <Transition  name="nested"> -->
    <div
      class="flex flex-row flex-wrap card-container justify-content-between md:mt-0"
    >
      <!-- <div v-if="show" class="flex flex-row flex-wrap card-container justify-content-between md:mt-0 "> -->
      <div
        v-for="(item, index) in pricingPlan"
        :key="index"
        class="col-12 xl:col-3 lg:col-6 md:col-6 p-0 md:p-3 my-2"
      >
        <!-- @mouseover="item.upHere = true"
          @mouseleave="item.upHere = false" -->

        <div
          class="flex flex-column plan-section"
          style="height: 100%"
          :class="{ 'price-hover': item.attributes.upHere }"
        >
          <div class="flex">
            <span class="price-heading">{{ item.attributes.heading }}</span>
            <div v-if="item.attributes.popular" class="popular-tag">
              <span
                class="font-weight-400 font-size-14 line-height-16 color-white"
              >
                Popular</span
              >
            </div>
          </div>
          <!-- <h3 class="price-heading">{{item.title}}</h3> -->

          <div style="height: 100%">
            <div
              v-if="item.attributes.customize_label"
              style="margin-top: 16px; margin-bottom: 20px"
            >
              <span class="dollar-price">{{
                item.attributes.customize_label
              }}</span>
            </div>
            <div v-else style="margin-top: 16px; margin-bottom: 20px">
              <span class="dollar-price">${{ item.attributes.price }}</span>
              <span class="price-month"> / {{ item.attributes.type }}</span>
            </div>

            <!-- <Divider class="w-full bg-surface-200"></Divider> -->

            <ul
              class="list-none p-0 flex text-900 flex-column"
              v-for="price in item.attributes.pricing_features"
              :key="price"
            >
              <li class="py-2 flex-center">
                <span class="tick-icon">
                  <img src="img/tik.svg" class="mx-auto" alt="" />
                </span>

                <span class="list-item m-l-15">{{ price.label }} </span>
              </li>
              <!-- <li class="py-2 flex-center">
                <span class="tick-icon">
                  <img src="img/tik.svg" class="mx-auto" alt="" />
                </span>
  
                <span class="list-item m-l-15">{{ item.products }} Products</span>
              </li>
              <li class="py-2 flex-center">
                <span class="tick-icon">
                  <img src="img/tik.svg" class="mx-auto" alt="" />
                </span>
  
                <span class="list-item m-l-15">Unlimited Variations</span>
              </li>
              <li class="py-2 flex-center">
                <span class="tick-icon">
                  <img src="img/tik.svg" class="mx-auto" alt="" />
                </span>
  
                <span class="list-item m-l-15"
                  >{{ item.stores }} Stores & Marketplaces</span
                >
              </li>
              <li class="py-2 flex-center">
                <span class="tick-icon">
                  <img src="img/tik.svg" class="mx-auto" alt="" />
                </span>
  
                <span class="list-item m-l-15">{{ item.users }} User</span>
              </li> -->
            </ul>
          </div>
          <div class="text-center">
            <Button
              :label="item.attributes.choose_button_text"
              class="block mx-auto border-none ml-3 font-light text-white line-height-2 plan-btn"
              @click="popup(item.attributes, index)"
            >
            </Button>
          </div>
        </div>
      </div>
    </div>
    <Dialog
      v-model:visible="showPopup"
      modal
      header="Tell us who you are and how our
sales team can help."
      :style="{ width: '50vw' }"
      :breakpoints="{ '960px': '75vw', '641px': '100vw' }"
      @update:visible="hideHandler"
      dismissableMask="true"
    >
      <div class="col-12 lg:col-12">
        <form @submit.prevent="submit">
          <div class="grid">
            <div class="col-12 lg:col-6 contact-form">
              <div class="mb-6">
                <span class="p-float-label">
                  <InputText
                    id="username"
                    class="w-100"
                    type="text"
                    v-model="form.name"
                    required
                  />
                  <label for="username">Name *</label>
                </span>
              </div>
              <div class="my-6">
                <span class="p-float-label">
                  <InputText
                    id="username"
                    class="w-100"
                    type="text"
                    v-model="form.company"
                  />
                  <label for="username">Company Name</label>
                </span>
              </div>
              <div class="my-6">
                <span class="p-float-label">
                  <InputText
                    id="username"
                    class="w-100"
                    type="text"
                    v-model="form.role"
                  />
                  <label for="username">Your Role</label>
                </span>
              </div>
              <div class="my-6">
                <span class="p-float-label">
                  <InputText
                    id="username"
                    class="w-100"
                    type="text"
                    v-model="form.products"
                  />
                  <label for="username">Products</label>
                </span>
              </div>
              <div class="my-6">
                <span class="p-float-label">
                  <InputText
                    id="username"
                    class="w-100"
                    type="text"
                    v-model="form.stores"
                  />
                  <label for="username">Stores & Marketplaces</label>
                </span>
              </div>
            </div>
            <div class="col-12 lg:col-6 contact-form">
              <div class="">
                <span class="p-float-label">
                  <InputText
                    class="w-100"
                    id="username"
                    type="email"
                    v-model="form.email"
                    required
                  />
                  <label for="username">Email *</label>
                </span>
              </div>
              <div class="lg:mt-2 pt-1 my-5">
                <!-- <span class="p-float-label"> -->
                <label class="ml-2 p-1 phone-label">Phone number</label>
                <span class="p-float-label">
                  <vue-tel-input
                    v-model="form.phone_no"
                    id=""
                    mode="international"
                    :inputOptions="phoneInputOptions"
                    class="p-inputtext p-component w-100"
                  >
                  </vue-tel-input>
                  <!-- <InputText
                    id="username"
                    class="w-100"
                    type="text"
                    v-model="form.phone_no"
                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                  />
                  <label for="username">Phone number</label> -->
                </span>
              </div>
              <div class="my-5 pt-1">
                <span class="p-float-label">
                  <InputText
                    id="username"
                    class="w-100"
                    type="text"
                    v-model="form.orders"
                  />
                  <label for="username">Orders Per Month</label>
                </span>
              </div>
              <div class="my-6">
                <span class="p-float-label">
                  <InputText
                    id="username"
                    class="w-100"
                    type="text"
                    v-model="form.variations"
                  />
                  <label for="username">Variations</label>
                </span>
              </div>
              <div class="my-6">
                <span class="p-float-label">
                  <InputText
                    id="username"
                    class="w-100"
                    type="text"
                    v-model="form.users"
                  />
                  <label for="username">Users</label>
                </span>
              </div>
            </div>
            <div class="col-12 lg:col-12 contact-form">
              <div class="">
                <span class="p-float-label">
                  <Textarea
                    rows="5"
                    id="username"
                    type="text"
                    v-model="form.message"
                    class="w-100"
                    autoResize
                  />
                  <label for="username">Enter your Message...</label>
                </span>
              </div>
            </div>
          </div>
          <div class="my-5">
            <Button type="submit" class="pricing-contact-button text-center"
              >Send message</Button
            >
          </div>
        </form>
      </div>
    </Dialog>
    <!-- </Transition> -->
  </div>
</template>

<script>
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";
import { BASE_URL } from "../../config.js";
import { Core } from "../../api.service";
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
export default {
  name: "PricingPlan",
  components: { VueSlider, VueTelInput },
  data() {
    return {
      scrollpx: 0,
      show: false,
      pricing: null,
      value: null,
      formatter1: "{value}\norders",
      isMonthly: true,
      upHere: false,
      monthlyClass: "bill-monthly",
      yearlyClass: "bill-yearly",
      pricingPlan: [],
      endpoint: `${BASE_URL}choose-plan?populate=deep`,
      endpointyearly: `${BASE_URL}choose-plan-yearly?populate=deep`,
      endpointContact: `${BASE_URL}contact-us-for-plans`,
      showPopup: false,
      phoneInputOptions: {
        placeholder: "",
        styleClasses: ["p-inputtext", "p-component", "w-100"],
        id: "username",
      },
      form: {
        name: "",
        email: "",
        company: "",
        phone_no: "",
        role: "",
        orders: "",
        products: "",
        variations: "",
        stores: "",
        users: "",
        // contact_us_subjects: [],
        message: "",
      },
      monthlyPricingPlan: [
        {
          title: "Personal",
          isPopular: false,
          cost: 99,
          orders: 500,
          products: 50,
          stores: 3,
          users: 100,
          upHere: false,
        },
        {
          title: "Professional",
          isPopular: true,
          cost: 199,
          orders: 1000,
          products: 100,
          stores: 5,
          users: 200,
          upHere: false,
        },
        {
          title: "Team",
          isPopular: false,
          cost: 299,
          orders: 1500,
          products: 150,
          stores: 7,
          users: 300,
          upHere: false,
        },
        {
          title: "Enterprise",
          isPopular: false,
          cost: 399,
          orders: 2000,
          products: 150,
          stores: 10,
          users: 500,
          upHere: false,
        },
      ],
      yearlyPricingPlan: [
        {
          title: "Personal",
          isPopular: true,
          cost: 990,
          orders: 3000,
          products: 500,
          stores: 30,
          users: 1000,
        },
        {
          title: "Professional",
          isPopular: true,
          cost: 1990,
          orders: 4000,
          products: 1000,
          stores: 50,
          users: 2000,
        },
        {
          title: "Team",
          isPopular: false,
          cost: 2990,
          orders: 4000,
          products: 1500,
          stores: 70,
          users: 3000,
        },
        {
          title: "Enterprise",
          isPopular: false,
          cost: 3990,
          orders: 5000,
          products: 1500,
          stores: 100,
          users: 5000,
        },
      ],
    };
  },
  created() {
    this.getPricing();
    // console.log(this.$route.query)
    if(this.$route.query.popup){
      // console.log(this.$route.query.popup) // outputs 'pgl-wl-plans-pop-up'
      this.showPopup = true;

    }
    window.addEventListener("scroll", this.handleScroll);
  },
  mounted() {
    this.pricingPlan = this.monthlyPricingPlan;
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    async submit() {
       // console.log("data is", this.form);
       // console.log(this.subject);
      // this.form.contact_us_subjects = [this.subject.id];
      await Core.post(this.endpointContact, this.form).then((res) => {
         console.log("output", res);
        this.$toast.add({
          severity: "success",
          summary: "Success Message",
          detail: "Message Sent",
          group: "br",
          life: 3000,
        });
      });
      this.showPopup = false;
      this.form = {
        name: "",
        email: "",
        company: "",
        phone_no: "",
        role: "",
        orders: "",
        products: "",
        variations: "",
        stores: "",
        users: "",
        // contact_us_subjects: [],
        message: "",
      };
    },
    popup(data, index) {
      // console.log("popup  ", text, index);
      if (index == 3) {
        // console.log("");
        this.showPopup = true;
      } else {
        location.href = data.choose_plan_ref;
      }
    },
    hideHandler(value) {
       // console.log("value", value);

      if (!value) {
        this.showPopup = false;
        this.form = {
          name: "",
          email: "",
          company: "",
          phone_no: "",
          role: "",
          orders: "",
          products: "",
          variations: "",
          stores: "",
          users: "",
          // contact_us_subjects: [],
          message: "",
        };
      }
    },
    handleScroll() {
      this.scrollpx = window.scrollY;
      // console.log('scroll',this.scrollpx)
      if (this.scrollpx > 3000) {
        this.show = true;
        // console.log('scrolltt',this.show)
      }
    },
    billMethod(monthly) {
      if (monthly) {
        this.isMonthly = true;
        Core.get(this.endpoint).then((res) => {
          this.pricing = res.data.attributes;
          this.pricingPlan = this.pricing.pricing_plans.data.filter(
            (data) => data.attributes.type == "month"
          );
          this.triggerLog(this.pricing.orders);
          this.value = this.pricing.orders;
        });
      } else {
        this.isMonthly = false;
        Core.get(this.endpoint).then((res) => {
          this.pricing = res.data.attributes;
          this.pricingPlan = this.pricing.pricing_plans.data.filter(
            (data) => data.attributes.type == "year"
          );
          this.triggerLog(this.pricing.orders);
          this.value = this.pricing.orders;
        });
      }
    },
    triggerLog(value) {
       // console.log("value-value", value);
      this.pricing.pricing_plans.data.forEach((element) => {
        if (element.attributes.orders == value) {
          element.attributes.upHere = true;
        } else {
          element.attributes.upHere = false;
        }
      });
    },
    async getPricing() {
      await Core.get(this.endpoint).then((res) => {
        this.pricing = res.data.attributes;
        this.pricingPlan = this.pricing.pricing_plans.data.filter(
          (data) => data.attributes.type == "month"
        );
        this.triggerLog(this.pricing.orders);
        this.value = this.pricing.orders;
      });
    },
  },
  computed: {},

  watch: {
    value(newValue) {
      this.triggerLog(newValue);
    },
  },
};
</script>

<style lang="scss">
.pricing-toggle {
  // height: 55px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 100px;
  background: #ffffff;
}
.nested-enter-active,
.nested-leave-active {
  transition: all 1s ease-in-out;
}
/* delay leave of parent element */
.nested-leave-active {
  transition-delay: 0.25s;
}

.nested-enter-from,
.nested-leave-to {
  transform: translateY(30px);
  opacity: 0;
}

/* we can also transition nested elements using nested selectors */
.nested-enter-active .inner,
.nested-leave-active .inner {
  transition: all 0.3s ease-in-out;
}
.nested-enter-active .inner {
  transition-delay: 0s;
}

.nested-enter-from .inner,
.nested-leave-to .inner {
  transform: translateX(60px);

  opacity: 0.001;
}
.p-dialog {
  .p-dialog-header {
    padding: 2% 5% 0% 5%;
    .p-dialog-title {
      padding: 2%;
      font-weight: 700;
      font-size: 2rem;
    }
  }
  .p-dialog-content {
    padding: 2% 5%;
  }
}
.p-dialog-header-icons {
  border: #2d3748 solid 1px;
  border-radius: 100%;
}
// .p-dialog-title{
//   font-weight: 500;
// font-size: 32px;
// line-height: 38px;
// }
.pricing-contact-button {
  margin: 15px 15px 15px 0px;
  background: #485096;
  border-radius: 100px;
  border: none;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;

  text-align: center;
}
.pricing-contact-button:hover {
  background: #485096;
  border-radius: 100px;
}
</style>
