<template>
  <div class="px-4 lg:px-8 text-center p-t-31 lg:pb-8 pb-4" style="
        background: linear-gradient(
          180deg,
          #f6f4ff 0%,
          rgba(246, 244, 255, 0.6) 92.51%,
          rgba(246, 243, 255, 0) 100%
        );
      ">
    <div class="lg:col-8 col-12 mx-auto">
      <h2
        class="font-weight-400 md:text-base text-sm line-heigh-19 ta-center body-text-color"
      >
        {{ platform.attributes.platform_component.heading }}
      </h2>
      <span class="font-weight-700 md:text-4xl text-3xl ta-center font-color-text">
        {{ platform.attributes.platform_component.subHeading }}
      </span>
      <div class="m-t-15">
        <span
          class="font-weight-400 md:text-base text-sm line-height-19 body-text-color ta-center"
          >{{ platform.attributes.platform_component.description }}</span
        >
      </div>
    </div>
    <div class="col-12 lg:col-6 mt-5 mx-auto">
      <Button
        :label="platform.attributes.platform_component.btn_try"
        class="yellow-button"
        @click="tryFree(platform.attributes.platform_component.ref)"
      ></Button>
    </div>
  </div>
</template>

<script>
import "vue-slider-component/theme/antd.css";
export default {
  name: "Connection",
  components: {},
  props: ["platform"],
  data() {
    return {
      value: 500,
      formatter1: "{value}\norders",
    };
  },
  methods: {
    tryFree(url) {
      window.open(url);
    },
  },
  computed: {},
};
</script>

<style lang="scss">
// .section-conatiner {
//   // background: rgb(246, 244, 255);
// }
</style>
