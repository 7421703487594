<template>
  <div v-if="ourClient">
    <div class="py-4 px-4 lg:px-8 my-2 md:my-4">
      <div class="text-center">
        <span
          class="font-weight-400 text-base line-height-19 text-align-center body-text-color text-transform-uppercase"
        >
          {{ ourClient.heading }}
        </span>

        <div class="m-t-12">
          <span class="section-heading lg:text-4xl text-3xl">{{ ourClient.sub_heading }} </span>
        </div>
      </div>
      <div class="grid justify-content-center p-70">
        <div
          v-for="data in ourClient.clients.data"
          :key="data"
          class="col-12 md:col-6 lg:col-3 p-0 mt-4 lg:mt-0 bg-white flex align-items-center justify-content-center border-default height-199"
        >
          <div
            class="p-2 text-align-center border-radius-10"
            v-if="data.attributes.image.data"
          >
            <div class="p-3 surface-card h-full border-radius-8">
              <div class="flex align-items-center justify-content-center mb-3">
                <img
                  :src="getImgUrl(data.attributes.image)"
                  class="mx-auto lg:w-12 w-10"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Core } from "../../api.service";
import { BASE_URL } from "../../config.js";

export default {
  name: "OurClient",
  props: ["data"],

  data() {
    return {
      endpointClient: `${BASE_URL}our-client?populate=deep`,
      ourClient: null,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      await Core.get(this.endpointClient).then((res) => {
        this.ourClient = res.data.attributes;
      });
    },
    getImgUrl(pic) {
      if (pic.data) {
        return Core.getImage(pic);
      }
    },
  },
  computed: {},
};
</script>

<style>
.height-199 {
  height: 199px;
}
</style>
