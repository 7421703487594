<template>
  <div class="py-4 px-4 lg:px-8 my-5 md:my-4" id="pricing">
    <div class="text-center">
      <span
        class=" font-weight-400 font-size-16
          line-height-19
          text-transform-uppercase"
        
        >{{data.heading}}</span
      >
      <h2
        class="mb-2 font-weight-700 font-size-32 line-height-38"
      >
      {{data.sub_heading}}
      </h2>
    </div>

    <div class="grid justify-content-between md:mt-0 md:p-3">
      <CustomerStoryCard class="col-12 md:col-4" :data="blog" :index="index" v-for="(blog, index) in data.customer_stories.data" :key="index" />
    </div>
    <div class="grid justify-content-around md:mt-0 md:p-3">
      <router-link to="/customer-story" active-class="active" exact>
        <div
          style="width:18rem"
          class="col-12 lg:col-12 p-3 text-center view-all-button"
        >
        {{data.button}}
        </div>
      </router-link>
    </div>
  </div>
</template>
  
  <script>
import CustomerStoryCard from "./CustomerStoryCard.vue";
export default {
  name: "Blogs",
  props:['data'],
  data() {
    return {};
  },
  created(){
 // console.log('customer stories',this.data)
  },
  components: { CustomerStoryCard },
  methods: {},
  computed: {},
};
</script>
  
  <style lang="scss">
.section-blog-card {
  background: #ffffff;
  border: 0.561194px solid #edeaff !important;
  border-radius: 40px;
  // margin: 5px;
}
.section-blog-card:hover {
  background: #ffffff;
  /* colorful shadow */

  box-shadow: 0px 30px 60px rgba(76, 85, 157, 0.25);
  border-radius: 40px;
}
.blog-image {
  width: 100%;
  height: 40%;
  border-radius: 40px 40px 0px 0px;
}
</style>
  