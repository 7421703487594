<template>
  <div class="py-8 px-4 lg:px-8 support-section-conatiner" id="pricing">
    <div class="px-4 lg:px-8 text-center" id="pricing">
      <div class="col-6 mx-auto">
        <h2
          class="font-weight-400 font-size-16 line-height-19 text-center text-transform-uppercase body-text-color"
        >
          {{ data.heading }}
        </h2>
        <span
          class="font-weight-700 font-size-28 line-height-38 text-center font-color-text"
          >{{ data.sub_heading }}
        </span>
      </div>
    </div>

    <div v-if="reason.length" class="grid justify-content-between mt-8 md:mt-0">
      <ReasonCard
        :data="reasonData"
        :index="index"
        v-for="(reasonData, index) in data.reasons.data"
        :key="index"
      />
    </div>
    <div class="grid justify-content-around md:mt-5 md:p-3">
      <router-link to="/integration" active-class="active" exact>
        <div class="col-12 lg:col-12 mt-5 p-3 text-center view-all-button">
          {{ data.button }}
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import ReasonCard from "./ReasonCard.vue";
export default {
  props: ["data"],
  name: "Menu",
  components: { ReasonCard },
  data() {
    return {
      value: 500,
      formatter1: "{value}\norders",
      reason: [
        {
          title: "Easy Setup",
          desc: "Connect your Shopify account to Sell O Mate n in seconds and seamlessly sync your product and shipping updates back and forth.",
        },
        {
          title: "Order Syncing",
          desc: "Gain complete control and insight into all of your orders at every stage of the shipping process, from all of your different selling channels.",
        },
        {
          title: "Product Information",
          desc: "Connect your Shopify account to Sell O Mate n in seconds and seamlessly sync your product and shipping updates back and forth.",
        },
        {
          title: "Order Edits",
          desc: "Refunds, cancellations, and line item changes are synced with every import so you avoid shipping out unnecessary orders.",
        },
        {
          title: "Automatic Shipping Updates",
          desc: "After shipping label creation, Sell O Mate transmits the Carrier, Tracking Number, and Shipping status back to Shopify and your customer.",
        },
        {
          title: "Multichannel Selling and Shipping",
          desc: "Whether you sell on other channels directly or through Shopify, reach more customers and ship orders from marketplaces like Amazon, eBay, and Walmart.",
        },
      ],
    };
  },
  created() {
     // console.log("reasonsss", this.data);
  },
  methods: {},
  computed: {},
};
</script>

<style lang="scss">
.support-section-conatiner {
  background: rgba(246, 244, 255, 0.6) 92.51%;
  // background: linear-gradient(
  //   180deg,
  //   #f6f4ff 0%,
  //   rgba(246, 244, 255, 0.6) 92.51%,
  //   rgba(246, 243, 255, 0) 100%
  // );
}
</style>
