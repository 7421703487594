<template>
  <div class="preloader" v-if="loading">
    <div class="preloader-content"></div>
  </div>
  <div v-else>
    <HeroCommon v-if="pricing" :data="pricing.header" />
    <PricingPlan />
    <Reviews />
    <Fulfilment />
    <Blogs />
  </div>
</template>

<script>
import Reviews from "../components/reviews/Reviews.vue";
import Fulfilment from "../components/fulfilment/Fulfilment.vue";
import PricingPlan from "../components/pricingPlan/PricingPlan.vue";
import Blogs from "../components/blogs/Blogs.vue";
import HeroCommon from "../components/header/HeroCommon.vue";
import { BASE_URL } from "../config.js";
import { Core } from "../api.service";

export default {
  data() {
    return {
      endpointPrice: `${BASE_URL}pricing?populate=deep`,
      pricing: null,
      loading: false,
      // pricing: {
      //   heading: "Pricing plans",
      //   content: "Pricing to suit all sizes of business.",
      //   image: "priceHeader",
      // },
    };
  },
  components: {
    Reviews,
    Fulfilment,
    PricingPlan,
    Blogs,
    HeroCommon,
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      this.loading = true;
      await Core.get(this.endpointPrice).then((res) => {
        this.pricing = res.data.attributes;
        this.loading = false;
      });
    },
  },
  computed: {},
};
</script>

<style lang="scss"></style>
