<template>
  <div class="py-3 px-5 lg:px-7 md:px-6 mx-0 lg:mx-6 md:mx-4" v-if="data">
    <div class="grid mt-8 pb-2 md:pb-8 md:pt-5 section-fulfilment">
      <div class="col-12 lg:col-4 lg:px-6 px-4 py-5 flex flex-column">
        <span
          class="mt-5 font-weight-400 line-height-19 lg:text-base md:text-base text-sm"
          style="
            /* font-family: 'Roboto'; */
            /* font-style: normal; */
            /* font-weight: 400; */
            /* font-size: 16px; */
            /* line-height: 19px; */
            text-transform: uppercase;
          "
        >
          {{ data.heading }}
        </span>
        <span
          class="mt-5 font-weight-700 line-height-38 lg:text-4xl md:text-4xl text-2xl"
          style="
            /* font-family: 'Roboto'; */
            /* font-style: normal; */
            /* font-weight: 700; */
            /* font-size: 32px; */
            /* line-height: 38px; */
          "
        >
          {{ data.sub_heading }}
        </span>
        <span
          class="mt-5 font-weight-400 line-height-24 lg:text-lg md:text-lg text-base"
          style="
            /* font-family: 'Roboto'; */
            /* font-style: normal; */
            /* font-weight: 400; */
            /* font-size: 18px; */
            /* line-height: 24px; */
          "
          >{{ data.description }}
        </span>

        <span @click="goToSignup" class="mt-7 yellow-button lg:py-3 py-2 text-base"
          >{{ data.button_label }}
        </span>
      </div>

      <div class="col-12 lg:col-4 px-5 py-2 flex flex-column">
        <ul class="lg:mt-8 mt-2 list-none">
          <li v-for="data in data.list1" :key="data" class="flex align-items-start lg:text-base md:text-base text-sm font-weight-400 line-height-24 lg:mt-4 mt-2">
            <img src="img/tick.svg" class="mr-4 lg:mt-1 mt-2" alt="" /><span>{{ data.text }}</span> 
          </li>
        </ul>
      </div>

      <div class="col-12 lg:col-4 px-5 py-2 flex flex-column">
        <ul class="lg:mt-8 mt-2 list-none">
          <li v-for="data in data.list2" :key="data" class="flex align-items-start lg:text-base md:text-base text-sm font-weight-400 line-height-24 lg:mt-4 mt-2">
            <img src="img/tick.svg" class="mr-4 lg:mt-1 mt-2" alt="" /><span>{{ data.text }}</span> 
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { BASE_URL } from "../../config.js";
import { Core } from "../../api.service";
export default {
  data() {
    return {
      endpointFulfilment: `${BASE_URL}fulfilment?populate=deep`,
      fulfilment: null,
      data: null,
    };
  },
  created() {
    this.getFullfilment();
  },
  methods: {
    async getFullfilment() {
      await Core.get(this.endpointFulfilment).then((res) => {
        this.data = res.data.attributes;
      });
    },
    goToSignup() {
      window.open(
        this.data.button_ref,
        "_blank" // <- This is what makes it open in a new window.
      );
      // location.href = this.data.button_ref;
    },
  },
};
</script>

<style lang="scss">
.section-fulfilment {
  background: linear-gradient(132.35deg, #8866ad -7.37%, #485096 130.93%);
  box-shadow: 0px 30px 60px rgba(76, 85, 157, 0.25);
  border-radius: 40px;
  color: white;
}
.fulfilment-list {
  list-style: none;
}
.fulfilment-list li {
  // margin-top: 2rem;
  // font-family: "Roboto";
  // font-style: normal;
  // font-weight: 400;
  // font-size: 16px;
  // line-height: 24px;
  /* margin */
}
.fulfilment-list li:before {
  margin-right: 1.5rem;
}
.button-yellow {
  background: linear-gradient(92.56deg, #ff8301 -8.44%, #fea301 108.25%);
  border-radius: 100px;
  padding: 15px 0px;
  width: 16rem;
  cursor: pointer;
  text-align: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}
</style>
