import "primevue/resources/primevue.min.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "prismjs/themes/prism-coy.css";
import "./assets/styles/layout.scss";
import "./assets/demo/flags/flags.css";
import "../src/App.scss";
import { createApp, reactive } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import routes from "./router";
// import router from "./router";
import AppWrapper from "./AppWrapper.vue";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import PrimeVue from "primevue/config";
import Button from "primevue/button";
import Card from "primevue/card";
import Carousel from "primevue/carousel";
import Checkbox from "primevue/checkbox";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import Image from "primevue/image";
import Textarea from "primevue/textarea";
import InputMask from "primevue/inputmask";
import InputNumber from "primevue/inputnumber";
import InputSwitch from "primevue/inputswitch";
import InputText from "primevue/inputtext";
import Galleria from "primevue/galleria";
import Menu from "primevue/menu";
import Menubar from "primevue/menubar";
import OverlayPanel from "primevue/overlaypanel";
import Panel from "primevue/panel";
import ProgressBar from "primevue/progressbar";
import Rating from "primevue/rating";
import RadioButton from "primevue/radiobutton";
import Ripple from "primevue/ripple";
import ScrollTop from "primevue/scrolltop";
import StyleClass from "primevue/styleclass";
import BlockViewer from "./BlockViewer";
import Slider from "primevue/slider";
import ToastService from "primevue/toastservice";
import Toast from "primevue/toast";
import Markdown from 'vue3-markdown-it';


const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "",
  linkExactActiveClass: "active open",
});
router.beforeEach(function (to, from, next) {
  window.scrollTo(0, 0);
  // Add a navigation guard to modify the meta tags dynamically
  // router.beforeEach((to, from, next) => {
  // Update the title of the page
  document.title = to.meta.title || 'Sell-o-Mate';

  // Remove existing meta tags
  const metaTags = document.getElementsByTagName('meta');
  Array.from(metaTags).forEach((tag) => {
    if (tag.getAttribute('name') === 'description' || tag.getAttribute('property') === 'og:description' ||
      tag.getAttribute('name') === 'title' || tag.getAttribute('property') === 'og:title' ||
      tag.getAttribute('name') === 'image' || tag.getAttribute('property') === 'og:image' ||
      tag.getAttribute('name') === 'url' || tag.getAttribute('property') === 'og:url'
    ) {
      tag.remove();
    }
  });

  // Add new meta tags
  if (to.meta.metaTags) {
    to.meta.metaTags.forEach((tag) => {
      const meta = document.createElement('meta');
      Object.keys(tag).forEach((key) => {
        meta.setAttribute(key, tag[key]);
      });
      document.head.insertBefore(meta, document.head.firstChild);
    });
  }

  const titleElement = document.querySelector('title');

  // Step 3: Move the <title> tag to the top of the <head>
  document.head.insertBefore(titleElement, document.head.firstChild);
  // document.head.insertBefore(document.title,document.head.firstChild);


  next();
});
//   next();
// });

const app = createApp(AppWrapper);

app.config.globalProperties.$appState = reactive({
  theme: "lara-light-indigo",
  darkTheme: false,
});

app.use(PrimeVue, { ripple: true, inputStyle: "outlined" });
app.use(router);

app.use(Markdown);
app.use(ToastService);
app.directive("ripple", Ripple);
app.directive("styleclass", StyleClass);
app.component("Accordion", Accordion);
app.component("AccordionTab", AccordionTab);
app.component("Button", Button);
app.component("Slider", Slider);
app.component("Card", Card);
app.component("Carousel", Carousel);
app.component("Checkbox", Checkbox);
app.component("DataView", DataView);
app.component("Dialog", Dialog);
app.component("Dropdown", Dropdown);
app.component("Image", Image);
app.component("InputMask", InputMask);
app.component("InputNumber", InputNumber);
app.component("InputSwitch", InputSwitch);
app.component("InputText", InputText);
app.component("Textarea", Textarea);
app.component("Galleria", Galleria);
app.component("Menu", Menu);
app.component("Menubar", Menubar);
app.component("Panel", Panel);
app.component("OverlayPanel", OverlayPanel);
app.component("ProgressBar", ProgressBar);
app.component("RadioButton", RadioButton);
app.component("Rating", Rating);
app.component("ScrollTop", ScrollTop);
app.component("Toast", Toast);

app.component("BlockViewer", BlockViewer);

app.mount("#app");
