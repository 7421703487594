<template>
  <div
    v-if="whatDone"
    class="lg:pb-8 px-4 lg:px-8 mb-2 md:mb-4"
    style="
      background: linear-gradient(
        180deg,
        #faf9ff 0%,
        rgba(246, 244, 255, 0.6) 92.51%,
        rgba(246, 243, 255, 0) 100%
      );
    "
    id="pricing"
  >
    <div class="text-center padding-tlr">
      <h2
        class="font-weight-400 text-base line-height-19 text-center text-transform-uppercase body-text-color"
      >
        {{ whatDone.heading }}
      </h2>
      <span
        class="font-weight-700 line-height-38 text-center font-color-text text-3xl"
        >{{ whatDone.sub_heading }}
      </span>

      <div>
        <span
          class="m-t-15 font-weight-400 text-base padding-tlr line-height-19 text-center body-text-color"
          >{{ whatDone.description }}</span
        >
      </div>
    </div>
    <div class="grid justify-content-center padding-tlr">
      <div
        class="col-12 md:col-12 lg:col-4 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-0 bg-white border-default border-botom-left-radius border-top-left-radius"
      >
        <div class="p-2 border-radius-10 text-center">
          <div class="p-3 surface-card h-full border-radius-8">
            <div class="flex align-items-center justify-content-center mb-3">
              <img
                :src="getImgUrl(whatDoneTabs[0].attributes.image)"
                class="mx-auto"
                alt=""
              />
            </div>
            <h5 class="section-heading lg:text-4xl text-3xl">
              {{ whatDoneTabs[0].attributes.heading }}
            </h5>
            <span class="section-body text-base">{{
              whatDoneTabs[0].attributes.description
            }}</span>
          </div>
        </div>
      </div>
      <div
        class="col-12 md:col-12 lg:col-4 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-0 bg-white border-default border-top-default border-botom-default"
      >
        <div class="p-2 border-radius-10 text-center">
          <div class="p-3 surface-card h-full border-radius-8">
            <div class="flex align-items-center justify-content-center mb-3">
              <img
                :src="getImgUrl(whatDoneTabs[1].attributes.image)"
                class="mx-auto"
                alt=""
              />
            </div>
            <h5 class="section-heading lg:text-4xl text-3xl">
              {{ whatDoneTabs[1].attributes.heading }}
            </h5>
            <span class="section-body text-base">{{
              whatDoneTabs[1].attributes.description
            }}</span>
          </div>
        </div>
      </div>
      <div
        class="col-12 md:col-12 lg:col-4 p-0 lg:pb-5 mt-4 lg:mt-0 bg-white border-default border-botom-right-radius border-top-right-radius"
      >
        <div class="p-2 border-radius-10 text-center">
          <div class="p-3 surface-card h-full border-radius-8">
            <div class="flex align-items-center justify-content-center mb-3">
              <img
                :src="getImgUrl(whatDoneTabs[2].attributes.image)"
                class="mx-auto"
                alt=""
              />
            </div>
            <h5 class="section-heading lg:text-4xl text-3xl">
              {{ whatDoneTabs[2].attributes.heading }}
            </h5>
            <span class="section-body text-base">{{
              whatDoneTabs[2].attributes.description
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Core } from "../../api.service";
import { BASE_URL } from "../../config.js";

export default {
  name: "WhatDone",

  data() {
    return {
      whatDoneTabs: null,
      endpointWhatDone: `${BASE_URL}what-done?populate=deep`,
      whatDone: null,
    };
  },
  async created() {
    await this.getData();

    this.whatDoneTabs = this.whatDone.what_done_tabs.data;
  },
  methods: {
    async getData() {
      await Core.get(this.endpointWhatDone).then((res) => {
        this.whatDone = res.data.attributes;
         // console.log("whatDone", this.whatDone);
      });
    },
    getImgUrl(pic) {
      if (pic) {
        return Core.getSvg(pic);
      }
    },
  },
  computed: {},
};
</script>

<style scoped>
.padding-tlr {
  padding-top: 4%;
  padding-right: 4%;
  padding-left: 4%;
}
.font-size-14 {
  font-size: 14px;
}
</style>
