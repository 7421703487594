<template>
  <div v-if="founder" class=" px-3 lg:px-6 mx-0 lg:mx-8" id="highlight" style="">
    <div
      class="grid mt-8 section-fulfilment"
      :style="getBgImgUrl(founder?.background_image.data[1])"
      style="
        background-repeat: no-repeat;
        background-position: 78px 68px !important;
        background-color: #6459a0;
      "
    >
      <div
        class="
          col-12
          lg:col-5
          px-8
          py-5
          pb-2
          flex flex-column
          align-items-center
        "
      >
        <img
          :src="getImageUrl(founder?.image)"
          alt="hero screen"
          class="lg:w-12 md:w-5 sm:w-6 w-8"
        />
      </div>

      <div
        class="col-12 lg:col-7 comma-image lg:px-2 md:px-8 sm:px-6 px-2 lg:pb-0 pb-5"
      :style="getBgImgUrl(founder?.background_image.data[0])"
      >
        <div class="mt-a-center">
          <span class="font-weight-500 text-3xl line-height-24 color-white"
            >{{founder.heading}}</span
          >
          <br />
          <div class="m-t-10">
            <span
              class="
                font-weight-400 text-base
                line-height-24
                color-white
                opacity-08
              "
              >{{founder.sub_heading}}</span
            >
          </div>
          <div class="m-t-25">
            <p v-for="data in founder.description" :key="data" class="font-weight-400 text-base line-height-24 color-white">
              {{data.text}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BASE_URL } from "../../config.js";
import { Core } from "../../api.service";

export default {
  name: "Founder",
  components: {},
  // props: ["data"],
  data() {
    return {
      endpointFounder: `${BASE_URL}founder?populate=deep`,
      founder: null,

    };
  },
  methods: {
    async getData(){
      await Core.get(this.endpointFounder).then((res) => {
        this.founder = res.data.attributes;
        // console.log("founder", this.founder);
      });
    },
    getImageUrl(pic) {
      if (pic) {
        return Core.getImage(pic);
      }
    },
    getBgImgUrl(pic) {
      if (pic) {
        let bgImage = Core.getImageSingle(pic);
        return {
          backgroundImage: `url("${bgImage}")`, //Core.getImage(leftSection.attributes.image)
        };
      }
    },
  },
  computed: {},
  created() {
    this.getData();

  },
};
</script>


<style>
.section-fulfilment1 {
  background-color: linear-gradient(132.35deg, #8866ad -7.37%, #485096 130.93%);
  box-shadow: 0px 30px 60px rgb(76 85 157 / 25%);
  border-radius: 40px;
  color: white;
}
.comma-image {
  background-repeat: no-repeat;
  background-position: 315px 68px !important;
  display: flex;
  align-items: center;
}
</style>
