<template>
  <Toast position="bottom-right" group="br" />
  <div class="py-4 px-4 lg:px-8 xl:mx-8 lg:mx-4 md:mx-4" id="highlight">
    <div class="grid mt-8 pb-2 md:pb-8 contact-form">
      <div
        class="col-12 lg:col-4 xl:p-6 lg:p-5 p-4 xl:py-8 lg:py-8 py-6 contact-info"
      >
        <p class="font-weight-400 font-size-24 line-height-24 color-black">
          {{ data.heading }}
        </p>
        <p
          class="font-weight-400 font-size-16 line-height-24 font-color-text opacity-07"
        >
          {{ data.description }}
        </p>
        <img class="w-12" src="img/lineBreak.png" alt="" />
        <p
          class="pt-5 font-weight-500 lg:text-lg text-base line-height-21 font-color-text"
        >
          {{ data.contact_info_title }}
        </p>
        <p class="flex align-items-center">
          <img src="img/phone.png" alt="" />
          <span
            class="xl:ml-5 ml-3 font-weight-400 lg:text-lg text-base line-height-24 color-black"
          >
            {{ data.phone_no }}
          </span>
        </p>
        <p class="flex align-items-center">
          <img src="img/email.png" alt="" /><span class="xl:ml-5 ml-3">
            <a
              :href="'mailto:' + data.email"
              class="font-weight-400 lg:text-lg text-base line-height-24 color-black"
              >{{ data.email }}</a
            >
          </span>
        </p>
      </div>

      <div class="col-12 lg:col-8">
        <form @submit.prevent="submit">
          <div class="grid">
            <div class="col-12 lg:col-6">
              <div class="lg:m-5 my-5">
                <span class="p-float-label">
                  <InputText
                    id="username"
                    class="w-100"
                    type="text"
                    v-model="form.name"
                    required
                  />
                  <label for="username">Name *</label>
                </span>
              </div>

              <!-- <div class="m-5 dd-contact-uc">
                <span class="p-float-label">
                  <Dropdown
                    id="dropdown"
                    class="w-100"
                    v-model="form.subject"
                    :options="subjects"
                    optionLabel="name"
                  />
                  <InputText id="username" class="w-100" type="text" v-model="subject" />
                  <label for="username">Select Subject</label>
                </span>
              </div> -->
              <div class="lg:mx-5 lg:my-7 my-5">
                <span class="p-float-label">
                  <InputText
                    id="username"
                    class="w-100"
                    type="text"
                    v-model="form.company"
                  />
                  <label for="username">Company Name</label>
                </span>
              </div>
              <!-- <div class="lg:m-5 my-5">
                <span class="p-float-label">
                  <InputText
                    id="username"
                    class="w-100"
                    type="text"
                    v-model="form.role"
                  />
                  <label for="username">Your Role</label>
                </span>
              </div>
              <div class="lg:m-5 my-5">
                <span class="p-float-label">
                  <InputText
                    id="username"
                    class="w-100"
                    type="text"
                    v-model="form.products"
                  />
                  <label for="username">Products</label>
                </span>
              </div>
              <div class="lg:m-5 my-5">
                <span class="p-float-label">
                  <InputText
                    id="username"
                    class="w-100"
                    type="text"
                    v-model="form.stores"
                  />
                  <label for="username">Stores & Marketplaces</label>
                </span>
              </div> -->
              <!-- <div class="m-5">
                <span class="p-float-label">
                  <Textarea
                    rows="3"
                    id="username"
                    type="text"
                    v-model="form.message"
                    class="w-100"
                  />
                  <label for="username">Enter your Message...</label>
                </span>
              </div> -->
            </div>
            <div class="col-12 lg:col-6">
              <div class="lg:m-5 lg:my-5 md:m:0 my-0">
                <span class="p-float-label">
                  <InputText
                    class="w-100"
                    id="username"
                    type="email"
                    v-model="form.email"
                    required
                  />
                  <label for="username">Email *</label>
                </span>
              </div>
              <div class="lg:mx-5 lg:my-5 mt-5">
                <label class="ml-2 p-1 phone-label">Phone number</label>
                <span class="p-float-label">
                  <vue-tel-input
                    v-model="form.phone_no"
                    id=""
                    mode="international"
                    :inputOptions="phoneInputOptions"
                    class="p-inputtext p-component w-100"
                  >
                  </vue-tel-input>
                  <!-- <label for="username">Phone number</label>  -->

                  <!-- <InputText
                    
                    class="w-100"
                    type="number"
                    v-model="form.phone_no"
                  />-->
                </span>
              </div>
              <!-- <div class="lg:m-5 my-5">
                <span class="p-float-label">
                  <InputText
                    id="username"
                    class="w-100"
                    type="text"
                    v-model="form.orders"
                  />
                  <label for="username">Orders Per Month</label>
                </span>
              </div>
              <div class="lg:m-5 my-5">
                <span class="p-float-label">
                  <InputText
                    id="username"
                    class="w-100"
                    type="text"
                    v-model="form.variations"
                  />
                  <label for="username">Variations</label>
                </span>
              </div>
              <div class="lg:m-5 my-5">
                <span class="p-float-label">
                  <InputText
                    id="username"
                    class="w-100"
                    type="text"
                    v-model="form.users"
                  />
                  <label for="username">Users</label>
                </span>
              </div> -->
            </div>
            <div class="col-12 lg:col-12 contact-form">
              <div class="lg:mx-5">
                <span class="p-float-label">
                  <Textarea
                    rows="4"
                    id="username"
                    type="text"
                    v-model="form.message"
                    class="w-100"
                    autoResize
                  />
                  <label for="username">Enter your Message...</label>
                </span>
              </div>
            </div>
          </div>
          <div>
            <Button type="submit" class="contact-button text-center lg:mt-5 lg:ml-5 mt-5"
              >Send message</Button
            >
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { BASE_URL } from "../../config.js";
import { Core } from "../../api.service";
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
export default {
  name: "Contact",
  props: ["data"],
  components: {
    VueTelInput,
  },
  data() {
    return {
      // endpointContact: `${BASE_URL}contact-uses`,
      endpointContact: `${BASE_URL}contact-uses`,
      // form: {
      //   name: "",
      //   contact_us_subjects: [],
      //   message: "",
      //   email: "",
      //   phone_no: "",
      // },
      phoneInputOptions: {
        placeholder: "",
        styleClasses: ["p-inputtext", "p-component", "w-100"],
        id: "username",
      },
      form: {
        name: "",
        email: "",
        company: "",
        phone_no: "",
        // contact_us_subjects: [],
        message: "",
      },
      // subject: null,
      // subjects: [
      //   { name: "Inventory Management", id: "1" },
      //   { name: "Product Catalog", id: "2" },
      //   { name: "Order Management", id: "3" },
      //   { name: "Shipping Software", id: "4" },
      //   { name: " Others", id: "5" },
      // ],
    };
  },
  methods: {
    async submit() {
       // console.log("data is", this.form);
       // console.log(this.subject);
      // this.form.contact_us_subjects = [this.subject.id];
      await Core.post(this.endpointContact, this.form).then((res) => {
         console.log("output", res);
        this.$toast.add({
          severity: "success",
          summary: "Success Message",
          detail: "Message Sent",
          group: "br",
          life: 3000,
        });
      });
      this.form = {
        name: "",
        email: "",
        company: "",
        phone_no: "",
        // contact_us_subjects: [],
        message: "",
      };
    },
  },
  computed: {},
};
</script>

<style lang="scss">
.w-100 {
  width: 100%;
}
.vue-tel-input {
  border: none !important;
  box-shadow: none !important;
}
.p-inputtext,
.p-dropdown,
.p-dropdown-panel,
.vti__dropdown {
  border: none;
  border-bottom: 1px solid #edeaff;
  border-radius: 0px;
  box-shadow: none !important;
}
.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  border-color: #6366f1 !important;
}
.pi-chevron-down:before {
  content: "\2212";
  font-weight: bolder;
  font-size: 20px;
}
.contact-button {
  // margin: 15px;
  background: #485096;
  border-radius: 100px;
  border: none;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;

  text-align: center;
}
.contact-button:hover {
  background: #485096;
  border-radius: 100px;
}
.contact-info {
  background: #edeaff;
  border-radius: 40px;
}
.contact-form {
  .p-inputtext:enabled:hover {
    border-color: #485096 !important;
    label {
      color: red;
    }
  }
  .p-inputtext:enabled:focus,
  .vti__dropdown:enabled:focus {
    border-color: #485096 !important;
  }
  .p-dropdown:not(.p-disabled):hover {
    border-color: #485096 !important;
  }
  label {
    font-weight: 400;
    font-size: 14px !important;
    // line-height: 24px;
    color: #718096;
  }

  .p-inputtext {
    &:focus {
      outline: none;
      // border-color: #2d8cf0;

      + label {
        color: #485096;
      }
    }
  }
  .p-dropdown-panel {
    background: white;
  }
  .p-dropdown:not(.p-disabled).p-focus {
    box-shadow: none;
  }
  .pi-chevron-down:before {
    content: "\e902";
  }
}
// .dd-contact-uc {
//   .p-dropdown .p-dropdown-trigger {
//     height: 27px;
//     border: 2px solid #485096;
//     border-radius: 100px;
//     width: 27px;
//     color: #485096;
//   }
//   .pi-chevron-down:before {
//     font-size: 14px;
//   }
// }
.phone-label {
  color: #485096 !important;
}
.vti__dropdown {
  // border-color: #485096 !important;
}
</style>
