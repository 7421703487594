<template>
  <div
    class="px-4 lg:px-8 text-center connect-section-conatiner"
    v-if="connection"
  >
    <div class="col-6 mx-auto pt-5">
      <h2
        class="font-weight-400 font-size-16 line-height-19 text-center text-transform-uppercase body-text-color"
      >
        {{ connection.connection.heading }}
      </h2>
      <span
        class="font-weight-700 font-size-28 line-height-38 text-center font-color-text"
      >
        {{ connection.connection.subHeading }}
      </span>
      <div class="mt-5" style="margin-top: 15px">
        <span
          class="font-weight-400 font-size-16 line-height-19 text-center body-text-color"
        >
          {{ connection.connection.description }}</span
        >
      </div>
    </div>
    <div class="mt-5 mx-auto" v-if="connection.image.data">
      <img :src="Core.getImage(connection.image)" alt="" class="w-100" />
      <!-- <Button label="Login" class="login-button text-white-500"></Button> -->
      <!-- <Button label="Start Free 30 Days Trial" class="yellow-button"></Button> -->
    </div>
  </div>
</template>

<script>
import "vue-slider-component/theme/antd.css";
import { BASE_URL } from "../../config.js";
import { Core } from "../../api.service";
export default {
  name: "Connection",
  components: {},
  data() {
    return {
      Core: Core,
      value: 500,
      formatter1: "{value}\norders",
      endpoint: `${BASE_URL}integration-own-connection?populate=deep`,
      connection: null,
    };
  },
  created() {
    this.getConnection();
  },
  methods: {
    async getConnection() {
      await Core.get(this.endpoint).then((res) => {
        this.connection = res.data.attributes;
      });
    },
  },
  computed: {},
};
</script>

<style lang="scss">
.connect-section-conatiner {
  background: #faf8ff;
}
</style>
