<template>
  <div class="px-4 lg:px-8 md:px-7 support-section-conatiner" id="pricing">
    <div v-if="menu.length" class="grid md:mt-0">
      <MenuCard
        :data="data.attributes"
        :index="data.id"
        v-for="data in categories"
        :key="data.id"
      />
    </div>
  </div>
</template>

<script>
import { BASE_URL } from "../../config.js";
import { Core } from "../../api.service";
import MenuCard from "./MenuCard.vue";
export default {
  name: "Menu",
  components: { MenuCard },
  data() {
    return {
      endpoint: `${BASE_URL}article-categories?populate=deep`,
      categories: null,

      value: 500,
      formatter1: "{value}\norders",
      menu: [
        {
          title: "Order Management",
        },
        {
          title: "Purchase Order",
        },
        {
          title: "Product Management",
        },
        {
          title: "Inbound",
        },
        {
          title: "Outbound",
        },
        {
          title: "Inventory",
        },
        {
          title: "Locations",
        },
        {
          title: "Transactions",
        },
        {
          title: "Shipping Carrier",
        },
        {
          title: "Settings",
        },
        {
          title: "Marketplace",
        },
      ],
    };
  },

  created() {
    this.getData();
  },
  methods: {
    async getData() {
      await Core.get(this.endpoint).then((res) => {
        this.categories = res.data;
         // console.log('catregories',this.categories)
      });
    },
  },
  computed: {},
};
</script>

<style lang="scss"></style>
