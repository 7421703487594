<template>
  <div class="flex flex-column xl:py-5">
    <div class="section-blog-card m-2 md:m-1">
      <img
        v-if="blog.images.data"
        :src="getImgUrl(blog.images)"
        class="mx-auto blog-image"
        alt=""
        style="height: 240px"
      />
      <div class="xl:p-6 lg:p-5 md:p-3 p-5 flex flex-column border-200">
        <div class="">
          <span
            class="font-weight-400 text-base line-height-22 body-text-color"
            >{{ blog.heading }}</span
          >
        </div>
        <div class="my-5">
          <span
            class="font-weight-500  text-lg line-height-21 font-color-text"
            >{{ blog.sub_heading }}</span
          >
        </div>
        <div class="my-1">
          <span
            class="font-weight-400 text-base line-height-22 body-text-color"
            >{{ blog.short_description }}</span
          >
        </div>
        <div class="my-2">
          <router-link
            :to="'/customer-story-detail/' + data.id"
            exact
            class="read-more-link text-base hover-size"
            >Read More
          </router-link>
          <img src="img/arrowTopRight.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Core } from "../../api.service";
import moment from "moment";
export default {
  name: "BlogCard",
  props: ["index", "data"],
  data() {
    return {
      blog: null,
    };
  },
  components: {},
  methods: {
    moment: function (date) {
      return moment(date).format("MMMM D, YYYY");
    },
    getImgUrl(pic) {
      if (pic) {
        return Core.getSvg(pic);
      }
    },
  },
  computed: {},
  created() {
    this.blog = this.data.attributes;
  },
};
</script>

<style lang="scss">
.section-blog-card {
  background: #ffffff;
  border: 0.561194px solid #edeaff !important;
  border-radius: 40px;
  // margin: 5px;
}
.section-blog-card:hover {
  background: #ffffff;
  /* colorful shadow */

  box-shadow: 0px 30px 60px rgba(76, 85, 157, 0.25);
  border-radius: 40px;
}
.blog-image {
  width: 100%;
  height: 40%;
  border-radius: 40px 40px 0px 0px;
}
</style>
