<template>
  <div class="preloader" v-if="loading">
    <div class="preloader-content"></div>
  </div>
  <div v-else>
    <HeroCommon v-if="aboutUs" :data="aboutUs.header" />
    <aboutSell />
    <whatDone />
    <founder />
    <ourClients />
    <Reviews />
    <Fulfilment />
    <Blogs />
  </div>
</template>

<script>
import Reviews from "../components/reviews/Reviews.vue";
import Fulfilment from "../components/fulfilment/Fulfilment.vue";
import Blogs from "../components/blogs/Blogs.vue";
import aboutSell from "../components/aboutUs/aboutSelloMate.vue";
import whatDone from "../components/aboutUs/whatDone.vue";
import ourClients from "../components/aboutUs/ourClients.vue";
import founder from "../components/aboutUs/founder.vue";
import HeroCommon from "../components/header/HeroCommon.vue";

import { BASE_URL } from "../config.js";
import { Core } from "../api.service";

export default {
  data() {
    return {
      endpointAbout: `${BASE_URL}about?populate=deep`,
      aboutUs: null,
      blog: null,
      loading: false,

      responsiveOptions: [
        {
          breakpoint: "1024px",
          numVisible: 3,
          numScroll: 3,
        },
        {
          breakpoint: "600px",
          numVisible: 2,
          numScroll: 2,
        },
        {
          breakpoint: "480px",
          numVisible: 1,
          numScroll: 1,
        },
      ],
    };
  },
  components: {
    HeroCommon,
    Reviews,
    Fulfilment,
    aboutSell,
    Blogs,
    whatDone,
    ourClients,
    founder,
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      this.loading = true;
      await Core.get(this.endpointAbout).then((res) => {
        this.aboutUs = res.data.attributes;
        this.loading = false;
      });
    },
  },
  computed: {
    logoColor() {
      if (this.$appState.darkTheme) return "white";
      return "dark";
    },
  },
};
</script>

<style lang="scss"></style>
