<template>
  <div class="preloader" v-if="loading">
    <div class="preloader-content"></div>
  </div>
  <div v-else>
    <HeroCommon v-if="contactUs" :data="contactUs.header" />
    <Contact v-if="contactUs" :data="contactUs" />
    <Faq />
  </div>
</template>

<script>
import { BASE_URL } from "../config.js";
import { Core } from "../api.service";
import Contact from "../components/contact/Contact.vue";
import Faq from "../components/contact/Faq.vue";
import HeroCommon from "../components/header/HeroCommon.vue";



export default {
  data() {
    return {
      loading: false,
      endpointContact: `${BASE_URL}contact-us-info?populate=deep`,

      contactUs: null,
    };
  },
  components: {
    Contact,
    Faq,
    HeroCommon,
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      this.loading = true;
      await Core.get(this.endpointContact).then((res) => {
        this.contactUs = res.data.attributes;
        this.loading = false;
      });
    },
  },
  computed: {
    logoColor() {
      if (this.$appState.darkTheme) return "white";
      return "dark";
    },
  },
};
</script>

<style lang="scss">
.p-accordion
  .p-accordion-header:not(.p-disabled)
  .p-accordion-header-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none !important;
}
.contact-form .p-inputtext + label {
  color: #485096;
}
input.p-inputtext {
  font-weight: 500;
}
</style>
