<template>
  <div class="">
    <Accordion
      :activeIndex="active"
      class="m-t-28 support-accodian"
    >
      <AccordionTab
        class="col-12 support-button"
        aria-label="Google"
        v-for="(menu, index) in categories"
        :key="menu"
        @click="activeBtn = index"
        :class="{ activeBtn: activeBtn === index }"
        :header="menu.attributes.name"
      >
        <span
          class="px-3 font-weight-500 text-sm line-height-16 font-color-text"
        ></span>
        <ul class="">
          <li
            v-for="item in menu.attributes.article_sub_categories.data"
            :key="item"
            class="cursor"
          >
            {{ item.attributes.title }}
          </li>
        </ul>
      </AccordionTab>
    </Accordion>
    <div>
      <div v-for="item in list" :key="item">
        <p class="font-weight-500 text-base line-height-19 font-color-text">
          {{ item.title }}
        </p>
        <p class="font-weight-400 text-sm line-height-24 font-color-text">
          >
          {{ item.desc }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { BASE_URL } from "../../config.js";
import { Core } from "../../api.service";
export default {
  data() {
    return {
      activeBtn: 0,
      endpoint: `${BASE_URL}article-categories?populate=deep`,
      categories: null,
      active: null,
      menuList: [
        {
          title: "Dashboard",
          options: ["Lorem", "Ipsum"],
        },
        {
          title: "Purchase Order",
          options: ["Lorem", "Ipsum"],
        },
        {
          title: "Inbound",
          options: ["Lorem", "Ipsum"],
        },
        {
          title: "Outbound",
          options: ["Lorem", "Ipsum"],
        },
        {
          title: "Transactions",
          options: ["Lorem", "Ipsum"],
        },
        {
          title: "Order Management",
          options: ["Lorem", "Ipsum"],
        },
        {
          title: "Product Management",
          options: [
            "Add New Product",
            "Bulk Update Products",
            "Product Images",
            "Repricing Products",
            "Exporting Product Information",
          ],
        },
        {
          title: "Settings",
          options: ["Lorem", "Ipsum"],
        },
        {
          title: "Inventory",
          options: ["Lorem", "Ipsum"],
        },
      ],
    };
  },

  created() {
    

    // this.active = this.$route.params.id;

    this.getData();
  },
  methods: {
    async getData() {
      await Core.get(this.endpoint).then((res) => {
        this.categories = res.data;
         // console.log("catregories", this.categories);
        this.active  = this.categories.findIndex(cat => cat.id == this.$route.params.id);
         // console.log('actove index',this.active)
      });
    },
  },
};
</script>
<style lang="scss">
.p-button:focus {
  box-shadow: none;
}
.activeBtn {
  span {
    color: #3577f1;
  }
}
.support-button {
  background: #f3f6f9 !important;
  border-radius: 10px;
  height: 50px;
  border: none !important;
  margin-top: 12px;
  // border: 1.8px solid #f3f6f9;
}
.accordion-custom {
  i,
  span {
    vertical-align: middle;
  }

  span {
    margin: 0 0.5rem;
  }
}
.support-accodian {
  .p-accordion-tab {
    margin-bottom: 1rem;
  }
  .p-accordion,
  .p-accordion-header,
  .p-highlight,
  .p-accordion-header-link {
    background: #f3f6f9;
    color: #343a40;
    border-radius: 10px;
    border: none !important;
  }
  .p-accordion .p-accordion-header .p-accordion-header-link {
    border: none !important;
    background: none !important;
    padding: 16px 0px;
  }
  .p-accordion-header-action {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .p-accordion-header-text {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #2d3748;
  }
  @media screen and (max-width: 1000px) {
    .p-accordion-header-text {
    font-size: 14px;
  }
  }
  .pi-chevron-down:before {
    content: "\2212";
    font-weight: bolder;
    font-size: 20px;
  }
  .pi-chevron-right:before {
    content: "\002B";
    font-weight: bolder;
    font-size: 20px;
  }
  .p-accordion-content {
    border: none !important;
    background: transparent !important;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #718096;
    padding: 0px !important;
  }
  p {
    padding-bottom: 11px;
  }
  ul {
    list-style-type: none;
    padding: 0rem 1rem 1rem 3rem;
    li {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 32px;
      color: #2d3748;
    }
  }
  .p-highlight .p-accordion-header-text,
  .p-highlight .p-accordion-toggle-icon {
    color: #3577f1 !important;
  }
}
</style>
