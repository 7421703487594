<template>
  <div class="test faq-accodian" v-if="articleDetail">
    <div class="mx-auto" style="overflow-y: scroll; height: 700px">
      <div class="row">
        <div class="row mx-auto" style="padding-bottom: 16px">
          <div class="">
            <h2
              class="
                mb-2
                font-weight-700 lg:text-4xl text-3xl
                line-height-38
                font-color-text
              "
            >
              {{ articleDetail.heading }}
            </h2>
            <div class="m-t-21">
              <span
                class="
                  font-weight-400 text-base
                  line-height-24
                  body-text-color
                "
              >
                {{ articleDetail.description }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-for="data in articleDetail.section" :key="data.id">
        <div class="row mx-auto" style="padding-bottom: 16px">
          <div class="">
            <h2
              v-if="data.heading"
              class="
                mb-2
                font-weight-700 lg:text-4xl text-3xl
                line-height-38
                font-color-text
              "
            >
              {{ data.heading }}
            </h2>
            <div class="m-t-21" v-if="data.description">
              <span
                v-for="desc in data.description"
                :key="desc.id"
                class="
                  font-weight-400 text-base
                  line-height-24
                  body-text-color
                "
              >
                {{ desc.description }}
              </span>
            </div>
          </div>
          <div v-if="data.video" class="row mt-5">
            <iframe
              style="width: 62rem; height: 30rem"
              :src="data.video"
              frameborder="0"
              allowfullscreen
            ></iframe>

            <!-- <iframe :src="data.video" ></iframe> -->
          </div>
          <div v-if="data.image.data" class="row mt-5">
            <img
              style="width: 62rem"
              :src="getImgUrl(data.image)"
              class="w-11"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="row mx-auto" style="padding-bottom: 16px">
        <div class="text-center">
          <div class="m-t-21">
            <span v-if="articleDetail.helpful_text"
              class="
                text-center
                font-weight-400 text-base
                line-height-19
                body-text-color
              "
              >{{ articleDetail.helpful_text }}
            </span>
            <br />
            <br />
            <span
              class="
                font-weight-400 lg:text-4xl text-3xl
                line-height-24
                body-text-color
              "
            >
              <span class="cursor emoji-hover">😞</span>
              <span class="cursor emoji-hover">😃</span>
              <span class="cursor emoji-hover">😐</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { BASE_URL } from "../../config.js";
import { Core } from "../../api.service";
export default {
  name: "ProductManagement",
  props: ["articleId"],

  data() {
    return {
      list: [
        {
          title: "Ad New Product",
          desc: "Add a New Product Via Channels",
        },
        {
          title: "Bulk Update Products",
          desc: "Bulk-Update and Custom Export Columns",
        },
        {
          title: "Product Images",
          desc: "Channel-Specific Images",
        },
        {
          title: "Repricing Products ",
          desc: "Channel-Specific Images",
        },
        {
          title: "Exporting Product Information",
          desc: "Export Mapping Tool",
        },
      ],
      articleDetail: null,
      // articleId:null
    };
  },
  created() {
    // console.log('article detail created')
    // this.articleId = this.$route.params.article;
    this.getArticle();
  },
  mounted() {
     // console.log("article detail mounted");
  },
  methods: {
    async getArticle() {
      await Core.get(
        `${BASE_URL}articles/${this.articleId}?populate=deep`
      ).then((res) => {
        this.articleDetail = res.data.attributes;
         // console.log("SupportDetail-SupportDetail", this.articleDetail);
      });
    },
    getImgUrl(pic) {
      if (pic) {
         // console.log("pic", pic);

        return Core.getImage(pic);
      }
    },
  },
  computed: {},
};
</script>

<style lang="scss">
.accordion-custom {
  i,
  span {
    vertical-align: middle;
  }

  span {
    margin: 0 0.5rem;
  }
}
.faq-accodian {
  .p-accordion-tab {
    border-bottom: 1px solid #dee2e6 !important;
  }
  .p-accordion .p-accordion-header .p-accordion-header-link {
    border: none !important;
    background: none !important;
    padding: 16px 0px;
  }
  .p-accordion-header-action {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .p-accordion-header-text {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #2d3748;
  }
  .pi-chevron-down:before {
    content: "\2212";
    font-weight: bolder;
    font-size: 20px;
  }
  .pi-chevron-right:before {
    content: "\002B";
    font-weight: bolder;
    font-size: 20px;
  }
  .p-accordion-content {
    border: none !important;
    background: transparent !important;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #718096;
    padding: 0px !important;
  }
  p {
    padding-bottom: 11px;
  }
}
</style>
