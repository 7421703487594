<template>
  <div class="preloader" v-if="loading">
    <div class="preloader-content"></div>
  </div>
  <div v-else>
    <HeroCommon
      v-if="faqs"
      :data="faqs.attributes.header"
      :searchUrl="searchEndpoint"
      @serachList="searchData"
    />
    <div v-if="listSearch.key">
      <search-list :list="listSearch" />
    </div>
    <div class="faq-main xl:px-8 md:px-5 sm:px-8 px-8 py-8" v-else>
      <div class="grid lg:mx-5">
        <div class="col-12 md:col-3 faq-list border-40" v-if="faqs">
          <FaqList :list="faqs.attributes.faqSidebar" @faqActive="activeFaq" />
        </div>
        <div class="faq lg:pl-5 col-12 lg:col-8 md:col-8 md:mx-5 md:mt-0 mt-5 border-40" style=" background-color: white;">
          <div class="lg:px-6 md:px-5 px-3 py-7 border-40" v-if="faqs">
            <Faq :reviews="faqs" :list="reviews" />
          </div>
        </div>
      </div>
      <div class="grid">
        <div class="col-12 m-t-40" v-if="support">
          <TechnicalSupport :support="support" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Faq from "../components/faq/Faq.vue";
import FaqList from "../components/faq/FaqCategory.vue";
import TechnicalSupport from "../components/faq/TechnicalSupport.vue";
import HeroCommon from "../components/header/HeroCommon.vue";
import SearchList from "../components/shared/searchList.vue";

import { BASE_URL } from "../config.js";
import { Core } from "../api.service";

export default {
  components: {
    Faq,
    FaqList,
    TechnicalSupport,
    HeroCommon,
    SearchList,
  },

  data() {
    return {
      endpointfaq: `${BASE_URL}faq?populate=deep`,
      endpointTechnical: `${BASE_URL}technical-support?populate=deep`,
      searchEndpoint: `${BASE_URL}faq-lists?filters[heading][$startsWith]=`,
      loading: false,
      faqs: null,
      reviews: [],
      listSearch: [],
      support: null,

      faq: {
        image: "faqHeader",
        centerText: "Frequently Asked Questions",
        fieldPlaceholder: "Have a question ? Ask or enter a search term",
      },
    };
  },
  created() {
    this.getFAQ();
  },
  methods: {
    async getFAQ() {
      this.loading = true;
      await Core.get(this.endpointfaq).then((res) => {
        this.faqs = res.data;
        this.loading = false;
      });
      await Core.get(this.endpointTechnical).then((res) => {
        this.support = res.data;
        this.loading = false;
      });
    },
    activeFaq(data) {
      this.reviews = data;
    },
    searchData(data) {
      this.listSearch = data;
    },
  },
};
</script>
<style>
.faq-main {
  /* padding: 46px 130px; */
  background: #faf9ff;
}
.faq-list {
  background: #ffff;
  /* border-radius: 40px; */
  padding: 66px 18px;
}
.border-40{
  border-radius: 40px;

}
.faq {
  background: #ffff;
  /* border-radius: 40px; */
  /* padding: 56px 49px; */
  /* height: 100%; */
}
</style>
