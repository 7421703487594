<template>
  <div v-if="topMenuData" class="fixed navbar z-2" id="myTopnav">
    <!-- <div class="card relative z-2"> -->
    <Menubar
      id="menuBar"
      :model="topNavItems"
      :class="
        navbarClass[$route.path] ? navbarClass[$route.path] : 'route-navbar'
      "
    >
      <template #start>
        <!-- <div class="flex align-items-center"> -->
        <router-link @click="reloadPage()" to="/" class="flex align-items-center">
          <img
            alt="logo"
            :src="getImgUrl(topMenuData.logo)"
            height="40"
            class="mr-2 logo"
          />
          <span
            id="logoName"
            :style="{ color: navbarColors[$route.path] }"
            class="logo-name color-white m-l-6 color-white font-weight-700"
          >
            {{ topMenuData.logo_name }}
            <!-- SELL-O-MATE -->
          </span>
        </router-link>
        <!-- </div> -->
      </template>
      <template #end>
        <div class="login-trial mp-15 py-1 px-2">
          <Button
            class="responsive-menu-end"
            type="button"
            icon="pi pi-chevron-circle-down"
            label=""
            @click="toggle"
          />

          <OverlayPanel ref="op">
            <div class="">
              <a
                id="login"
                style="color: #2d3748"
                :href="topMenuData.login_button_ref"
              >
                {{ topMenuData.login_button }}
                <!-- Login -->
              </a>
              <br />
              <br />
              <a id="login" style="color: #2d3748" @click="goToSignup()">
                {{ topMenuData.trial_button_name }}
                <!-- Login -->
              </a>
              <!-- <a class="m-tria-btn">
                <Button
                  :label="topMenuData.trial_button_name"
                  class="yellow-button"
                  style="width: 158px"
                  @click="goToSignup()"
                ></Button
              ></a> -->
            </div>

            <!-- <img
              src="https://primefaces.org/cdn/primevue/images/product/bamboo-watch.jpg"
              alt="Bamboo Watch"
            /> -->
          </OverlayPanel>
          <a
            id="login"
            class="login-button text-white-500"
            :href="topMenuData.login_button_ref"
          >
            {{ topMenuData.login_button }}
            <!-- Login -->
          </a>
          <a class="m-tria-btn">
            <Button
              :label="topMenuData.trial_button_name"
              class="yellow-button"
              style="width: 158px"
              @click="goToSignup()"
            ></Button
          ></a>
        </div>
      </template>
    </Menubar>
    <!-- </div> -->
  </div>
  <div class="preloader" v-else>
    <div class="preloader-content"></div>
  </div>
</template>

<script>
// When the user scrolls down 50px from the top of the document, resize the myTopnav's font size
window.onscroll = function () {
  scrollFunction();
};

function scrollFunction() {
  if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
    document.getElementById("login").style.color = "black";
    document.getElementById("myTopnav").style.boxShadow =
      "0 0 30px 0 rgb(0 0 0 / 10%)";
    document.getElementById("myTopnav").style.background =
      "rgb(255 255 255 / 70%)";
    document.getElementById("myTopnav").style.position = "sticky";
    document.getElementById("myTopnav").style.backgroundBlendMode = "overlay";
    document.getElementById("myTopnav").style.backdropFilter = "blur(15px)";
    if (window.location.pathname == "/") {
      return;
    } else {
      document.getElementById("logoName").style.color = "black";
      document.getElementById("menuBar").classList.remove("route-navbar");
      document.getElementById("menuBar").classList.add("home-navbar");
      // document.getElementById("menuBar").classList.remove('route-navbar')
      //   document.getElementById("menuBar").classList.add('home-navbar')
    }
    // document.getElementsByClassName("logo-name").style.color = "black"
  } else {
    document.getElementById("myTopnav").style.background = "transparent";
    document.getElementById("myTopnav").style.boxShadow = "none";
    document.getElementById("myTopnav").style.backdropFilter = "blur(0px)";

    document.getElementById("login").style.color = "white";
    if (window.location.pathname == "/") {
      return;
    } else {
      document.getElementById("logoName").style.color = "white";
      document.getElementById("menuBar").classList.remove("home-navbar");
      document.getElementById("menuBar").classList.add("route-navbar");
      // document.getElementById("menuBar").classList.remove('route-navbar')
      //   document.getElementById("menuBar").classList.add('home-navbar')
    }
    // document.getElementsByClassName("logo-name").style.color = "white";

    // document.getElementById("menuBar").classList.remove('route-navbar')
    // document.getElementById("menuBar").classList.add('home-navbar')
  }
}
import TopMenu from "./TopMenu.vue";
import { BASE_URL } from "../../config.js";
import { Core } from "../../api.service";
export default {
  data() {
    return {
      endpoint: `${BASE_URL}top-menu?populate=deep`,
      topMenuData: null,
      TopMenu,
      navbarColors: {
        "/": " #2D3748",
      },
      navbarClass: {
        "/": "home-navbar",
      },
      topNavItems: [],
      showSubMenu: false,
    };
  },
  components: {},
  created() {
    this.getFeatures();
  },
  methods: {
    getImgUrl(pic) {
      if (pic) {
        return Core.getImage(pic);
      }
    },
    myFunction() {
      var x = document.getElementById("myTopnav");
      if (x.className === "topnav") {
        x.className += " responsive";
      } else {
        x.className = "topnav";
      }
    },
    async getFeatures() {
      await Core.get(this.endpoint).then((res) => {
        this.topMenuData = res.data.attributes;
         // console.log("topMenuData", this.topMenuData);
        // this.topNavItems = [];
      });
      this.topMenuData.body.map((data, index) => {
        // console.log('data',data)
        this.topNavItems.push({ label: data?.label, to: data?.ref });
        if (data.__component == "menu.sub-menu") {
          this.topNavItems[index].items = [];
          data.sub_menus.data.map((subData) => {
            // console.log('subdata',subData)
            this.topNavItems[index].items.push({
              label: subData.attributes.label,
              to: subData.attributes.ref,
            });
          });
        }
      });
      // console.log('topNavItems',this.topNavItems)
    },
    goToSignup() {
      window.open(
        this.topMenuData.trial_button_ref,
        "_blank" // <- This is what makes it open in a new window.
      );
      // location.href = this.topMenuData.trial_button_ref;
    },
    toggle() {
       // console.log("ref-ref");
      // this.showSubMenu = !this.showSubMenu
      this.$refs.op.toggle(event);
    },
    reloadPage(){
      if(window.location.pathname == "/"){
        window.location.reload();
      }
      else{
        this.$router.push('/')
      }
    }
  },
  computed: {
    logoColor() {
      if (this.$appState.darkTheme) return "white";
      return "dark";
    },
  },
};
</script>

<style lang="scss">
.sub-nav {
  display: none;
  position: absolute;
  background: #ffffff;
  border: 0.56px solid #e9ebf0;
  border-radius: 20px;
  min-width: 240px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  margin-left: 1rem;
  z-index: 1;
  li {
    list-style-type: none;
    padding: 12px 12px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #2d3748;
  }
}

.main-nav:hover .sub-nav {
  display: block;
}
.login-button {
  color: #ffffff;
  border: none;
  padding: 12px 25px;
  background-color: transparent;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}
.main-background {
  // .sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
  // }
  background: linear-gradient(161.21deg, #8866ad 12.7%, #485096 119.14%);
  box-shadow: 0px 30px 60px rgba(76, 85, 157, 0.35);
}
.nav-bar {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  /* Heading */

  color: #2d3748;
}
.color-white {
  color: #ffffff;
}

.feature-menu.p-menu-overlay {
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  background: #ffffff;
  // border: 0.56px solid #e9ebf0;
  border-radius: 20px;
  width: 237px;
  .p-menuitem,
  .p-menuitem a {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    // text-align: center;
    color: #2d3748;
    padding: 12px;
  }
  .p-submenu-header {
    padding: 0 !important;
  }
  ul {
    padding: 12px;
  }
  .p-menuitem-link .p-menuitem-text {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #2d3748;
  }
  .p-menuitem-link:not(.p-disabled):hover {
    background: none;
  }
  .p-menuitem-link:focus {
    box-shadow: none;
  }
}
body {
  margin: 0;
}

.navbar {
  // overflow: hidden;
  // background-color: #333;
  width: 100%;
  padding: 0px 90px;
  position: absolute;
  z-index: 1000 !important;
}
.p-submenu-icon {
  display: none;
}
.p-submenu-list {
  margin-top: 40px !important;
  background: #ffffff;
  border: 0.56px solid #e9ebf0;
  border-radius: 20px;
  min-width: 240px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px !important;
  // margin-left: 15px !important;
  // display: block !important,;
  .p-menuitem {
    display: flex;
    // .p-menuitem-link{
    //   background: transparent !important;
    // }
  }
}
.p-menuitem-link:hover {
  background: transparent !important;
  border: none !important;
}
.login-trial {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.navbar a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 6px;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
}

// .topnav a:hover {
// background-color: #ddd;
// color: black;
// }

.topnav a.active {
  // background-color: #04aa6d;
  color: white;
}

.topnav .icon {
  display: none;
}
@media screen and (max-width: 1248px) {
  // .login-trial {
  //   display: block;
  // }
  .yellow-button {
    font-size: 14px;
    // width: 145px !important;
  }
  .navbar a {
    font-size: 14px;
  }
  .logo-name {
    font-size: 14px !important;
  }
  .logo {
    height: 30px;
  }
  .p-menubar-start div {
    width: 150px !important;
  }
}
.responsive-menu-end {
  display: none;
}
@media screen and (max-width: 470px) {
  .responsive-menu-end,
  .responsive-menu-end:hover,
  .responsive-menu-end:focus,
  .responsive-menu-end:active {
    display: block;
    background: transparent !important;
    border: none !important;
    color: #6c757d !important;
    .pi {
      font-size: 20px !important;
    }
  }
  .m-tria-btn,
  .login-button {
    display: none !important;
  }
}
@media screen and (max-width: 600px) {
  .yellow-button > .p-button-label {
    font-size: 12px !important;
    // width: 2rem !important;
  }
  .topnav a:not(:first-child) {
    display: none;
  }
  .navbar a {
    padding: 7px 16px !important;
  }
  .topnav div:not(:first-child) {
    display: none;
  }
  .topnav a.icon {
    float: right;
    display: block;
  }

  .login-trial {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  // }

  // @media screen and (max-width: 600px) {
  .topnav.responsive {
    position: relative;
    background: #5a5a99;
  }
  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
  .topnav.responsive div {
    float: none;
    display: block;
    text-align: left;
  }
  .mp-15 {
    padding: 15px;
  }
  // .m-tria-btn {
  //   padding: 0px !important;
  //   margin-top: 19px;
  // }
}
@media (max-width: 1096px) {
  .navbar {
    padding: 0px 40px;
  }
  .login-button {
    font-size: 14px !important;
    line-height: 12px;
  }
  .logo-name {
    font-size: 14px !important;
  }
}
@media (max-width: 990px) {
  .navbar {
    padding: 0px 20px;
  }
  .login-button {
    color: black !important;
  }
}
#myTopnav {
  color: black;
  text-align: center; /* Centered text */
  font-size: 90px; /* Big font size */
  font-weight: bold;
  position: fixed; /* Fixed position - sit on top of the page */
  top: 0;
  width: 100%; /* Full width */

  transition: 0.2s; /* Add a transition effect (when scrolling - and font size is decreased) */
}
.p-menubar {
  // padding: 0.5rem;
  background: transparent;
  // color: #495057;
  border: transparent;
  // border-radius: 6px;
}
.home-navbar {
  .p-menubar-button .pi-bars {
    color: transparent !important;
  }
  @media (max-width: 960px) {
    .p-menubar-button .pi-bars {
      color: #2d3748 !important;
    }
  }
}
.route-navbar {
  .p-menubar-root-list
    .p-menuitem
    .p-menuitem-content
    .p-menuitem-link
    .p-menuitem-text {
    font-family: "Roboto" !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    color: white !important;
  }
  .p-submenu-list {
    .p-menuitem .p-menuitem-content .p-menuitem-link .p-menuitem-text {
      color: #2d3748 !important;
    }
  }
  .p-menubar-button .pi-bars {
    color: transparent !important;
  }
  @media (max-width: 960px) {
    .p-menubar-button .pi-bars {
      color: white !important;
    }
    .p-menubar-root-list
      .p-menuitem
      .p-menuitem-content
      .p-menuitem-link
      .p-menuitem-text {
      color: #2d3748 !important;
    }
  }
  .p-menubar-end .login-trial .login-button {
    color: white !important;
  }
}
</style>
