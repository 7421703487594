<template>
  <div class="preloader" v-if="loading">
    <div class="preloader-content"></div>
  </div>
  <div v-else>
    <HeroCommon :data="orderPage.header" />
    <div>
      <platform :platform="platform" />
    </div>
    <div>
      <!-- :leftSection="inventoryManagement" -->
      <!-- :data="trackControl" -->
      <OrderManagement
      :data="orderManagement"
      />
    </div>

    <Reviews />
    <div>
      <inventoryHome :inventory="inventory" :data="everything" />
    </div>
    <Blogs />
  </div>
</template>

<script>
import platform from "../components/inventory/Platform.vue";
import Reviews from "../components/reviews/Reviews.vue";
import Blogs from "../components/blogs/Blogs.vue";
import inventoryHome from "../components/inventory/inventoryHome.vue";
import OrderManagement from "../components/inventory/shippingSoftware.vue";
import HeroCommon from "../components/header/HeroCommon.vue";
import { BASE_URL } from "../config.js";
import { Core } from "../api.service";

export default {
  name: "PricingPlan",
  components: {
    platform,
    OrderManagement,
    Reviews,
    Blogs,
    inventoryHome,
    HeroCommon,
  },

  data() {
    return {
      platform: null,
      orderManagement:null,
      // inventoryManagement: null,
      // trackControl: null,
      everything: null,
      orderPage: null,
      loading: false,
      endpoint: `${BASE_URL}order-platform?populate=deep`,
      endpointOrder: `${BASE_URL}feature-orders-management?populate=deep`,
      // endpointinventory: `${BASE_URL}feature-order-management?populate=deep`,
      // endpointTrackControl: `${BASE_URL}feature-easy-fulfill?populate=deep`,
      endpointFeatureShipping: `${BASE_URL}order-everything?populate=deep`,
      endpointOrderManagement: `${BASE_URL}order-management-page?populate=deep`,
      inventory: {
        imageGuide: "guide_order",
        heading: "Understanding Order Management",
      },
    };
  },
  created() {
    this.getPlatform();
  },

  methods: {
    async getPlatform() {
      this.loading = true;
      await Core.get(this.endpoint).then((res) => {
        this.platform = res.data;
        // this.loading = false;
      });
      await Core.get(this.endpointOrder).then((res) => {
        this.orderManagement = res.data.attributes.feature_detail;
        // this.loading = false;
      });
      // await Core.get(this.endpointinventory).then((res) => {
      //   this.inventoryManagement = res.data;
      //   // this.loading = false;
      // });
      // await Core.get(this.endpointTrackControl).then((res) => {
      //   this.trackControl = res.data;
      //   // this.loading = false;
      // });
      await Core.get(this.endpointFeatureShipping).then((res) => {
        this.everything = res.data.attributes.feature_everything;
        // this.loading = false;
      });
      await Core.get(this.endpointOrderManagement).then((res) => {
        this.orderPage = res.data.attributes;
        // this.loading = false;
      });
      this.loading = false;
    },
  },
};
</script>

<style></style>
