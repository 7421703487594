<template>
  <div class="py-4 px-4 lg:px-8 my-5 md:my-4" id="pricing">
    <!-- <transition name="bounce"> -->
      <div class="flex flex-row flex-wrap card-container justify-content-between">
        <BlogCard class="col-12 md:col-4"
          :data="blog"
          :index="index"
          v-for="(blog, index) in blog"
          :key="index"
        />
      </div>
    <!-- </transition> -->
  </div>
</template>

<script>
import { BASE_URL } from "../../config.js";
import { Core } from "../../api.service";
import BlogCard from "./BlogCard.vue";
export default {
  name: "Blogs",
  props: ["data"],
  data() {
    return {
      endpointBlog: `${BASE_URL}blogs?populate=deep`,
      blog: null,
    };
  },
  created() {
    this.getData();
  },
  components: { BlogCard },
  methods: {
    async getData() {
      await Core.get(this.endpointBlog).then((res) => {
        this.blog = res.data;
         // console.log("blog", this.blog);
      });
    },
  },
  computed: {},
};
</script>

<style lang="scss">
.section-blog-card {
  background: #ffffff;
  border: 0.561194px solid #edeaff !important;
  border-radius: 40px;
  // margin: 5px;
}
.section-blog-card:hover {
  background: #ffffff;
  /* colorful shadow */

  box-shadow: 0px 30px 60px rgba(76, 85, 157, 0.25);
  border-radius: 40px;
}
.blog-image {
  width: 100%;
  height: 40%;
  border-radius: 40px 40px 0px 0px;
}
.bounce-enter-active {
  animation: bounce-in 4s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(0.9);
  }
}
</style>
