<template>
  <div class="py-3 px-3 lg:px-6 mx-0 lg:mx-8" id="highlight">
    <div class="grid section-fulfilment">
      <div class="col-12 lg:col-4 px-8 flex flex-column">
        <img :src="getImgUrl(inventory.imageGuide)" class="" alt="" />
      </div>

      <div class="col-12 lg:col-8 px-5 flex flex-column p-t-8r">
        <span class="font-weight-400 font-size-16 line-height-19 color-white">{{
          data.heading
        }}</span>

        <span
          class="m-t-14 font-weight-700 font-size-32 line-height-38 color-white"
          >{{ data.subHeading }}</span
        >
        <span
          class="m-t-32 font-weight-400 font-size-18 line-height-24 color-white"
          >{{ data.description }}</span
        >
        <div class="grid">
          <div class="col-12 lg:col-6">
            <span class="p-input-icon-right m-t-32 w-100">
              <div class="download-pdf-wrapper">
                <span
                  class="color-white font-weight-500 font-size-16 line-height-19"
                  >Download PDF</span
                >
              </div>

              <InputText
                type="email"
                v-model="value3"
                placeholder="You email address"
                class="download-pdf-input"
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from "primevue/inputtext";
export default {
  data() {
    return {};
  },
  methods: {
    getImgUrl(pic) {
      return require("../../../public/img/" + pic + ".svg");
    },
  },
  computed: { InputText },
  props: ["inventory", "data"],
};
</script>

<style lang="scss">
// .section-fulfilment {
//   background: linear-gradient(132.35deg, #8866ad -7.37%, #485096 130.93%);
//   box-shadow: 0px 30px 60px rgba(76, 85, 157, 0.25);
//   border-radius: 40px;
//   color: white;
// }
// .fulfilment-list {
//   list-style: none;
// }
// .fulfilment-list li {
//   margin-top: 2rem;
//   font-family: "Roboto";
//   font-style: normal;
//   font-weight: 400;
//   font-size: 16px;
//   line-height: 24px;
//   /* margin */
// }
// .fulfilment-list li:before {
//   margin-right: 1.5rem;
// }
// .button-yellow {
//   background: linear-gradient(92.56deg, #ff8301 -8.44%, #fea301 108.25%);
//   border-radius: 100px;
//   padding: 15px 0px;
//   width: 16rem;
//   cursor: pointer;
//   text-align: center;
//   font-family: "Roboto";
//   font-style: normal;
//   font-weight: 500;
//   font-size: 16px;
//   line-height: 19px;
// }


.width-380 {
  width: 380px;
}

.download-pdf-wrapper:hover {
  background: #0c1ba0;
}
.download-pdf-wrapper {
  background: #485096;
  border-radius: 100px;
  height: 45px;
  width: 42%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  margin-top: -1.6rem;
  right: 10px;
  cursor: pointer;
}
.download-pdf-input {
  color: rebeccapurple;
  padding: 22px;
  background: #ffffff;
  border-radius: 100px;
  width: 100%;
  height: 55px;
  border: none;
}

.p-t-8r {
  padding-top: 6% !important;
}
</style>
