<template>
  <div class="py-4 px-6 lg:px-8 lg:mx-8 my-5 md:my-4 blog-detail-section" id="pricing">
    <div class="xl:px-8 lg:px-1">
      <img
        :src="getImgUrl(data.images.data[0])"
        class="mx-auto blog-detail-image md:border-round-3xl border-round"
        alt=""
      />
    </div>
    <div class="xl:px-8 lg:px-1">
      <div>
        <p class="blog-date text-base mt-5">{{ data.heading }}</p>
        <p class="blog-title lg:text-3xl text-xl text-left">
          {{ data.sub_heading }}
        </p>
        <p class="blog-desc text-base">
          {{ data.short_description }}
        </p>
        <!-- <p class="blog-desc">
          Tempus imperdiet nulla malesuada pellentesque elit eget gravida cum. Sit
          amet ris nullam eget felis. Enim praesent elementum facilisis leo.
          Ultricies leo integer.
        </p> -->
      </div>
      <div class="mt-5">
        <img
          :src="getImgUrl(data.images.data[1])"
          class="mx-auto blog-detail-image md:border-round-3xl border-round"
          alt=""
        />
      </div>
      <div>
        <!-- <p class="blog-date">February 2, 2022</p> -->
        <!-- <p class="blog-title">To-Increase: Warehouse Management</p> -->
        <p class="blog-desc text-base lg:mt-5 mt-2">
          {{ data.long_description }}
        </p>
        <!-- <p class="blog-desc">
          Tomfoolery crikey bits and bobs brilliant bamboozled down the pub
          amongst brolly hanky panky, cack bonnet arse over tit burke bugger all
          mate bodge. cillum dolore eu fugiat nulla pariatur. Excepteur sint
          occaecat cupidatat non proident, sunt in culpa qui officia deserunt
          mollit anim id est laborum.Suspendisse interdum consectetur libero id
          faucibu nisl. Lacus vel facilisis volutpat est velit egestas.
        </p> -->
      </div>
      <div class="flex align-items-center lg:mt-5 mt-2">
        <span
          class="font-weight-400 text-base line-height-22 font-color-text pl-0 p-2"
          >Share</span
        >
        <div v-for="share in data.link" :key="share">
          <a @click="socialTag(share.ref)">
            <img class="m-1" :src="getIconUrl(share.icon)" alt="" />
          </a>
        </div>
  
        <!-- <img class="m-1" src="img/facebook.png" alt="" />
        <img class="m-1" src="img/instagram.png" alt="" />
        <img class="m-1" src="img/twitter.png" alt="" />
        <img class="m-1" src="img/youtube.png" alt="" /> -->
      </div>
    </div>
  </div>
</template>

<script>
import { Core } from "../../api.service";
import moment from "moment";

export default {
  name: "BlogDetail",
  props: ["data"],
  data() {
    return {};
  },
  components: {},
  methods: {
    moment: function (date) {
      return moment(date).format("MMMM D, YYYY");
    },
    getImgUrl(pic) {
      if (pic) {
        return Core.getImageSingle(pic);
      }
    },
    getIconUrl(pic) {
      if (pic) {
        return Core.getImage(pic);
      }
    },
    socialTag() {
      window.open("https://www.github.com/", "_blank");
    },
  },
  computed: {},
  created() {
    // console.log("this.data", this.data);
  },
};
</script>

<style lang="scss">
// .blog-detail-section {
//   margin: 18rem;
// }

.section-blog-card:hover {
  background: #ffffff;
  box-shadow: 0px 30px 60px rgba(76, 85, 157, 0.25);
  border-radius: 40px;
}

.blog-date {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #718096;
}
.blog-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 33px;
  // text-align: center;
  color: #2d3748;
}
.blog-desc {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #718096;
}
</style>
