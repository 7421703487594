<template>
  <div class="px-4 lg:px-8 text-center section-conatiner" v-if="connection">
    <div class="col-12 pt-7">
      <h2
        class="font-weight-400 font-size-16 line-height-19 text-center text-transform-uppercase body-text-color"
      >
        {{ connection.heading }}
      </h2>
      <span
        class="font-weight-700 font-size-28 line-height-38 text-center font-color-text"
      >
        {{ connection.subHeading }}
      </span>
      <div class="m-mt-5 mp-content" style="margin-top: 15px">
        <span
          class="font-weight-400 font-size-16 line-height-19 text-center body-text-color"
        >
          {{ connection.description }}</span
        >
      </div>
    </div>
    <div class="col-12 m-mt-5 mx-auto">
      <Button
        :label="connection.btn_try"
        class="yellow-button"
        @click="tryFree(connection.ref)"
      ></Button>
    </div>
  </div>
</template>

<script>
import "vue-slider-component/theme/antd.css";
import { BASE_URL } from "../../config.js";
import { Core } from "../../api.service";
export default {
  name: "Connection",
  components: {},
  data() {
    return {
      value: 500,
      formatter1: "{value}\norders",
      endpoint: `${BASE_URL}own-connection?populate=deep`,
      connection: null,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      await Core.get(this.endpoint).then((res) => {
        this.connection = res.data.attributes.connection;
      });
    },
    tryFree(url) {
      window.open(url);
    },
  },
  computed: {},
};
</script>

<style lang="scss">
.section-conatiner {
  background: rgb(246, 244, 255);
}
// .list-item {
//   font-family: "Roboto";
//   font-style: normal;
//   font-weight: 400;
//   font-size: 16px;
//   line-height: 36px;
//   color: #718096;
// }

// .price-heading {
//   font-family: "Roboto";
//   font-style: normal;
//   font-weight: 400;
//   font-size: 18px;
//   line-height: 21px;
//   color: #485096;
// }

// .dollar-price {
//   font-family: "Roboto";
//   font-style: normal;
//   font-weight: 700;
//   font-size: 32px;
//   line-height: 38px;
//   color: #485096;
// }

// .price-month {
//   font-family: "Roboto";
//   font-style: normal;
//   font-weight: 400;
//   font-size: 24px;
//   line-height: 28px;
//   color: #718096;
// }
// .tick-icon {
//   height: 20px;
//   width: 20px;
//   border-radius: 100px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background: #f8f7ff;
// }

// .m-l-15 {
//   margin-left: 15px;
// }
// .flex-center {
//   display: flex;
//   align-items: center;
// }

// .plan-btn {
//   background: #485096;
//   border-radius: 100px;
//   width: 201px;
//   height: 45px;
//   font-family: "Roboto";
//   font-style: normal;
//   font-weight: 500;
//   font-size: 16px;
//   line-height: 19px;
//   color: #ffffff;
// }

// .plan-section {
//   background: #ffffff;
//   border: 0.561194px solid #e9ebf0;
//   border-radius: 40px;
//   padding: 42px 22px 42px 22px;
// }
//   .price-section-conatiner {
// background: linear-gradient(
//   180deg,
//   #f6f4ff 0%,
//   rgba(246, 244, 255, 0.6) 92.51%,
//   rgba(246, 243, 255, 0) 100%
// );
//   }
// .bill-monthly {
//   margin-left: 7px;
//   background: #485096;
//   border-radius: 100px;
//   padding: 13px 32px;
//   font-family: "Roboto";
//   font-style: normal;
//   font-weight: 500;
//   font-size: 16px;
//   line-height: 19px;
//   color: #ffffff;
// }
// .bill-yearly {
//   margin-left: 7px;
//   border-radius: 100px;
//   padding: 13px 32px;
//   font-family: "Roboto";
//   font-style: normal;
//   font-weight: 500;
//   font-size: 16px;
//   line-height: 19px;
//   color: #464e92;
//   margin-right: 7px;
// }
// .popular-tag {
//   margin-left: 10px;
//   padding: 0px 8px;
//   background: linear-gradient(92.56deg, #ff8301 -8.44%, #fea301 108.25%);
//   border-radius: 100px;
// }
// .vue-slider-rail {
//   background: #f3f0ff;
//   border-radius: 100px;
//   height: 9px;
// }
// .vue-slider-process {
//   background: linear-gradient(136.57deg, #8866ad -43.55%, #485096 112.25%);
//   box-shadow: 0px 30px 60px rgb(76 85 157 / 35%);
//   border-radius: 40px;
// }
// .vue-slider-dot-handle {
//   background: #62599f;
//   cursor: pointer;
//   width: 100%;
//   height: 100%;
//   border-radius: 50%;
//   border: 5px solid white;
//   box-sizing: border-box;
//   transition: box-shadow 0.3s, border-color 0.3s;
// }
// .vue-slider-dot {
//   width: 23px !important;
//   height: 23px !important;
// }
// .vue-slider-dot-handle:hover {
//   border-color: #ffffff !important;
// }
// .vue-slider:hover .vue-slider-dot-handle-focus {
//   border-color: #ffffff !important;
// }
// .vue-slider-dot-tooltip-inner {
//   font-size: 14px;
//   white-space: nowrap;
//   padding: 6px 8px;
//   color: #fff;
//   border-radius: 5px;
//   border-color: #62599f;
//   background-color: #62599f;
//   box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
//   transform: scale(0.9);
//   transition: transform 0.3s;
// }
</style>
