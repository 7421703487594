<template>
  <div class="py-8 px-4 lg:px-8 support-section-conatiner" id="pricing">
    <div v-if="articles.length" class="grid justify-content-between md:mt-0">
      <ArticleCard
        :data="articles[index - 1]"
        :index="index"
        v-for="index in articles.length"
        :key="index"
      />
    </div>
  </div>
</template>

<script>
import ArticleCard from "./ArticleCard.vue";
import { BASE_URL } from "../../config.js";
import { Core } from "../../api.service";
export default {
  name: "Menu",
  components: { ArticleCard },
  data() {
    return {
      endpoint: `${BASE_URL}article-sections?populate=deep`,
      articles: [],
      value: 500,
      formatter1: "{value}\norders",
      article: [
        {
          title: "Latest Articles",
        },
        {
          title: "Most Popular Help Articles",
        },
        {
          title: "Most Helpful Articles",
        },
      ],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      await Core.get(this.endpoint).then((res) => {
        this.articles = res.data;
      });
    },
  },
  computed: {},
};
</script>

<style lang="scss">
.support-section-conatiner {
  background: rgba(246, 244, 255, 0.6) 92.51%;
}
</style>
