<template>
  <div class="preloader" v-if="loading">
    <div class="preloader-content"></div>
  </div>
  <div v-else>
    <HeroCommon v-if="integration" :data="integration.header" />
    <Connection />
    <Marketplaces v-if="integration" :data="integration.marketplaces.data" />
    <Reviews />
    <Fulfilment />
    <Blogs />
  </div>
</template>

<script>
import Reviews from "../components/reviews/Reviews.vue";
import Fulfilment from "../components/fulfilment/Fulfilment.vue";
import Blogs from "../components/blogs/Blogs.vue";
import Connection from "../components/integration/Connection.vue";
import Marketplaces from "../components/integration/Marketplaces.vue";
import HeroCommon from "../components/header/HeroCommon.vue";

import { BASE_URL } from "../config.js";
import { Core } from "../api.service";

export default {
  data() {
    return {
      endpoint: `${BASE_URL}integration?populate=deep`,
      loading: false,
      integration: null,
      blog: null,
      fulfilment: null,
    };
  },
  components: {
    Reviews,
    Fulfilment,
    Blogs,
    Connection,
    Marketplaces,
    HeroCommon,
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      this.loading = true;
      await Core.get(this.endpoint).then((res) => {
        this.integration = res.data.attributes;
        this.loading = false;
      });

      // await Core.get(this.endpointBlog).then((res) => {
      //   this.blog = res.data.attributes;
      //   console.log("blog-section", this.blog);
      // });
      // await Core.get(this.endpointFulfilment).then((res) => {
      //   this.fulfilment = res.data.attributes;
      //   console.log("fulfilment-section", this.fulfilment);
      // });
    },
  },
  computed: {},
};
</script>

<style lang="scss"></style>
