<template>
  <div class="col-12 lg:col-3 md:col-6 md:p-3">
    <router-link
      :to="'/support-detail/' + index"
      v-if="data"
      class="hover-bg flex xl:align-items-center lg:align-items-start align-items-center xl:flex-row lg:flex-column flex-row border-200 marketplace px-3 py-5"
      style="height: 100%"
    >
      <div class="col-3 text-left">
        <img :src="getIconUrl(data.icon)" alt="" class="" />
      </div>
      <div class="xl:col-9 lg:col-12 col-9 lg:ml-2 ml-0">
        <span
          class="font-weight-700 font-size-16 line-height-21 font-color-text hover-bold"
        >
          {{ data.name }}
        </span>
        <br />
      </div>
    </router-link>
  </div>
</template>

<script>
import { Core } from "../../api.service";

export default {
  name: "MenuCard",
  props: ["index", "data"],
  components: {},
  data() {
    return {
      value: 500,
      formatter1: "{value}\norders",
    };
  },
  methods: {
    getIconUrl(pic) {
      if (pic) {
        return Core.getImage(pic);
      }
    },
  },
  computed: {},
};
</script>

<style lang="scss"></style>
