<template>
  <div style="background-color: #faf9ff">
    <div class="py-4 px-4 lg:px-8 mx-0 lg:mx-5" id="highlight" v-if="aboutUs">
      <div class="grid mt-8 pb-2 md:pb-8">
        <div
          class="flex justify-content-center col-12 lg:col-6 md:col-6 p-0 flex-order-1 md:flex-order-0 border-radius-8 text-left"
        >
          <img
            src="img/about_video.svg"
            class="xl:w-11 lg:w-11 md:w-10 w-8"
            alt=""
          />
        </div>

        <div class="col-12 lg:col-6 md:col-6 flex flex-column">
          <span
            class="font-weight-400 text-base line-height-24 m-b-14 body-text-color"
            >{{ aboutUs.heading }}</span
          >
          <span class="section-heading lg:text-4xl text-3xl">
            {{ aboutUs.sub_heading }}
          </span>
          <span class="section-body text-base">{{ aboutUs.description }} </span>
          <div class="flex align-items-center m-2 border-botom-default">
            <div
              @click="changeToggle(true)"
              :class="[toggle ? 'border-botom-hover' : '']"
              class="flex align-items-center lg:p-12 p-10 cursor"
            >
              <div
                class="icon-circle bg-white flex align-items-center justify-content-center border-radius-100"
              >
                <img src="img/yello_flag.svg" alt="flag" />
              </div>
              <div class="m-l-12">
                <span
                  :style="[toggle ? 'font-weight: 600 !important;' : '']"
                  :class="[toggle ? 'head-text-color' : '']"
                  class="font-weight-500  lg:text-lg text-base line-height-21 body-text-color"
                  >{{ aboutMissionTabs.heading }}
                </span>
              </div>
            </div>
            <div
              @click="changeToggle(false)"
              :class="[toggle ? '' : 'border-botom-hover']"
              class="flex align-items-center lg:p-12 p-10 cursor"
            >
              <div
                class="bg-white icon-circle border-radius-100 m-l-47 flex justify-content-center align-items-center"
              >
                <img src="img/blue_eye.svg" alt="eye" />
              </div>

              <div class="m-l-12">
                <span
                  :style="[toggle ? '' : 'font-weight: 600 !important;']"
                  :class="[toggle ? '' : 'head-text-color']"
                  class="font-weight-500 lg:text-lg text-base line-height-21 body-text-color"
                  >{{ aboutVisionTabs.heading }}</span
                >
              </div>
            </div>
          </div>
          <span class="section-body text-base">{{ sectionBody }} </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { BASE_URL } from "../../config.js";
import { Core } from "../../api.service";
export default {
  name: "AboutUs",
  components: {},
  data() {
    return {
      aboutMissionTabs: [],
      aboutVisionTabs: [],
      endpointAbout: `${BASE_URL}about?populate=deep`,
      aboutUs: null,

      toggle: true,
      sectionBody: ``,
    };
  },
  methods: {
    async getData() {
      await Core.get(this.endpointAbout).then((res) => {
        this.aboutUs = res.data.attributes;
        // console.log("about", this.aboutUs);
      });
    },
    changeToggle(value) {
      this.toggle = value;
      if (value) {
        this.sectionBody = this.aboutMissionTabs.description;
      } else {
        this.sectionBody = this.aboutVisionTabs.description;
      }
    },
  },
  computed: {},
  async created() {
    await this.getData();
    this.aboutMissionTabs = this.aboutUs.about_tabs.data[0].attributes;
    this.aboutVisionTabs = this.aboutUs.about_tabs.data[1].attributes;
    this.changeToggle(true);
    // console.log("data", this.aboutUs);
  },
};
</script>

<style lang="scss">
// .img-about {
//   width: 100%;
//   padding: 5%;
// }
</style>
