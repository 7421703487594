<template>
  <div class="preloader" v-if="loading">
    <div class="preloader-content"></div>
  </div>
  <div v-else>
    <div class="grid header m-0" id="">
      <div
        class="col-12 lg:col-5 mt-0 m-content-hero"
        style="background: #f6f4ff; z-index: 1"
      >
        <div class="home-tagline">
          <span>{{ hero.tagline }}</span>
        </div>
        <h1
          style="color: #4a539a"
          class="font-bold font-weight-700 font-size-36 line-height-42 body-text-color"
        >
          {{ hero.heading }}
          <span style="color: #60b4e0">{{ hero.subHeading }}</span>
        </h1>
        <p
          style="color: #343434"
          class="line-height-3 md:mt-5 font-weight-400 font-size-16 line-height-24"
        >
          {{ hero.description }}
        </p>
        <p
          style="color: #485096"
          class="line-height-3 md:mt-5 font-weight-500 text-lg line-height-24"
        >
          {{ hero.second_subheading }}
        </p>
        <div class="flex justify-content-between mt-8">
          <Button
            :label="hero.btn_try.label"
            class="yellow-button font-size-16"
            style="width: 158px"
            @click="tryFree(hero.btn_try.ref)"
          ></Button>
          <!-- <a style="display: flex; align-items: center" href=""
            ><img
              :src="Core.getImage(hero.btn_video.icon)"
              class="point"
            /><span
              class="ml-2 font-weight-500 head-text-color font-size-16 m-text-none"
              >{{ hero.btn_video.label }}</span
            >
          </a> -->
        </div>
      </div>
      <div
        @mousemove="Core.onMousemove('container', 'inner')"
        class="col-12 lg:col-7 m-rect-hero text-center"
        id="container"
        style="
          background-image: url('img/rectangle.png');
          background-repeat: no-repeat;
          background-position: right top;

          background-color: #f6f4ff;
        "
      >
        <img
          :src="Core.getImage(hero.image)"
          class="bottom-0 lg:w-12 w-8"
          id="inner"
          alt="hero screen"
          style="right: 10%"
        />
        <div class="area">
          <ul class="circles">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
      </div>
    </div>
    <div
      class="grid sub-header m-0"
      style="
        background: linear-gradient(
          180deg,
          #f6f4ff 0%,
          rgba(246, 244, 255, 0.6) 92.51%,
          rgba(246, 243, 255, 0) 100%
        );
      "
      id=""
    >
      <div
        class="col-12 lg:col-4 mt-0 m-content-hero"
        style="
          z-index: 1;
          padding-top: 5rem !important;
          padding-bottom: 0rem !important;
        "
      >
        <span class="section-heading lg:text-4xl text-3xl">
          {{ hero.section_2_heading }}
        </span>
      </div>
      <div
        class="col-12 lg:col-8 mt-0 m-content-hero"
        style="
          z-index: 1;
          color: #343434;
          padding-top: 5rem !important;
          padding-bottom: 0rem !important;
        "
      >
        <span class="font-weight-400 font-size-16 line-height-24 m-t-24">
          {{ hero.section_2_desc }}
        </span>
        <br /><br />

        <span class="font-weight-400 font-size-16 line-height-24 m-t-24"
          >{{ hero.section_2_subdesc }}
        </span>
      </div>

      <div
        class="col-12 lg:text-center mt-0 m-content-hero"
        style="z-index: 1; color: #343434; padding-top: 5rem !important"
      >
        <hr />
        <p
          class="section-heading lg:text-4xl text-3xl"
          style="padding: 5rem 2rem 2rem 2rem !important"
        >
          {{ hero.section_3_heading }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { BASE_URL } from "../../config.js";
import { Core } from "../../api.service";
export default {
  data() {
    return {
      loading: false,
      Core: Core,
      hero: null,
      endpoint: `${BASE_URL}hero?populate=deep`,
    };
  },
  created() {
    this.getHeroContent();
  },
  methods: {
    async getHeroContent() {
      this.loading = true;
      await Core.get(this.endpoint).then((res) => {
        this.hero = res.data.attributes;
        this.loading = false;
      });
    },
    tryFree(url) {
      window.open(url);
    },
  },
};
</script>

<style>
#container {
  perspective: 30px;
}

#inner {
  transition: transform 0.5s;
  -webkit-transition: transform 0.5s;
}

.demo-img {
  border: solid white 15px;
  background-image: url("https://images.unsplash.com/photo-1469474968028-56623f02e42e?auto=format&fit=crop&w=1953&q=80");
  background-size: cover;
  background-repeat: no-repeat;
}

#container:hover .demo-overlay {
  opacity: 1;
}

#container:hover {
  cursor: pointer;
}

.demo-overlay {
  width: 25em;
  padding: 10em 0;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.4s;
}

.demo-overlay svg {
  display: block;
  margin: 0 auto;
  fill: white;
}
.point {
  animation: point 1.8s infinite linear both;
}

@keyframes point {
  from,
  to {
    opacity: 0.5;
    transform: scale(0.8);
  }

  50% {
    opacity: 1;
    transform: scale(1);
  }
}
.home-tagline {
  background: rgba(96, 180, 224, 0.2);
  border: 1px solid #60b4e0;
  border-radius: 100px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #2d3748;
  padding: 5px;
  text-align: center;
}
</style>
