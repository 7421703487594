<template>
  <HeroCommon
    v-if="support"
    :data="support.header"
    :searchUrl="searchEndpoint"
    @serachList="searchData"
  />
  <div v-if="listSearch.key">
    <search-list :list="listSearch" />
  </div>
  <div class="support-main xl:px-8 md:px-5 sm:px-8 px-5 py-8" v-else>
    <div class="grid lg:mx-5">
      <div class="col-12 md:col-3 border-40 support-list">
        <MenuList />
      </div>
      <div class="faq lg:pl-5 col-12 lg:col-8 md:col-8 md:mx-5 md:mt-0 mt-5 border-40"  style=" background-color: white;">
        <div class="support-section lg:px-6 md:px-4 px-3 py-7 border-40">
          <div v-if="product">
            <AddProduct :articleId="articleId"/> 
          </div>
          <ProductManagement
          @addProduct="addProduct"
            v-else
            :id = "SupportId"
            :data="SupportDetail"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProductManagement from "../components/support/ProductManagement.vue";
import MenuList from "../components/support/MenuList.vue";
import HeroCommon from "../components/header/HeroCommon.vue";
import AddProduct from "../components/support/AddProduct.vue";
import SearchList from "../components/shared/searchList.vue";

import { BASE_URL } from "../config.js";
import { Core } from "../api.service";

export default {
  components: {
    ProductManagement,
    MenuList,
    HeroCommon,
    AddProduct,
    SearchList,
  },

  data() {
    return {
      faq: {
        image: "support2",
        centerText: "Advice and answers from the Sell O Mate Team",
        fieldPlaceholder: "",
      },
      product: false,
      endpoint: `${BASE_URL}support-page?populate=deep`,
      searchEndpoint: `${BASE_URL}articles?filters[heading][$startsWith]=`,

      support: null,
      SupportId: null,
      SupportDetail: null,
      articleId: null,
      listSearch: [],
    };
  },
  created() {
    this.SupportId = this.$route.params.id;
    if (this.$route.params.article || "") {
      this.articleId = this.$route.params.article
      this.product = true
       // console.log("artciel details");
    }
    this.getSupport();
  },
  mounted(){
     // console.log('article id here')
  },
  methods: {
    addProduct(event) {
       // console.log("eevnt", event);
      this.articleId = event;
      this.product = true;
    },

    searchData(data) {
       // console.log("data-data", data);
      this.listSearch = data;
    },
    async getSupport() {
      await Core.get(this.endpoint).then((res) => {
        this.support = res.data.attributes;
      });
      await Core.get(
        `${BASE_URL}article-categories/${this.SupportId}?populate=deep`
      ).then((res) => {
        this.SupportDetail = res.data.attributes;
         // console.log("SupportDetail-SupportDetail", this.SupportDetail);
      });
    },
  },
};
</script>
<style lang="scss">
.support-main {
  // padding: 4rem 8rem;
  background: #faf9ff;
  .support-list {
    background: #ffff;
    // border-radius: 40px;
    padding: 4rem 1.2rem;
  }
  .support-section {
    background: #ffff;
    // border-radius: 40px;
    // padding: 3.5rem 3rem;
    // height: 100%;
  }

  ::-webkit-scrollbar {
    width: 5px;
    opacity: 0.1;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(201, 200, 200);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
@media screen and (max-width: 600px) {
  .support-main {
    padding: 1rem 3rem;
    .support-list {
      padding: 4rem 1rem;
    }
    .support-section {
      padding: 3rem 2rem;
      height: 100%;
    }
  }
}
</style>
