// import { createRouter, createWebHashHistory } from "vue-router";
import Website from "./pages/Website.vue";
import Home from "./pages/Home.vue";
import About from "./pages/AboutUs.vue";
import ContactUs from "./pages/ContactUs.vue";
import Pricing from "./pages/Pricing.vue";
// import pricing from "./pages/Pricing.vue";
import Blog from "./pages/Blog.vue";
import BlogDetail from "./pages/BlogDetail.vue";
import CustomerStory from "./pages/CustomerStory.vue";
import CustomerStoryDetail from "./pages/CustomerStoryDetail.vue";
import Integration from "./pages/Integration.vue";
import IntegrationDetail from "./pages/IntegrationDetail.vue";
import Inventory from "./pages/Inventory.vue";
import OrderManagement from "./pages/OrderManagement";
import ProductCatalog from "./pages/ProductCatalog.vue";
import FetaureShippingSoftware from "./pages/FeatureShippingSoftware.vue";
import PrivacyPolicy from "./pages/PrivacyPolicy.vue";
import TermsAndConditions from "./pages/TermsAndConditions.vue"
import Support from "./pages/Support.vue";
import SupportDetail from "./pages/SupportDetail.vue";
import Faq from "./pages/Faq.vue";

const routes = [
  {
    path: "",
    component: Website,
    name: "Website",
    children: [
      { path: "", redirect: { name: "Home" } },
      {
        path: "/",
        name: "Home",
        css: "black",
        component: Home,
        meta: {
          title: 'Sellomate | Home',
          metaTags: [
            {
              name: 'description',
              content: 'Sellomate is a platform providing all one solution for e-commerce store owners. Want to know how it helps? Try a 30-day free trial today!',
            },
            {
              property: 'og:title',
              content: 'Sellomate | Home',
            },
            {
              property: 'og:description',
              content: 'Sellomate is a platform providing all one solution for e-commerce store owners. Want to know how it helps? Try a 30-day free trial today!',
            },
            // Add more meta tags as needed
          ],
        },
      },
      {
        path: "/about-us",
        name: "About",
        css: "white",
        component: About,
        meta: {
          title: 'Sellomate | About',
          metaTags: [
            {
              name: 'description',
              content: 'Want to know more about us? Read out our complete journey and what is the mission behind Sellomate. ',
            },
            {
              property: 'og:title',
              content: 'Sellomate | About',
            },
            {
              property: 'og:description',
              content: 'Want to know more about us? Read out our complete journey and what is the mission behind Sellomate. ',
            },
            // Add more meta tags as needed
          ],
        },
      },
      {
        path: "/blog",
        name: "blog",
        css: "white",
        component: Blog,
        meta: {
          title: 'Sellomate | Blog',
          metaTags: [
            {
              name: 'description',
              content: 'Learn more about e-commerce and read our latest blogs and articles on how Sellomate can help you in growing your business.',
            },
            {
              property: 'og:title',
              content: 'Sellomate | Blog',
            },
            {
              property: 'og:description',
              content: 'Learn more about e-commerce and read our latest blogs and articles on how Sellomate can help you in growing your business.',
            },
            // Add more meta tags as needed
          ],
        },
      },
      {
        path: "/blog-detail/:id",
        name: "BlogDetail",
        component: BlogDetail,
        meta: {
          title: 'Sellomate | Blog',
          metaTags: [
            {
              name: 'description',
              content: 'Learn more about e-commerce and read our latest blogs and articles on how Sellomate can help you in growing your business.',
            },
            {
              property: 'og:title',
              content: 'Sellomate | Blog',
            },
            {
              property: 'og:description',
              content: 'Learn more about e-commerce and read our latest blogs and articles on how Sellomate can help you in growing your business.',
            },
            // Add more meta tags as needed
          ],
        },
      },
      {
        path: "/customer-story",
        name: "CustomerStory",
        css: "white",
        component: CustomerStory,
        meta: {
          title: 'Sellomate | Customer Story',
          metaTags: [
            {
              name: 'description',
              content: 'Our customer success is our success and that is why we feel proud to share it with you. Read out our customer stories and how we helped them in growing businesses.',
            },
            {
              property: 'og:title',
              content: 'Sellomate | Customer Story',
            },
            {
              property: 'og:description',
              content: 'Our customer success is our success and that is why we feel proud to share it with you. Read out our customer stories and how we helped them in growing businesses.',
            },
            // Add more meta tags as needed
          ],
        },
      },
      {
        path: "/customer-story-detail/:id",
        name: "customerStoryDetail",
        component: CustomerStoryDetail,
        meta: {
          title: 'Sellomate | Customer Story',
          metaTags: [
            {
              name: 'description',
              content: 'Our customer success is our success and that is why we feel proud to share it with you. Read out our customer stories and how we helped them in growing businesses.',
            },
            {
              property: 'og:title',
              content: 'Sellomate | Customer Story',
            },
            {
              property: 'og:description',
              content: 'Our customer success is our success and that is why we feel proud to share it with you. Read out our customer stories and how we helped them in growing businesses.',
            },
            // Add more meta tags as needed
          ],
        },
      },
      {
        path: "/pricing",
        name: "Pricing",
        css: "white",
        component: Pricing,
        meta: {
          title: 'Sellomate | Pricing',
          metaTags: [
            {
              name: 'description',
              content: 'Get to know our latest pricing and package and if you want a customized one we got you covered! Book a call now!',
            },
            {
              property: 'og:title',
              content: 'Sellomate | Pricing',
            },
            {
              property: 'og:description',
              content: 'Get to know our latest pricing and package and if you want a customized one we got you covered! Book a call now!',
            },
            // Add more meta tags as needed
          ],
        },
      },
      {
        path: "/integration",
        name: "Integration",
        css: "white",
        component: Integration,
        meta: {
          title: 'Sellomate | Integrations',
          metaTags: [
            {
              name: 'description',
              content: 'WIth Sellomate you can not integrate 30+ platforms and enjoy a seamless experience just with one dashboard',
            },
            {
              property: 'og:title',
              content: 'Sellomate | Integrations',
            },
            {
              property: 'og:description',
              content: 'WIth Sellomate you can not integrate 30+ platforms and enjoy a seamless experience just with one dashboard',
            },
            // Add more meta tags as needed
          ],
        },
      },
      {
        path: "/integration-detail/:id",
        name: "IntegrationDetail",
        component: IntegrationDetail,
        meta: {
          title: 'Sellomate | Integrations',
          metaTags: [
            {
              name: 'description',
              content: 'WIth Sellomate you can not integrate 30+ platforms and enjoy a seamless experience just with one dashboard',
            },
            {
              property: 'og:title',
              content: 'Sellomate | Integrations',
            },
            {
              property: 'og:description',
              content: 'WIth Sellomate you can not integrate 30+ platforms and enjoy a seamless experience just with one dashboard',
            },
            // Add more meta tags as needed
          ],
        },
      },
      {
        path: "/inventory-management",
        name: "Inventory",
        css: "white",
        component: Inventory,
        meta: {
          title: 'Sellomate | Automated Inventory Management',
          metaTags: [
            {
              name: 'description',
              content: 'Inventory managment now on finger tips! With Sellomate automated inventory management you can now easily manage your inventory from anywhere around the world',
            },
            {
              property: 'og:title',
              content: 'Sellomate | Automated Inventory Management',
            },
            {
              property: 'og:description',
              content: 'Inventory managment now on finger tips! With Sellomate automated inventory management you can now easily manage your inventory from anywhere around the world',
            },
            // Add more meta tags as needed
          ],
        },
      },
      {
        path: "/order-management",
        name: "OrderManagement",
        css: "white",
        component: OrderManagement,
        meta: {
          title: 'Sellomate | Order Management System',
          metaTags: [
            {
              name: 'description',
              content: 'Manage your order from different platforms from one dashboard! With Sellomate order management system you can manage order easily.',
            },
            {
              property: 'og:title',
              content: 'Sellomate | Order Management System',
            },
            {
              property: 'og:description',
              content: 'Manage your order from different platforms from one dashboard! With Sellomate order management system you can manage order easily.',
            },
            // Add more meta tags as needed
          ],
        },
      },
      {
        path: "/shipping-software",
        name: "shippingSoftware",
        css: "white",
        component: FetaureShippingSoftware,
        meta: {
          title: 'Sellomate | Shipping Management Software',
          metaTags: [
            {
              name: 'description',
              content: 'Connect different shipping carriers with one. Sellomate is your shipping management software allowing you to take care all your shipping needs from one place.',
            },
            {
              property: 'og:title',
              content: 'Sellomate | Shipping Management Software',
            },
            {
              property: 'og:description',
              content: 'Connect different shipping carriers with one. Sellomate is your shipping management software allowing you to take care all your shipping needs from one place.',
            },
            // Add more meta tags as needed
          ],
        },
      },
      {
        path: "/contact-us",
        name: "ContactUs",
        css: "white",
        component: ContactUs,
        meta: {
          title: 'Sellomate | Contact us',
          metaTags: [
            {
              name: 'description',
              content: 'Feel free to reach us from anywhere at any time we are here to help you. Just drop your query and we will get back to you shortly.',
            },
            {
              property: 'og:title',
              content: 'Sellomate | Contact us',
            },
            {
              property: 'og:description',
              content: 'Feel free to reach us from anywhere at any time we are here to help you. Just drop your query and we will get back to you shortly.',
            },
            // Add more meta tags as needed
          ],
        },
      },
      {
        path: "/product-catalog",
        name: "ProductCatalog",
        css: "white",
        component: ProductCatalog,
        meta: {
          title: 'Sellomate | Product Catalog',
          metaTags: [
            {
              name: 'description',
              content: 'Create attractive and interesting product catalog with Sellomate. Now within minutes you can create your product catalog. Try demo today!',
            },
            {
              property: 'og:title',
              content: 'Sellomate | Product Catalog',
            },
            {
              property: 'og:description',
              content: 'Create attractive and interesting product catalog with Sellomate. Now within minutes you can create your product catalog. Try demo today!',
            },
            // Add more meta tags as needed
          ],
        },
      },
      {
        path: "/privacy-policy",
        name: "PrivacyPolicy",
        css: "white",
        component: PrivacyPolicy,
      },
      {
        path: "/terms-and-conditions",
        name: "TermsAndConditions",
        css: "white",
        component: TermsAndConditions,
      },
      {
        path: "/support",
        name: "Support",
        component: Support,
        meta: {
          title: 'Sellomate | Support',
          metaTags: [
            {
              name: 'description',
              content: 'Our 24/7 customer support is always available. Drop your query and we will help you in solving it with our live chat support.',
            },
            {
              property: 'og:title',
              content: 'Sellomate | Support',
            },
            {
              property: 'og:description',
              content: 'Our 24/7 customer support is always available. Drop your query and we will help you in solving it with our live chat support.',
            },
            // Add more meta tags as needed
          ],
        },
      },
      {
        path: "/support-detail/:id/:article?",
        name: "SupportDetail",
        component: SupportDetail,
        meta: {
          title: 'Sellomate | About',
          metaTags: [
            {
              name: 'description',
              content: 'Our 24/7 customer support is always available. Drop your query and we will help you in solving it with our live chat support.',
            },
            {
              property: 'og:title',
              content: 'Sellomate | About',
            },
            {
              property: 'og:description',
              content: 'Our 24/7 customer support is always available. Drop your query and we will help you in solving it with our live chat support.',
            },
            // Add more meta tags as needed
          ],
        },
      },
      {
        path: "/faq",
        name: "Faq",
        css: "white",
        component: Faq,
        meta: {
          title: 'Sellomate | FAQs',
          metaTags: [
            {
              name: 'description',
              content: 'Did you get questions? Sellomate got answers to all those questions. Check our FAQs where we are already answering all your queries.',
            },
            {
              property: 'og:title',
              content: 'Sellomate | FAQs',
            },
            {
              property: 'og:description',
              content: 'Did you get questions? Sellomate got answers to all those questions. Check our FAQs where we are already answering all your queries.',
            },
            // Add more meta tags as needed
          ],
        },
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: About,
  },
  // {
  //   path: "/pricing",
  //   name: "pricing",
  //   component: pricing,
  // },
];

// const router = createRouter({
//   history: createWebHashHistory(),
//   routes,
// });

export default routes;
