import axios from "axios";
import { API_BASE_URL } from "./config.js";

// const config = {
//   headers: { "Content-Type": "application/octet-stream" }
// }
axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";

function get(url) {
  return axios.get(url).then((res) => res.data);
}

function post(url, payload) {
  // console.log("data is pauload", payload);
  return axios
    .post(url, { data: payload })
    .then((res) => res.data)
    .catch((err) => new Error(err)
    // err
    //  {
    //   // console.log("errrr", err);
    // }
    );
}

function getImage(pic) {
  if (pic) {
    return API_BASE_URL + pic.data.attributes.url;
  }
}
function getSvg(pic) {
  return API_BASE_URL + pic.data[0].attributes.url;
}
function getImageSingle(pic) {
  return API_BASE_URL + pic.attributes.url;
}

function onMousemove(containId, innerId) {
  // console.log("check", containId, innerId);
  // Init
  var container = document.getElementById(containId),
    inner = document.getElementById(innerId);

  // Mouse
  var mouse = {
    _x: 0,
    _y: 0,
    x: 0,
    y: 0,
    updatePosition: function (event) {
      var e = event || window.event;
      this.x = e.clientX - this._x;
      this.y = (e.clientY - this._y) * -1;
    },
    setOrigin: function (e) {
      this._x = e.offsetLeft + Math.floor(e.offsetWidth / 2);
      this._y = e.offsetTop + Math.floor(e.offsetHeight / 2);
    },
    show: function () {
      return "(" + this.x + ", " + this.y + ")";
    },
  };

  mouse.setOrigin(container);

  var counter = 0;
  var refreshRate = 10;
  var isTimeToUpdate = function () {
    return counter++ % refreshRate === 0;
  };

  var onMouseEnterHandler = function (event) {
    update(event);
  };

  var onMouseLeaveHandler = function () {
    inner.style = "";
  };

  var onMouseMoveHandler = function (event) {
    if (isTimeToUpdate()) {
      update(event);
    }
  };

  //----------------------------------------------------

  var update = function (event) {
    mouse.updatePosition(event);
    updateTransformStyle(
      (mouse.y / inner.offsetHeight / 2).toFixed(2),
      (mouse.x / inner.offsetWidth / 2).toFixed(2)
    );
  };

  var updateTransformStyle = function (x, y) {
    var style =
      "rotateX(" +
      x +
      "deg) skew(" +
      x +
      "deg) rotateY(" +
      y +
      "deg) skew(" +
      y +
      "deg)";
    inner.style.transform = style;
    inner.style.webkitTransform = style;
    inner.style.mozTranform = style;
    inner.style.msTransform = style;
    inner.style.oTransform = style;
  };

  //--------------------------------------------------------

  container.onmousemove = onMouseMoveHandler;
  container.onmouseleave = onMouseLeaveHandler;
  container.onmouseenter = onMouseEnterHandler;
}
export const Core = {
  get,
  post,
  getImage,
  getSvg,
  getImageSingle,
  onMousemove,
};
