<template>
  <div class="col-12 lg:col-4 p-b-10 md:p-3">
    <div
      class="flex align-items-center border-200 marketplace"
      style="
        border: 2px solid;
        border-radius: 40px;
        padding: 30px;
        height: 100%;
      "
    >
      <div v-if="data">
        <span class="font-weight-500
            font-size-18
            line-height-21
            font-color-text"
          >{{data.attributes.heading}}
        </span>
        <br />
        <br />
        <div class="mt-3 flex align-items-center">
          <p class="font-weight-400
            font-size-16
            line-height-24
            body-text-color">
            {{ data.attributes.description }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MenuCard",
  props: ["index", "data"],
  components: {},
  data() {
    return {
      value: 500,
      formatter1: "{value}\norders",
    };
  },
  methods: {},
  computed: {},
};
</script>

<style lang="scss">
.marketplace {
  background: #ffffff;
  /* Light-Gray-2 */

  border: 1px solid #e9ebf0 !important;
  border-radius: 40px;
  // padding: 5px;
  a {
    color: #3577f1;
  }
}
</style>
