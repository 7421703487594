<template>
  <div class="faq-accodian" id="">
    <!-- <div class=""> -->
      <div class="">
        <!-- <div class=""> -->
          <div class="">
            <h2
              class="mb-2 font-weight-700 font-size-32 line-height-38 font-color-text"
            >
              {{ reviews.attributes.label }}
            </h2>
            <div class="m-t-21">
              <span
                class="font-weight-400 font-size-16 line-height-24 body-text-color"
                >{{ reviews.attributes.description }}</span
              >
            </div>
          </div>
        <!-- </div> -->

        <Accordion
          :multiple="true"
          :activeIndex="[0]"
          class="m-t-28 faq-accodian"
        >
          <AccordionTab
            v-for="faq in list.data"
            :key="faq"
            :header="faq.attributes.heading"
          >
            <p>
              {{ faq.attributes.description }}
            </p>
          </AccordionTab>
        </Accordion>
      </div>
    <!-- </div> -->
  </div>
</template>
<script>
export default {
  name: "Faq",
  props: ["reviews", "list"],

  data() {
    return {};
  },
  methods: {},
  computed: {},
};
</script>

<style lang="scss">
.faq-accodian {
  .p-accordion-tab {
    border-bottom: 1px solid #dee2e6 !important;
  }
  .p-accordion .p-accordion-header .p-accordion-header-link {
    border: none !important;
    background: none !important;
    padding: 16px 0px;
  }
  .p-accordion-header-action {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .p-accordion-header-text {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #2d3748;
  }
  .pi-chevron-down:before {
    content: "\2212";
    font-weight: bolder;
    font-size: 20px;
  }
  .pi-chevron-right:before {
    content: "\002B";
    font-weight: bolder;
    font-size: 20px;
  }
  .p-accordion-content {
    border: none !important;
    background: transparent !important;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #718096;
    padding: 0px !important;
  }
  // p {
  //   padding-bottom: 11px;
  // }
}
</style>
