<template>
  <div class="preloader" v-if="loading">
    <div class="preloader-content"></div>
  </div>
  <div v-else>
    <HeroCommon :data="productPage.header" />
    <div>
      <platform :platform="platform" />
    </div>
    <div>
      <!-- :data="productChannel" :leftSection="productManage" -->
      <ProductCatalog 
      :data="productCatalog"
       />
    </div>

    <Reviews />
    <div>
      <inventoryHome :inventory="inventory" :data="everything" />
    </div>
    <Blogs />
  </div>
</template>

<script>
import platform from "../components/inventory/Platform.vue";
import Reviews from "../components/reviews/Reviews.vue";
import Blogs from "../components/blogs/Blogs.vue";
import inventoryHome from "../components/inventory/inventoryHome.vue";
import ProductCatalog from "../components/inventory/shippingSoftware.vue";
import HeroCommon from "../components/header/HeroCommon.vue";
import { BASE_URL } from "../config.js";
import { Core } from "../api.service";

export default {
  name: "PricingPlan",
  components: {
    platform,
    ProductCatalog,
    Reviews,
    Blogs,
    inventoryHome,
    HeroCommon,
  },

  data() {
    return {
      productPage: null,
      platform: null,
      productCatalog:null,
      // productManage: null,
      // productChannel: null,
      everything: null,
      loading: false,
      endpoint: `${BASE_URL}product-platform?populate=deep`,
      endpointProduct: `${BASE_URL}feature-product-catalog?populate=deep`,
      // endpointProductManage: `${BASE_URL}catalog-multichannel?populate=deep`,
      // endpointProductChannel: `${BASE_URL}track-control?populate=deep`,
      endpointFeatureShipping: `${BASE_URL}product-everything?populate=deep`,
      endpointProductPage: `${BASE_URL}product-page?populate=deep`,

      inventory: {
        imageGuide: "catalog_guide",
        heading: "Understanding Product Catalog ",
      },
    };
  },
  created() {
    this.getPlatform();
  },

  methods: {
    async getPlatform() {
      this.loading = true;
      await Core.get(this.endpoint).then((res) => {
        this.platform = res.data;
        // this.loading = false;
      });
      await Core.get(this.endpointProduct).then((res) => {
        this.productCatalog = res.data.attributes.feature_detail;
        // this.loading = false;
      });
      // await Core.get(this.endpointProductManage).then((res) => {
      //   this.productManage = res.data;
      //   // this.loading = false;
      // });
      // await Core.get(this.endpointProductChannel).then((res) => {
      //   this.productChannel = res.data;
      //   // this.loading = false;
      // });
      await Core.get(this.endpointFeatureShipping).then((res) => {
        this.everything = res.data.attributes.feature_everything;
        // this.loading = false;
      });
      await Core.get(this.endpointProductPage).then((res) => {
        this.productPage = res.data.attributes;
        // this.loading = false;
      });
      this.loading = false;
    },
  },
};
</script>

<style></style>
