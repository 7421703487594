<template>
  <div class="preloader" v-if="loading">
    <div class="preloader-content"></div>
  </div>
  <div v-else>
    <HeroCommon
      v-if="support"
      :data="support.header"
      :searchUrl="searchEndpoint"
      @serachList="searchData"
    />
    <div v-if="listSearch.key">
      <search-list :list="listSearch" />
    </div>
    <div v-else>
      <Article />
      <Menus />
    </div>

    <Fulfilment />
  </div>
</template>

<script>
import Menus from "../components/support/Menus.vue";
import Article from "../components/support/Article.vue";
import Fulfilment from "../components/fulfilment/Fulfilment.vue";
import HeroCommon from "../components/header/HeroCommon.vue";
import SearchList from "../components/shared/searchList.vue";

import { BASE_URL } from "../config.js";
import { Core } from "../api.service";
export default {
  name: "Support",
  data() {
    return {
      endpoint: `${BASE_URL}support-page?populate=deep`,
      searchEndpoint: `${BASE_URL}articles?filters[heading][$startsWith]=`,
      support: null,
      listSearch: [],
      loading: false,

      products: [
        {
          id: "1",
          name: "Ronald Richards",
          role: "Manager",
          description:
            "ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.",
          // image: "bamboo-watch.jpg",
          title: "Great platfrom!",
        },
        {
          id: "2",
          name: "Ronald Richards",
          role: "Manager",
          description:
            "ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.",
          // image: "black-watch.jpg",
          title: "Great platfrom!",
        },
        {
          id: "3",
          name: "Ronald Richards",
          role: "Manager",
          description:
            "ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.",
          // image: "blue-band.jpg",
          title: "Great platfrom!",
        },
        {
          id: "4",
          name: "Ronald Richards",
          role: "Manager",
          description:
            "ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.",
          // image: "blue-t-shirt.jpg",
          title: "Great platfrom",
        },
      ],
      responsiveOptions: [
        {
          breakpoint: "1024px",
          numVisible: 3,
          numScroll: 3,
        },
        {
          breakpoint: "600px",
          numVisible: 2,
          numScroll: 2,
        },
        {
          breakpoint: "480px",
          numVisible: 1,
          numScroll: 1,
        },
      ],
      support1: {
        image: "supportHeader",
        centerText: "Advice and answers from the Sell O Mate Team",
        fieldPlaceholder: "Search for articals...",
      },
    };
  },
  components: {
    Menus,
    Article,
    Fulfilment,
    HeroCommon,
    SearchList,
  },
  created() {
    this.getSupport();
  },
  methods: {
    async getSupport() {
      this.loading = true;
      await Core.get(this.endpoint).then((res) => {
        this.support = res.data.attributes;
        this.loading = false;
      });
    },
    searchData(data) {
       // console.log("data-data", data);
      this.listSearch = data;
    },
  },
};
</script>

<style lang="scss"></style>
