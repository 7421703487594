<template>
  <div class="preloader" v-if="loading">
    <div class="preloader-content"></div>
  </div>
  <div class="" v-else>
    <HeroCommon v-if="customerStory" :data="customerStory.header" />
    <CustomerStory />
  </div>
</template>

<script>
import { BASE_URL } from "../config.js";
import { Core } from "../api.service";
import CustomerStory from "../components/customerStory/allCustomerStory.vue";
import HeroCommon from "../components/header/HeroCommon.vue";

export default {
  data() {
    return {
      endpoint: `${BASE_URL}customer-story-section?populate=deep`,
      loading: false,
      customerStory: null,
    };
  },
  components: {
    CustomerStory,
    HeroCommon,
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      this.loading = true;
      await Core.get(this.endpoint).then((res) => {
        this.customerStory = res.data.attributes;
        this.loading = false;
      });
    },
  },
  computed: {},
};
</script>

<style lang="scss"></style>
