<template>
  <!-- <div
    style="width: 100%; padding: 37px 79px"
    class="mx-0 flex align-items-center justify-content-between absolute"
  > -->
  <router-link to="/" class="flex align-items-center">
    <svg
      width="188"
      height="41"
      viewBox="0 0 188 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_10012_4214)">
        <circle cx="21.0695" cy="19.9303" r="18.7917" fill="white" />
        <path
          d="M20.8409 0C9.33061 0 0 9.17798 0 20.5C0 31.822 9.33061 41 20.8409 41C32.3512 41 41.6818 31.822 41.6818 20.5C41.6818 9.17798 32.3512 0 20.8409 0ZM6.62405 12.1412L9.84048 8.97737L13.0867 8.99409V11.6647L9.40284 15.2883L6.62405 14.4608V12.1412ZM7.14242 20.4081L19.2901 8.10387H22.0689V10.8372L9.85323 22.853H7.13817V20.4081H7.14242ZM8.30238 32.8961V29.4648L26.0076 12.6845V8.10387H33.3752V15.6059H28.4677L11.4041 32.8961H8.30238ZM29.3048 34.4216H26.7851V32.3862L31.8244 27.8098H33.3752L33.6004 30.192L29.3048 34.4216ZM20.8409 34.4216H17.7392L17.0934 31.6256L30.146 18.5315H32.8611L34.0253 21.0726L20.8409 34.4216Z"
          fill="url(#paint0_linear_10012_4214)"
        />
        <path
          d="M56.9655 16.3489C56.8592 15.6718 56.2984 14.5183 54.4968 14.5183C53.2647 14.5183 52.4701 15.2789 52.4701 16.0814C52.4701 16.7793 52.9078 17.2934 53.9317 17.4815L55.916 17.8535C58.7373 18.3675 60.2031 20.1563 60.2031 22.2335C60.2031 24.5154 58.2571 26.8392 54.7263 26.8392C50.6261 26.8392 48.8713 24.2271 48.6631 22.1708L51.6968 21.4519C51.82 22.7893 52.742 23.9596 54.7688 23.9596C56.0647 23.9596 56.8592 23.341 56.8592 22.4383C56.8592 21.6985 56.2517 21.1635 55.2702 20.9797L53.2434 20.6077C50.7366 20.1354 49.2069 18.4887 49.2069 16.328C49.2069 13.5737 51.6756 11.6387 54.5181 11.6387C58.2189 11.6387 59.621 13.8203 59.9355 15.5255L56.9655 16.3489Z"
          fill="#2D3748"
        />
        <path
          d="M62.4634 26.5292V11.9473H71.8535V15.0108H65.769V17.7859H71.2884V20.6446H65.769V23.4406H71.8747V26.525H62.4634V26.5292Z"
          fill="#2D3748"
        />
        <path
          d="M74.6553 26.5292V11.9473H77.9609V23.4239H84.3598V26.5292H74.6553Z"
          fill="#2D3748"
        />
        <path
          d="M86.4868 26.5292V11.9473H89.7925V23.4239H96.1913V26.5292H86.4868Z"
          fill="#2D3748"
        />
        <path
          d="M97.3818 21.7368V18.9199H103.653V21.7368H97.3818Z"
          fill="#2D3748"
        />
        <path
          d="M113.066 11.6387C117.225 11.6387 120.76 14.6228 120.76 19.2494C120.76 23.8551 117.225 26.8392 113.066 26.8392C108.885 26.8392 105.35 23.8551 105.35 19.2494C105.35 14.6228 108.885 11.6387 113.066 11.6387ZM113.066 23.6294C115.114 23.6294 117.332 22.2711 117.332 19.2285C117.332 16.1859 115.114 14.8067 113.066 14.8067C110.996 14.8067 108.778 16.1859 108.778 19.2285C108.778 22.2711 110.996 23.6294 113.066 23.6294Z"
          fill="#2D3748"
        />
        <path
          d="M122.453 21.7368V18.9199H128.724V21.7368H122.453Z"
          fill="#2D3748"
        />
        <path
          d="M144.825 26.5292V16.8623L140.958 26.5292H138.282L134.415 16.9876V26.5292H131.279V11.9473H135.588L139.667 21.7981L143.533 11.9473H148.071V26.5292H144.825Z"
          fill="#2D3748"
        />
        <path
          d="M160.149 23.566H154.422L153.355 26.5292H149.884L155.467 11.9473H159.295L164.814 26.5292H161.22L160.149 23.566ZM155.505 20.607H159.1L157.324 15.5666L155.505 20.607Z"
          fill="#2D3748"
        />
        <path
          d="M171.918 15.0526V26.5292H168.617V15.0526H164.058V11.9473H176.498V15.0526H171.918Z"
          fill="#2D3748"
        />
        <path
          d="M178.51 26.5292V11.9473H187.896V15.0108H181.811V17.7859H187.331V20.6446H181.811V23.4406H187.917V26.525H178.51V26.5292Z"
          fill="#2D3748"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_10012_4214"
          x1="7.40278"
          y1="5.125"
          x2="31.0419"
          y2="36.0869"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#51C1EA" />
          <stop offset="1" stop-color="#8F59A4" />
        </linearGradient>
        <clipPath id="clip0_10012_4214">
          <rect width="187.917" height="41" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </router-link>
  <a
    class="cursor-pointer block lg:hidden text-700 p-ripple"
    v-ripple
    v-styleclass="{
      selector: '@next',
      enterClass: 'hidden',
      leaveToClass: 'hidden',
      hideOnOutsideClick: true,
    }"
  >
    <i class="pi pi-bars text-4xl"></i>
  </a>
  <div
    class="nav-bar surface-0 align-items-center flex-grow-1 justify-content-between hidden lg:flex w-full left-0 px-6 lg:px-0 z-2"
    style="top: 92%"
  >
    <ul
      class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row cursor-pointer"
    >
      <li class="main-nav">
        <a
          class="flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3 p-ripple"
          v-ripple
        >
          <span>Features</span>
        </a>
        <ul class="sub-nav">
          <li>
            <router-link to="/inventory" active-class="active" exact>
              <span>Inventory Management</span>
            </router-link>
          </li>
          <li><span>Product Catalog</span></li>
          <li><span>Order Management</span></li>
          <li><span>Shipping Software</span></li>
        </ul>
      </li>
      <li class="main-nav">
        <a
          class="flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3 p-ripple"
          v-ripple
        >
          <span>Resources</span>
        </a>
        <ul class="sub-nav">
          <li>
            <router-link to="/blog" active-class="active" exact>
              <span>Blog</span>
            </router-link>
          </li>
          <li><span>Support</span></li>
          <li><span>FAQ</span></li>
          <li><span>Customer Story</span></li>
        </ul>
      </li>
      <li>
        <router-link
          class="flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3 p-ripple"
          to="/integration"
          active-class="active"
          exact
        >
          <span>Integration</span>
        </router-link>
      </li>
      <li>
        <router-link
          class="flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3 p-ripple"
          to="/pricing"
          active-class="active"
          exact
        >
          <span>Pricing</span>
        </router-link>
      </li>
      <li>
        <router-link
          class="flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3 p-ripple"
          to="/about"
          active-class="active"
          exact
        >
          <span>About</span>
        </router-link>
      </li>
      <li>
        <router-link
          class="flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3 p-ripple"
          to="/contact-us"
          active-class="active"
          exact
        >
          <span>Contact Us</span>
        </router-link>
      </li>
    </ul>
    <div class="flex justify-content-between">
      <a
        class="login-button text-white-500"
        href="https://app.test2.sellersignals.com/users/login"
        >Login</a
      >
      <Button
        label="Start Free 30 Days Trial"
        class="yellow-button"
        @click="goToSignup()"
      ></Button>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
export default {
  name: "TopMenu",
  data() {
    return {};
  },
  components: {},
  methods: {
    goToSignup() {
      location.href = "https://app.test2.sellersignals.com/users/login";
    },
    goToIntegration() {
      this.$router.push({ name: "integration" });
    },
  },
  computed: {
    logoColor() {
      if (this.$appState.darkTheme) return "white";
      return "dark";
    },
  },
};
</script>

<style lang="scss">
#hero {
  background: linear-gradient(
    180deg,
    #f6f4ff 0%,
    rgba(246, 244, 255, 0.6) 92.51%,
    rgba(246, 243, 255, 0) 100%
  );

  height: 700px;
  overflow: hidden;
  margin-top: 7rem;
}

.sub-nav {
  display: none;
  position: absolute;
  background: #ffffff;
  border: 0.56px solid #e9ebf0;
  border-radius: 20px;
  min-width: 240px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  margin-left: 1rem;
  z-index: 1;
  li {
    list-style-type: none;
    padding: 12px 12px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    // text-align: center;
    color: #2d3748;
  }
}

.main-nav:hover .sub-nav {
  display: block;
}

.login-button {
  color: #ffffff;
  border: none;
  padding: 12px 25px;
  background-color: transparent;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}
.main-background {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
  background: linear-gradient(161.21deg, #8866ad 12.7%, #485096 119.14%);
  box-shadow: 0px 30px 60px rgba(76, 85, 157, 0.35);
}
.nav-bar {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #2d3748;
}
</style>
