<template>
  <div class="faq-accodian" v-if="data">
    <div class="pb-2 mx-auto">
      <!-- {{ data }} -->
      <div class="">
        <div class="row mx-auto border-botom-default p-b-16">
          <div class="">
            <h2
              class="font-weight-700 lg:text-4xl text-3xl line-height-38 font-color-text"
            >
              {{ data.name }}
            </h2>
            <div class="m-t-21">
              <span
                class="font-weight-400 text-base line-height-24 body-text-color"
                >{{ data.description }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <a>
          <div v-for="item in data.article_sub_categories.data" :key="item">
            <p
              class="mt-5 font-weight-700 text-base line-height-19 font-color-text font-weight-bold"
            >
              <img class="mr-2" src="/img/folder.png" alt="" />
              {{ item.attributes.title }}
            </p>
            <p
              class="flex align-items-center md:ml-5 ml-2 m-0 p-0 font-weight-400 text-sm line-height-24 font-color-text"
              
            >
            <!-- v-for="articles in item.attributes.articles.data"
              :key="articles" -->
              <img class="mr-2" src="/img/document.png" alt="" />

              <span class="cursor-pointer text-sm" @click="addProduct(articles.id)">
                <!-- {{ articles.attributes.section[0].heading }} -->
                {{ item.attributes.description }}
                </span
              >
            </p>
            <hr class="ml-5 p-0" />
          </div>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ProductManagement",
  props: ["id","data"],

  data() {
    return {
      list: [
        {
          title: "Add New Product",
          desc: "Add a New Product Via Channels",
        },
        {
          title: "Bulk Update Products",
          desc: "Bulk-Update and Custom Export Columns",
        },
        {
          title: "Product Images",
          desc: "Channel-Specific Images",
        },
        {
          title: "Repricing Products ",
          desc: "Channel-Specific Images",
        },
        {
          title: "Exporting Product Information",
          desc: "Export Mapping Tool",
        },
      ],
    };
  },
  methods: {
    addProduct(data) {
       // console.log('suport detail id data',data)
       // console.log('suport detail this  data',this.id)
      this.$router.push(`/support-detail/${this.id}/${data}`)
      this.$emit("addProduct", data);
    },
  },
  computed: {},
};
</script>

<style lang="scss">
.faq-accodian {
  .p-accordion-tab {
    border-bottom: 1px solid #dee2e6 !important;
  }
  .p-accordion .p-accordion-header .p-accordion-header-link {
    border: none !important;
    background: none !important;
    padding: 16px 0px;
  }
  .p-accordion-header-action {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .p-accordion-header-text {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #2d3748;
  }
  .pi-chevron-down:before {
    content: "\2212";
    font-weight: bolder;
    font-size: 20px;
  }
  .pi-chevron-right:before {
    content: "\002B";
    font-weight: bolder;
    font-size: 20px;
  }
  .p-accordion-content {
    border: none !important;
    background: transparent !important;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #718096;
    padding: 0px !important;
  }
  p {
    padding-bottom: 11px;
  }
}
</style>
