<template>
  <div class="grid">
    <!-- @click="activeBtn = index" -->
    <!-- {{ list }} -->
    <Button
      class="col-12 faq-button"
      aria-label="Google"
      v-for="(faq, index) in list"
      :key="faq"
      @click="faqSelect(faq.faq_lists)"
      :class="{ activeBtn: activeBtn === index }"
    >
      <span
        class="px-3 font-weight-500 font-size-14 line-height-16 font-color-text"
        >{{ faq.label }}</span
      >
    </Button>
  </div>
</template>
<script>
export default {
  props: ["list"],
  data() {
    return {
      activeBtn: 0,
      faqList: [
        {
          name: "Payment",
        },
        {
          name: "Integrations",
        },

        {
          name: "Features",
        },
        {
          name: "Implementation",
        },
        {
          name: "API",
        },

        {
          name: "Features",
        },
        {
          name: "Implementation",
        },
        {
          name: "Refund Policy",
        },
        {
          name: "Other Question",
        },
      ],
    };
  },

  mounted() {
    this.list.forEach((element) => {
      if (element.id == 2) {
        this.faqSelect(element.faq_lists);
      }
    });
  },
  methods: {
    faqSelect(data) {
      this.$emit("faqActive", data);
    },
  },
};
</script>
<style lang="scss">
.p-button:focus {
  box-shadow: none;
}
.activeBtn {
  span {
    color: #3577f1;
  }
}
.faq-button {
  background: #f3f6f9 !important;
  border-radius: 10px;
  height: 50px;
  border: none;
  margin-top: 12px;
  border: 1.8px solid #f3f6f9;
}
</style>
