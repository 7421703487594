<template>
  <div class="py-4 px-4 lg:px-8 my-5 md:my-4" v-if="data">
    <div class="text-center">
      <span
        class="body-text-color font-weight-400 text-base line-height-19 text-transform-uppercase"
        >{{ data.heading }}</span
      >
      <h2 class="mb-2 font-weight-700 text-4xl line-height-38">
        {{ data.sub_heading }}
      </h2>
    </div>
    <!-- <transition name="bounce"> -->
      <!-- <div v-if="showDiv" v-on:appear="handleScroll"> -->
        <div v-if="data" class="flex flex-row flex-wrap card-container justify-content-between">
          <BlogCard class="col-12 md:col-4"
            :data="blog"
            :index="index"
            v-for="(blog, index) in data.blogs.data"
            :key="index"
          />
        </div>
      <!-- </div> -->
    <!-- </transition> -->
    <div class="grid justify-content-around md:mt-0 md:p-3">
      <router-link to="/blog" active-class="active" exact>
        <div class="view-all-button col-12 lg:col-12 p-3 text-center">
          {{ data.button }}
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import BlogCard from "./BlogCard.vue";
import { BASE_URL } from "../../config.js";
import { Core } from "../../api.service";
export default {
  name: "Blogs",

  data() {
    return {
      scrollpx: 0,
      showDiv: false,
      endpointBlog: `${BASE_URL}blog-section?populate=deep`,
      data: null,
    };
  },
  components: { BlogCard },
  created() {
    this.getData();
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    async getData() {
      await Core.get(this.endpointBlog).then((res) => {
        this.data = res.data.attributes;
      });
    },
    handleScroll() {
      this.scrollpx = window.scrollY;
      // console.log(this.scrollpx);
      if (this.scrollpx > 5000) {
        this.showDiv = true;
      } else {
        this.showDiv = false;
      }
    },
  },
  computed: {},
};
</script>

<style lang="scss">
.section-blog-card {
  background: #ffffff;
  border: 0.561194px solid #edeaff !important;
  border-radius: 40px;
  // margin: 5px;
}
.section-blog-card:hover {
  background: #ffffff;
  /* colorful shadow */

  box-shadow: 0px 30px 60px rgba(76, 85, 157, 0.25);
  border-radius: 40px;
}
.blog-image {
  width: 100%;
  height: 40%;
  border-radius: 40px 40px 0px 0px;
}
// .view-all-button {
//   background: #485096;
//   border-radius: 100px;
//   font-family: "Roboto";
//   font-style: normal;
//   font-weight: 500;
//   font-size: 16px;
//   line-height: 19px;
//   cursor: pointer;
//   width: 15rem;
//   color: white !important;
// }

.bounce-enter-active {
  animation: bounce-in 2s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
</style>
