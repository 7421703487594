<template>
  <div class="py-4 px-4 lg:px-8 my-5 md:my-4" id="pricing">
    <div class="flex flex-row flex-wrap card-container justify-content-between">
      <CustomerStoryCard class="col-12 md:col-4" :data="blog" :index="index" v-for="(blog, index) in customerStory" :key="index" />
    </div>
  </div>
</template>
    
<script>
import { BASE_URL } from "../../config.js";
import { Core } from "../../api.service";
import CustomerStoryCard from "./CustomerStoryCard.vue";
export default {
  name: "Blogs",
  // props:['data'],
  data() {
    return {
      endpoint: `${BASE_URL}customer-stories?populate=deep`,

      customerStory: null,
    };
  },
  mounted(){
// console.log('data customer story',this.data)
  },
  created(){
    this.getData();

    // console.log('yess')
// console.log('data customer story',this.data)
  },
  components: { CustomerStoryCard },
  methods: {
    async getData() {
      await Core.get(this.endpoint).then((res) => {
        this.customerStory = res.data;
         // console.log("customerStory", this.customerStory);
      });
    },
  },
  computed: {},
};
</script>
    
    <style lang="scss">
.section-blog-card {
  background: #ffffff;
  border: 0.561194px solid #edeaff !important;
  border-radius: 40px;
  // margin: 5px;
}
.section-blog-card:hover {
  background: #ffffff;
  /* colorful shadow */

  box-shadow: 0px 30px 60px rgba(76, 85, 157, 0.25);
  border-radius: 40px;
}
.blog-image {
  width: 100%;
  height: 40%;
  border-radius: 40px 40px 0px 0px;
}
</style>
    