<template>
  <div class="py-8 px-4 lg:px-8 price-section-conatiner" id="pricing">
    <div v-if="data.length" class="grid md:mt-0">
      <MarketplaceCard
        :data="CardData"
        :index="index"
        v-for="(CardData, index) in data"
        :key="index"
      />
    </div>
  </div>
</template>

<script>
import MarketplaceCard from "./MarketplaceCard.vue";
export default {
  name: "Marketplaces",
  props: ["data"],
  components: { MarketplaceCard },
  data() {
    return {
      value: 500,
      formatter1: "{value}\norders",
      marketplace: [
        {
          title: "Amazon",
          desc: "Ecommerce",
        },
        {
          title: "Shopify",
          desc: "Ecommerce",
        },
        {
          title: "Walmart",
          desc: "Ecommerce",
        },
        {
          title: "US Postal Service",
          desc: "Logistics",
        },
        {
          title: "UPS",
          desc: "Logistics",
        },
        {
          title: "WooCommerce",
          desc: "Ecommerce",
        },
        {
          title: "Magento",
          desc: "Ecommerce",
        },
        {
          title: "Shopify",
          desc: "Ecommerce",
        },
      ],
    };
  },
  created() {
     // console.log(this.data);
  },
  methods: {},
  computed: {},
};
</script>

<style lang="scss"></style>
