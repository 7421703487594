<template>
  <div class="preloader" v-if="loading">
    <!-- <div class="preloader-content"></div> -->
  </div>
  <div v-else>
    <HeroCommon :data="inventoryPage.header" />
    <div>
      <platform :platform="platform" />
    </div>
    <div>
      <!-- :data="trackControl" -->
      <!-- :leftSection="inventoryManagement" -->
      <Inventorymanagement
        :data="inventoryManagement"
      />
    </div>
    <Reviews />
    <div>
      <inventoryHome :inventory="inventory" :data="everything" />
    </div>
    <Blogs />
  </div>
</template>

<script>
import platform from "../components/inventory/Platform.vue";
import Reviews from "../components/reviews/Reviews.vue";
import Blogs from "../components/blogs/Blogs.vue";
import inventoryHome from "../components/inventory/inventoryHome.vue";
import Inventorymanagement from "../components/inventory/shippingSoftware.vue";
import HeroCommon from "../components/header/HeroCommon.vue";

import { BASE_URL } from "../config.js";
import { Core } from "../api.service";

export default {
  name: "PricingPlan",
  components: {
    platform,
    Inventorymanagement,
    Reviews,
    Blogs,
    inventoryHome,
    HeroCommon,
  },

  data() {
    return {
      platform: null,
      inventoryManagement: null,
      // trackControl: null,
      everything: null,
      inventoryPage: null,
      loading: false,
      endpoint: `${BASE_URL}inventory-platform?populate=deep`,
      endpointInventory: `${BASE_URL}feature-inventory-management?populate=deep`,
      // endpointinventory: `${BASE_URL}inventory-management?populate=deep`,
      // endpointTrackControl: `${BASE_URL}track-control?populate=deep`,
      endpointFeatureShipping: `${BASE_URL}inventory-everything?populate=deep`,
      endpointInventoryPage: `${BASE_URL}inventory-page?populate=deep`,

      inventory: {
        imageGuide: "InventoryHome",
        heading: "Understanding Inventory Management",
      },
    };
  },
  created() {
    this.getPlatform();
  },

  methods: {
    async getPlatform() {
      this.loading = true;
      await Core.get(this.endpoint).then((res) => {
        this.platform = res.data;
        // this.loading = false;
      });
      await Core.get(this.endpointInventory).then((res) => {
        this.inventoryManagement = res.data.attributes.feature_detail;
        // this.loading = false;
      });
      // await Core.get(this.endpointinventory).then((res) => {
      //   this.inventoryManagement = res.data;
      //   // this.loading = false;
      // });
      // await Core.get(this.endpointTrackControl).then((res) => {
      //   this.trackControl = res.data;
      //   // this.loading = false;
      // });
      await Core.get(this.endpointFeatureShipping).then((res) => {
        this.everything = res.data.attributes.feature_everything;
        // this.loading = false;
      });
      await Core.get(this.endpointInventoryPage).then((res) => {
        this.inventoryPage = res.data.attributes;
        // this.loading = false;
      });
      this.loading = false;
    },
  },
};
</script>

<style>
/* .btl img{
  border-top: 10px solid #7069ad;
    border-top-left-radius: 25px;
    border-left: 10px solid #706aa6;
} */

</style>
