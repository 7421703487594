<template>
  <div class="">
    <HeroCommon v-if="integration" :data="integration.header" />
    <ConnectionRoadmap />
    <Reasons v-if="reason" :data="reason" />
    <Fulfilment />
    <Blogs />
  </div>
</template>

<script>
import Fulfilment from "../components/fulfilment/Fulfilment.vue";
import Blogs from "../components/blogs/Blogs.vue";
import ConnectionRoadmap from "../components/integration/ConnectionRoadmap.vue";
import Reasons from "../components/integration/Reasons.vue";
import HeroCommon from "../components/header/HeroCommon.vue";
import { BASE_URL } from "../config.js";
import { Core } from "../api.service";
export default {
  data() {
    return {
      endpoint: `${BASE_URL}integration?populate=deep`,
      endpointReason: `${BASE_URL}reason-section?populate=deep`,
      integration: null,
      reason: null,
    };
  },
  components: {
    Fulfilment,
    Blogs,
    ConnectionRoadmap,
    Reasons,
    HeroCommon,
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      await Core.get(this.endpoint).then((res) => {
        this.integration = res.data.attributes;
         // console.log("about", this.integration);
      });

      await Core.get(this.endpointReason).then((res) => {
        this.reason = res.data.attributes;
         // console.log("reason-section", this.reason);
      });
    },
  },
  computed: {},
};
</script>

<style lang="scss"></style>
