<template>
  <div class="col-12 lg:col-3 md:col-6 p-1 xl:p-3 lg:p-2 md:p-3">
    <div
      class="flex xl:align-items-center xl:flex-row lg:flex-column flex-row border-200 marketplace px-3 py-5"
      style="border: 2px solid; border-radius: 40px;"
    >
      <div class="col-3 text-left">
        <img :src="getImgUrl(data.attributes.image)" alt="" />
      </div>
      <div v-if="data" class="xl:col-9 lg:col-12 col-9 lg:ml-2 ml-0">
        <span
          class="font-weight-500 xl:text-xl text-lg line-height-21 font-color-text"
        >
          {{ data.attributes.title }}
        </span>
        <br />
        <span
          class="font-weight-400 lg:text-base text-sm line-height-24 body-text-color"
        >
          {{ data.attributes.type }}
        </span>
        <!-- <router-link class="" exact :to="'/integration-detail/' + data.id" -->
        <div class="mt-2 lg:text-base text-sm">
          <router-link class="" exact :to="'/integration-detail/' + data.attributes.slug"
            >{{ data.attributes.learnmore_button }}
            <img class="main-img" src="img/arrowTopRight.png" alt="" />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Core } from "../../api.service";

export default {
  name: "Marketplaces",
  props: ["index", "data"],
  components: {},
  data() {
    return {
      value: 500,
      formatter1: "{value}\norders",
    };
  },
  methods: {
    getImgUrl(pic) {
      if (pic) {
        return Core.getImage(pic);
      }
    },
  },
  computed: {},
  created() {
     // console.log("carddd", this.data);
  },
};
</script>

<style lang="scss">
.marketplace {
  background: #ffffff;
  /* Light-Gray-2 */

  border: 1px solid #e9ebf0 !important;
  border-radius: 40px;
  // padding: 5px;
  a {
    color: #3577f1;
  }
}
</style>
