<template>
  <div v-if="contactFaq" class="py-4 lg:px-8 md:px-4 px-1 mx-0 lg:mx-8" id="highlight">
    <div class="mt-8 pb-2 md:pb-8 mx-auto">
      <div class="row mx-auto">
        <div class="text-center">
          <h2
            class="font-weight-700 lg:text-4xl text-3xl line-height-38 font-color-text mb-2"
          >
            {{ contactFaq.heading }}
          </h2>
          <span
            class="font-weight-400 text-base line-height-24 body-text-color"
          >
            {{ contactFaq.sub_heading }}</span
          >
        </div>
      </div>
      <div class="row mt-8 contact-faq xl:px-8 lg:px-5 px-0">
        <Accordion :multiple="true" :activeIndex="[0]">
          <AccordionTab class="text-base"
            v-for="data in contactFaq.faq_lists.data"
            :key="data.id"
            :header="data.attributes.heading"
          >
            <p>
              {{ data.attributes.description }}
            </p>
          </AccordionTab>
        </Accordion>
      </div>
    </div>
  </div>
</template>
<script>
import { BASE_URL } from "../../config.js";
import { Core } from "../../api.service";
export default {
  name: "Faq",

  data() {
    return {
      endpoint: `${BASE_URL}contact-faq?populate=deep`,

      contactFaq: null,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      await Core.get(this.endpoint).then((res) => {
        this.contactFaq = res.data.attributes;
         // console.log("contactFaq", this.contactFaq);
      });
    },
  },
  computed: {},
};
</script>

<style lang="scss">
.contact-faq {
  // margin: 0px 12rem;
  .accordion-custom {
    i,
    span {
      vertical-align: middle;
    }

    span {
      // margin: 0 0.5rem;
    }
  }

  .p-accordion-tab {
    border-bottom: 1px solid #dee2e6 !important;
  }
  .p-accordion .p-accordion-header .p-accordion-header-link {
    border: none !important;
    background: none !important;
  }
  .p-accordion-header-action {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .p-accordion-header-text {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #2d3748;
  }
  .pi-chevron-down:before {
    content: "\2212";
    font-weight: bolder;
    font-size: 20px;
  }
  .pi-chevron-right:before {
    content: "\002B";
    font-weight: bolder;
    font-size: 20px;
  }
  .p-accordion-content {
    border: none !important;
    background: transparent !important;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #718096;
  }
}
// @media only screen and (max-width: 600px) {
//   .contact-faq {
//     margin: 0;
//   }
// }
</style>
