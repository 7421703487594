<template>
  <div class="preloader" v-if="loading">
    <div class="preloader-content"></div>
  </div>
  <div v-else>
    <HeroCommon :data="shippingSoftware.header" />
    <div>
      <platform :platform="platform" />
    </div>
    <div>
      <!-- :data="trackControl"
      :leftSection="inventoryManagement" -->
      <ShippingManagement
      :data="shippingManagement"
      />
    </div>

    <Reviews />
    <div>
      <inventoryHome :inventory="inventory" :data="everything" />
    </div>

    <Blogs />
  </div>
</template>

<script>
import platform from "../components/inventory/Platform.vue";
import Reviews from "../components/reviews/Reviews.vue";
import Blogs from "../components/blogs/Blogs.vue";
import inventoryHome from "../components/inventory/inventoryHome.vue";
import ShippingManagement from "../components/inventory/shippingSoftware.vue";
import HeroCommon from "../components/header/HeroCommon.vue";
import { BASE_URL } from "../config.js";
import { Core } from "../api.service";

export default {
  name: "PricingPlan",
  components: {
    platform,
    ShippingManagement,
    Reviews,
    Blogs,
    inventoryHome,
    HeroCommon,
  },

  data() {
    return {
      platform: null,
      // inventoryManagement: null,
      // trackControl: null,
      shippingManagement:null,
      everything: null,
      shippingSoftware: null,
      loading: false,
      endpoint: `${BASE_URL}shipping-platform?populate=deep`,
      endpointShipping: `${BASE_URL}feature-shipping-software?populate=deep`,
      // endpointinventory: `${BASE_URL}feature-shipping?populate=deep`,
      // endpointTrackControl: `${BASE_URL}feature-ship-order?populate=deep`,
      endpointFeatureShipping: `${BASE_URL}shipping-everything?populate=deep`,
      endpointShippingPage: `${BASE_URL}shipping-software-page?populate=deep`,

      inventory: {
        imageGuide: "guide_shipping",
        heading: "Understanding Shipping Software",
      },
    };
  },
  created() {
    this.getPlatform();
  },

  methods: {
    async getPlatform() {
      this.loading = true;

      await Core.get(this.endpoint).then((res) => {
        this.platform = res.data;
        // this.loading = false;
      });
      await Core.get(this.endpointShipping).then((res) => {
        this.shippingManagement = res.data.attributes.feature_detail;
        // this.loading = false;
      });
      // await Core.get(this.endpointinventory).then((res) => {
      //   this.inventoryManagement = res.data;
      //   // this.loading = false;
      // });
      // await Core.get(this.endpointTrackControl).then((res) => {
      //   this.trackControl = res.data;
      //   // this.loading = false;
      // });
      await Core.get(this.endpointFeatureShipping).then((res) => {
        this.everything = res.data.attributes.feature_everything;
        // this.loading = false;
      });
      await Core.get(this.endpointShippingPage).then((res) => {
        this.shippingSoftware = res.data.attributes;
        // this.loading = false;
      });
      this.loading = false;
    },
  },
};
</script>

<style></style>
