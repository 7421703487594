<template>
  <div
    class="py-4 px-4 lg:px-8 my-2 md:my-4 review-section-conatiner"
    v-if="data"
  >
    <div class="text-center mb-5">
      <h2
        class="body-text-color font-weight-400 text-base line-height-19 text-transform-uppercase body-text-color"
      >
        {{ data.heading }}
      </h2>
      <span
        class="font-weight-700 text-4xl line-height-38 text-center font-color-text"
      >
        {{ data.sub_heading }}
        <!-- What our
        <span style="color: #464e92">happy user</span> says -->
      </span>
    </div>
    <div class="absolute right-0 left-0 w-full text-center xl:block hidden">
      <img class="w-8 h-34rem" src="../../../public/img/bg-review.svg" alt="" />
    </div>

    <div class="w-12 align-items-center">
      <Carousel
        :value="data.user_reviews.data"
        :numVisible="3"
        :numScroll="3"
        :responsiveOptions="responsiveOptions"
        :showNavigators="true"
        :showIndicators="false"
        :circular="true"
        :autoplayInterval="data.user_reviews.data.length > 3 ? 3000 : false"
      >
        <template #item="slotProps">
          <div class="product-item" style="height: 90% !important">
            <div class="flex flex-column section-reviews-card xl:p-5 p-4 xl:m-4 lg:m-3 m-2" style="height: 100% !important">
              <div class="mb-3">
                <div class="grid justify-content-between m-1">
                  <span
                    class="font-weight-400 lg:text-lg text-base line-height-22 body-text-color"
                    >{{ slotProps.data.attributes.label }}</span
                  >
                  <span
                    class="font-weight-400 lg:text-lg text-base line-height-22 body-text-color"
                  >
                    <Rating
                      class="rating"
                      v-model="slotProps.data.attributes.ratings"
                      :cancel="false"
                      :stars="5"
                    />
                  </span>
                </div>
              </div>
              <div class="my-5" style="height: 100% !important">
                <span
                  class="font-weight-400 text-base line-height-22 body-text-color"
                  >{{ slotProps.data.attributes.review }}</span
                >
              </div>
              <img style="width: 100%" src="img/smallLineBreak.png" alt="" />
              <div class="my-2 grid">
                <div>
                  <!-- <img
                    :src="getImgUrl(slotProps.data.attributes.avatar)"
                    alt=""
                  /> -->
                </div>
                <div class="ml-5 mt-3">
                  <span
                    class="font-weight-500 lg:text-lg text-base line-height-21 font-color-text"
                  >
                    {{ slotProps.data.attributes.reviewer }} </span
                  ><br />
                  <span
                    class="font-weight-400 lg:text-lg text-base line-height-21 body-text-color"
                  >
                    {{ slotProps.data.attributes.reviewer_role }}
                  </span>
                </div>
              </div>
              <div></div>
            </div>
          </div>
        </template>
      </Carousel>
    </div>
  </div>
</template>

<script>
import { Core } from "../../api.service";
import { BASE_URL } from "../../config.js";

export default {
  name: "Reviews",

  data() {
    return {
      endpointReview: `${BASE_URL}review?populate=deep`,
      data: null,
      responsiveOptions: [
        {
          breakpoint: "1098px",
          numVisible: 2,
          numScroll: 1,
        },
        // {
        //   breakpoint: "1098px",
        //   numVisible: 2,
        //   numScroll: 1,
        // },
        {
          breakpoint: "780px",
          numVisible: 1,
          numScroll: 1,
        },
      ],
    };
  },
  components: {},
  created() {
    this.getData();
  },
  methods: {
    getImgUrl(pic) {
      if (pic.data) {
        return Core.getImage(pic);
      }
    },
    async getData() {
      await Core.get(this.endpointReview).then((res) => {
        this.data = res.data.attributes;
         // console.log("review-section", this.review);
      });
    },
  },
  computed: {
    // logoColor() {
    //   if (this.$appState.darkTheme) return "white";
    //   return "dark";
    // },
  },
};
</script>

<style lang="scss">
.section-reviews-card {
  background: #ffffff;
  /* Light-Gray-2 */

  border: 0.561194px solid #e9ebf0;
  border-radius: 40px;
  // margin: 20px;
  // padding: 30px;
}
.fa-star-o {
  color: orange;
}
.fa-star {
  color: #f8a401;
}
.fa-star-half-o {
  color: #f8a401;
}
.rating {
  position: sticky;
}
.p-carousel-indicator {
  button {
    width: 45px !important;
    height: 45px !important;
    border-radius: 100px !important;
  }
  button:before {
    content: "\f061";
  }
}
.p-carousel-prev {
  // button {
  width: 45px !important;
  height: 45px !important;
  border-radius: 100px !important;
  border: #95969b solid 1px !important;
  // }
  .pi-chevron-left:before {
    content: url(../../../public/img/arrow-left.svg) !important;
  }
}
.p-carousel-prev:hover {
  // button {
  background: #485096 !important;
  // }
  .pi-chevron-left:before {
    content: url(../../../public/img/arrow-left-white.svg) !important;
  }
}
.p-carousel-next {
  // button {
  width: 45px !important;
  height: 45px !important;
  border-radius: 100px !important;
  border: #95969b solid 1px !important;
  // }
  .pi-chevron-right:before {
    content: url(../../../public/img/arrow-right.svg) !important;
  }
}
.p-carousel-next:hover {
  background: #485096 !important;

  .pi-chevron-right:before {
    content: url(../../../public/img/arrow-right-white.svg) !important;
  }
}
@media screen and (max-width: 600px) {
  .p-carousel-prev,
  .p-carousel-next {
    // button {
    width: 25px !important;
    height: 25px !important;
  }
  .pi-chevron-left:before,
  .pi-chevron-right:before {
    font-size: 10px;
  }
}
</style>
