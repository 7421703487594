<template>
  <div class="">
    <Header />
    <router-view />
    <Footer />
    <ScrollTop />
  </div>
</template>

<script>
import Footer from "../components/footer/Footer.vue";
import Header from "../components/header/Header.vue";
// import store from "../store";

export default {
  name: "Website",
  data() {
    return {};
  },
  components: {
    Footer,
    Header,
  },
  created() {
    // store.dispatch("fetch/fetch");
  },
  methods: {
    fetchData() {},
  },
  computed: {},
};
</script>

<style lang="scss"></style>
