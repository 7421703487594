<template>
  <div class="preloader" v-if="loading">
    <div class="preloader-content"></div>
  </div>
  <div class="" v-else>
    <HeroCommon v-if="blog" :data="blog.header" />
    <BlogDetail v-if="blogDetail" :data="blogDetail" />
    <Blogs />
  </div>
</template>

<script>
import { BASE_URL } from "../config.js";
import { Core } from "../api.service";
import Blogs from "../components/blogs/Blogs.vue";
import BlogDetail from "../components/blogs/BlogDetail.vue";
import HeroCommon from "../components/header/HeroCommon.vue";

export default {
  data() {
    return {
      endpointBlog: `${BASE_URL}blog-section?populate=deep`,
      endpointBlogDetail: `${BASE_URL}blogs?filters[slug][$eq]=`,
      // endpointBlogDetail: `${BASE_URL}blogs/${blogId}?populate=deep`,
      blogId: null,
      blogDetail: null,
      loading: false,
    };
  },
  components: {
    Blogs,
    BlogDetail,
    HeroCommon,
  },
  methods: {
    async getData() {
      this.loading = true;
      await Core.get(this.endpointBlog).then((res) => {
        this.blog = res.data.attributes;
         console.log("blog-section", this.blog);
      });
      await Core.get(this.endpointBlogDetail+this.blogId+'&populate=deep').then(
        
      // await Core.get(`${BASE_URL}blogs/${this.blogId}?populate=deep`).then(
        (res) => {
          this.blogDetail = res.data[0].attributes;
           console.log("blog-blogDetail", this.blogDetail);
        }
      );
      this.loading = false;
    },
  },
  computed: {},
  created() {
    this.blogId = this.$route.params.id;
    console.log('this.blogId',this.blogId)
    this.getData();
  },
};
</script>

<style lang="scss"></style>
