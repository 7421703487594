<template>
  <div class="py-4 px-4 lg:px-5 mx-0 lg:mx-8">
    <div class="grid px-2">
      <div
        class="col-12 lg:col-6 flex flex-column lg:align-items-start lg:mt-7"
      >
        <span class="section-heading lg:text-4xl text-3xl">
          {{ data[0].heading }}
        </span>
        <span
          class="m-t-16 font-weight-400 text-base line-height-24 body-text-color"
        >
          {{ data[0].description }}
        </span>
        <div v-for="item in data[0].Short_description" :key="item">
          <p
            class="m-t-33 line-height-21 font-weight-500 text-lg font-color.text"
          >
            {{ item.heading }}
          </p>
          <p
            class="m-t-16 font-weight-400 text-base line-height-24 body-text-color"
          >
            {{ item.description }}
          </p>
        </div>
      </div>
      <div
        class="col-12 lg:col-6 flex flex-order-1 sm:flex-order-2 p-0 lg:text-left"
        @mousemove="Core.onMousemove('containerInventory', 'innerInventory')"
        id="containerInventory"
      >
        <img
          :src="Core.getImage(data[0].image)"
          class="mx-auto lg:w-10 w-9 md:w-7 lg:mt-4 mt-1"
          id="innerInventory"
        />
      </div>
    </div>
    <!-- <div class="grid px-2">
      <div
        class="col-12 lg:col-6 flex flex-column lg:align-items-start lg:mt-7"
      >
        <span class="section-heading lg:text-4xl text-3xl">
          {{ leftSection.attributes.feature_detail.heading }}
        </span>
        <span
          class="m-t-16 font-weight-400 text-base line-height-24 body-text-color"
        >
          {{ leftSection.attributes.feature_detail.description }}
        </span>
        <div
          v-for="item in leftSection.attributes.feature_detail
            .Short_description"
          :key="item"
        >
          <p
            class="m-t-33 line-height-21 font-weight-500 text-lg font-color.text"
          >
            {{ item.heading }}
          </p>
          <p
            class="m-t-16 font-weight-400 text-base line-height-24 body-text-color"
          >
            {{ item.description }}
          </p>
        </div>
      </div>
      <div
        class="col-12 lg:col-6 flex flex-order-1 sm:flex-order-2 p-0 lg:text-left"
        @mousemove="Core.onMousemove('containerInventory', 'innerInventory')"
        id="containerInventory"
      >
        <img
          :src="Core.getImage(leftSection.attributes.feature_detail.image)"
          class="mx-auto lg:w-10 w-9 md:w-7 lg:mt-4 mt-1"
          id="innerInventory"
        />
      </div>
    </div> -->
    <div class="grid px-2 mt-8 pb-2 md:pb-8">
      <div
        class="col-12 lg:col-6 flex flex-order-1 lg:flex-order-0 p-0 lg:text-left"
        v-if="data[1].image.data != null"
        @mousemove="
          Core.onMousemove('containerInventoryTrack', 'innerInventoryTrack')
        "
        id="containerInventoryTrack"
      >
        <img
          :src="Core.getImage(data[1].image)"
          class="mx-auto lg:w-10 w-9 md:w-7 lg:mt-4 mt-1"
          id="innerInventoryTrack"
        />
      </div>

      <div
        class="col-12 lg:col-6 flex flex-column lg:align-items-start lg:mt-7"
      >
        <span class="section-heading lg:text-4xl text-3xl">
          {{ data[1].heading }}
        </span>
        <span
          class="m-t-16 font-weight-400 text-base line-height-24 body-text-color"
        >
          {{ data[1].description }}
        </span>
        <div
          v-for="item in data[1].Short_description"
          :key="item"
        >
          <p
            class="m-t-33 line-height-21 font-weight-500 text-lg font-color.text"
          >
            {{ item.heading }}
          </p>
          <p
            class="m-t-16 font-weight-400 text-base line-height-24 body-text-color"
          >
            {{ item.description }}
          </p>
        </div>
      </div>
    </div>
    <!-- <div class="grid px-2 mt-8 pb-2 md:pb-8">
      <div
        class="col-12 lg:col-6 flex flex-order-1 lg:flex-order-0 p-0 lg:text-left"
        v-if="data.attributes.feature_detail.image.data != null"
        @mousemove="
          Core.onMousemove('containerInventoryTrack', 'innerInventoryTrack')
        "
        id="containerInventoryTrack"
      >
        <img
          :src="Core.getImage(data.attributes.feature_detail.image)"
          class="mx-auto lg:w-10 w-9 md:w-7 lg:mt-4 mt-1"
          id="innerInventoryTrack"
        />
      </div>

      <div
        class="col-12 lg:col-6 flex flex-column lg:align-items-start lg:mt-7"
      >
        <span class="section-heading lg:text-4xl text-3xl">
          {{ data.attributes.feature_detail.heading }}
        </span>
        <span
          class="m-t-16 font-weight-400 text-base line-height-24 body-text-color"
        >
          {{ data.attributes.feature_detail.description }}
        </span>
        <div
          v-for="item in data.attributes.feature_detail.Short_description"
          :key="item"
        >
          <p
            class="m-t-33 line-height-21 font-weight-500 text-lg font-color.text"
          >
            {{ item.heading }}
          </p>
          <p
            class="m-t-16 font-weight-400 text-base line-height-24 body-text-color"
          >
            {{ item.description }}
          </p>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { Core } from "../../api.service";

export default {
  name: "orderManagement",
  props: [
    // "data", "leftSection", 
  "data"],
  data() {
    return {
      Core: Core,
    };
  },
  components: {},
  methods: {
    getImgUrl(pic) {
      return require("../../../public/img/" + pic + ".svg");
    },
  },
  computed: {},
};
</script>

<style>
#containerInventory {
  perspective: 30px;
}

#inner {
  transition: transform 0.5s;
  -webkit-transition: transform 0.5s;
}
#containerInventory:hover {
  cursor: pointer;
}
#containerInventoryTrack {
  perspective: 30px;
}

#innerTrack {
  transition: transform 0.5s;
  -webkit-transition: transform 0.5s;
}
#containerInventoryTrack:hover {
  cursor: pointer;
}
</style>
