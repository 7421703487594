<template>
  <div class="preloader" v-if="loading">
    <div class="preloader-content"></div>
  </div>
  <div v-else>
  <HeroCommon :data="privacyPolicy.header" />

  <div style="padding: 7% 8%" class="privacy-policy">
    <!-- {{ privacyPolicy }} -->
    <div v-for="section in privacyPolicy.section" :key="section.id">
      <div class="mt-5">
        <span
          class="font-bold lg:text-3xl md:text-2xl text-xl line-height-4 font-color-text"
          >{{ section.heading }}</span
        >
      </div>

      <div
        v-for="description in section.description"
        :key="description.id"
        class="mt-3"
      >
        <p class="font-normal lg:text-lg md:text-base text-sm line-height-4 body-text-color">
          {{ description.description }}
        </p>
      </div>
      <!-- {{ section.description }} -->
      <div v-if="section.list">
      <div v-for="list in section.list" :key="list.id" class="mt-4">
            <span>{{list.heading}}</span>
            <br />
            <span
              class="font-normal lg:text-lg md:text-base text-sm line-height-4 body-text-color"
            >
            {{list.description}}
            </span>
          </div>
      </div>
    </div>
  </div>
  </div>
</template>
<script>
import HeroCommon from "../components/header/HeroCommon.vue";
import { BASE_URL } from "../config.js";
import { Core } from "../api.service";

export default {
  components: { HeroCommon },
  data() {
    return {
      Core: Core,
      endpoint: `${BASE_URL}privacy-policy?populate=deep`,
      privacyPolicy: [],
      loading: false,
      // policy: {
      //   heading: "Sell O Mate",
      //   content: "Privacy Policy",
      //   image: "",
      // },
    };
  },
  created() {
    this.getPriacyPolicy();
  },
  methods: {
    getPriacyPolicy() {
      this.loading = true;
      Core.get(this.endpoint).then((res) => {
        this.privacyPolicy = res.data.attributes;
         // console.log("this.privacyPolicy", this.privacyPolicy);
      this.loading = false;
      });
    },
  },
};
</script>
