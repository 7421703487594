<template>
  <div class="col-12 lg:col-4 lg:p-3 md:px-5 p-b-15">
    <div class="marketplace px-5 py-6" style="height: 100%">
      <div v-if="data">
        <span
          class="font-weight-700 text-lg line-height-21 font-color-text"
          >{{ data.attributes.heading }}
        </span>
        <br />
        <br />
        <router-link
          :to="'/support-detail/' + categoryId(item) + '/' + item.id"
          v-for="item in list"
          :key="item"
          class="cursor mt-3 flex align-items-center"
        >
          <!-- <span> -->
          <img class="mt-1" :src="'img/document.png'" alt="" />
          <!-- </span> -->
          <span
            class="hover-bold ml-3 font-weight-400 text-base line-height-24 font-color-text"
          >
            {{ item.attributes.heading }}
            <!-- {{ data.desc }} -->
          </span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MenuCard",
  props: ["index", "data"],
  components: {},
  data() {
    return {
      value: 500,
      formatter1: "{value}\norders",
      list: this.data.attributes.articles.data,
    };
  },
  created() {
     // console.log("data of artciels on support", this.data);
  },
  methods: {
    categoryId(item) {
       // console.log("itemmmmmm", item);
      return item?.attributes?.article_sub_category?.data?.attributes
        ?.article_category?.data?.id;
    },
  },
  computed: {},
};
</script>

<style lang="scss">
.marketplace {
  background: #ffffff;
  /* Light-Gray-2 */

  border: 1px solid #e9ebf0 !important;
  border-radius: 40px;
  border: 2px solid;
  // padding: 30px !important;
  // padding: 5px;
  a {
    color: #3577f1;
  }
}
</style>
