<template>
  <div class="">
    <Hero />
    <!-- <Features /> -->
    <PickAndPack />
    <Reviews />
    <PricingPlan />
    <Fulfilment />
    <Blogs />
  </div>
</template>

<script>
import Reviews from "../components/reviews/Reviews.vue";
import Fulfilment from "../components/fulfilment/Fulfilment.vue";
// import Features from "../components/features/Features.vue";
import PricingPlan from "../components/pricingPlan/PricingPlan.vue";
import Blogs from "../components/blogs/Blogs.vue";
import Hero from "../components/header/HeroContent.vue";
import PickAndPack from "../components/pickAndPack/PickAndPack.vue";
export default {
  data() {
    return {};
  },
  components: {
    Reviews,
    Fulfilment,
    // Features,
    PricingPlan,
    Blogs,
    Hero,
    PickAndPack,
  },
  methods: {},
  computed: {
    logoColor() {
      if (this.$appState.darkTheme) return "white";
      return "dark";
    },
  },
};
</script>

<style lang="scss"></style>
