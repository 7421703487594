<template>
  <div class="preloader" v-if="loading">
    <div class="preloader-content"></div>
  </div>
  <div v-else>
    <HeroCommon :data="termsAndCondition.header" />

    <div style="padding: 7% 8%" class="privacy-policy">
      <!-- {{ termsAndCondition }} -->
      <div v-for="section in termsAndCondition.section" :key="section.id">
        <div v-if="section.heading" class="mt-5">
          <span
            class="font-bold lg:text-3xl md:text-2xl text-xl line-height-4 font-color-text"
            >{{ section.heading }}</span
          >
        </div>

        <div
          v-for="description in section.description"
          :key="description.id"
          class="mt-3"
        >
          <p
            class="font-normal lg:text-lg md:text-base text-sm line-height-3 body-text-color"
          >
            {{ description.description }}
          </p>
        </div>
        <!-- {{ section.description }} -->
        <div v-if="section.list">
          <div v-for="list in section.list" :key="list.id" class="mt-4">
            <span>{{ list.heading }}</span>
            <br />
            <span
              class="font-normal lg:text-lg md:text-base text-sm line-height-3 body-text-color"
            >
              {{ list.description }}
            </span>
          </div>
        </div>
        <div v-if="section.sub_list">
          <div v-for="list in section.sub_list" :key="list.id" class="mt-4">
            <span
              class="font-normal lg:text-lg md:text-base text-sm line-height-3 body-text-color"
            >
              {{ list.text }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HeroCommon from "../components/header/HeroCommon.vue";
import { BASE_URL } from "../config.js";
import { Core } from "../api.service";

export default {
  components: { HeroCommon },
  data() {
    return {
      Core: Core,
      endpoint: `${BASE_URL}terms-and-condition?populate=deep`,
      termsAndCondition: [],
      loading: false,
      // policy: {
      //   heading: "Sell O Mate",
      //   content: "Privacy Policy",
      //   image: "",
      // },
    };
  },
  created() {
    this.getPriacyPolicy();
  },
  methods: {
    getPriacyPolicy() {
      this.loading = true;
      Core.get(this.endpoint).then((res) => {
        this.termsAndCondition = res.data.attributes;
         // console.log("this.termsAndCondition", this.termsAndCondition);
        this.loading = false;
      });
    },
  },
};
</script>
