<template>
  <div v-if="data" class="bg-hero">
    <div class="header m-0 hero-bg-image" :style="getImgUrl(data?.image)">
      <div class="bg-hero grid" style="width: 100%; margin: 0">
        <!-- class="col-12 lg:col-6 mt-0 hero-content-pad mw-100" -->
        <div
          class="col-12 lg:col-6 mw-100 pt-8"
          style="background-image: url(img/logo_shadow.png); background-repeat: no-repeat;background-position: -192px 68px !important;"
          :class="
            data.imageRight.data
              ? 'col-6 lg:col-6'
              : data.is_search
              ? 'col-12 lg:col-12 mt-8'
              : 'col-12 lg:col-12 mt-0'
          "
        >
          <div class="lg:p-5 p-4 mt-5 lg:m-4" v-if="!data.is_search">
            <h1
              class="font-weight-400 md:text-base text-sm line-height-19 text-transform-uppercase opacity-08 lg:pt-5 lg:px-5 lg:mx-5 px-0 mx-0"
              style="color: #f8f6ff"
            >
              {{ data.heading }}
            </h1>
            <p
              class="line-height-3 md:mt-5 font-weight-700 lg:text-4xl md:text-3xl text-2xl line-height-38 color-white hero-content lg:px-5 lg:mx-5 px-0 mx-0"
            >
              {{ data.content }}
            </p>
          </div>
          <div v-else class="ta-center md:pb-8 pb-4">
            <span
              class="font-weight-500 text-2xl line-height-28 ta-center color-white"
            >
              {{ data.heading }}
            </span>
            <div class="m-t-30 input-search">
              <span class="p-input-icon-left" style="width: 100%">
                <img
                  v-if="data.search_icon.data"
                  :src="Core.getImage(data.search_icon)"
                  class="mx-auto"
                  alt=""
                  style="
                    position: absolute;
                    top: 50%;
                    margin-top: -0.7rem;
                    left: 1.75rem;
                  "
                />
                <InputText
                  style="
                    background: #ffffff;
                    border-radius: 16px;
                    width: 100%;
                  "
                  type="text"
                  v-model="value3"
                  :placeholder="data.search_placeholder"
                  @change="searchArticles($event)"
                  class="search-input md:h-4rem h-3rem"
                />
              </span>
            </div>
          </div>
        </div>
        <div class="col-12 lg:col-6 mp-hero-image" v-if="data.is_image">
          <img
            :src="Core.getImage(data.imageRight)"
            class="bottom-0 lg:pt-4 lg:px-0 md:px-4 px-2"
            alt="hero screen"
            style="width: 100%; height: 100%"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { BASE_URL } from "../../config.js";
import { Core } from "../../api.service";
export default {
  props: ["data", "searchUrl"],
  data() {
    return {
      Core: Core,
      value3: null,
      endpoint: `${BASE_URL}articles?filters[heading][$startsWith]=`,
      searchList: [],
    };
  },
  methods: {
    getImgUrl(pic) {
      if (pic.data) {
        let bgImage = Core.getImage(pic);
        return {
          backgroundImage: `url("${bgImage}")`, //Core.getImage(leftSection.attributes.image)
        };
      }
      return false;
    },
    searchArticles(event) {
       // console.log("event-search", event.target._value);
      let url = this.searchUrl + event.target._value;
      Core.get(url).then((res) => {
        this.searchList = res.data;
         // console.log("seihowe", this.searchList);

        const search = {
          values: this.searchList,
          key: event.target._value,
        };
        this.$emit("serachList", search);
      });
    },
  },
};
</script>
<style>
.bg-hero {
  background: linear-gradient(
    165deg,
    #485096 16.32%,
    rgba(136, 102, 173, 0.66) 84.66%
  ) !important;
  mix-blend-mode: normal;
}
.p-input-icon-left > i:first-of-type {
  left: 1.75rem;
  color: #6c757d;
}
.p-input-icon-left > .p-inputtext {
  padding-left: 4rem;
}
::placeholder {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #2d3748;
}
.hero-content {
  /* width: 47%; */
}
.hero-bg-image {
  background-repeat: no-repeat;
  background-size: cover;
}
/* .hero-content-pad {
  padding-top: 8rem;
  padding-bottom: 5rem;
  height: 28rem;
} */
/* .p-0-9 {
  padding: 70px 9rem;
} */
.w-m {
}
.input-search {
  padding: 0% 20%;
}
@media only screen and (max-width: 700px) {
  .search-input::placeholder{
  font-size: 12px;
  }
}
@media only screen and (max-width: 600px) {
  /* .p-0-9 {
    padding: 0px 0px;
  } */
  /* .mp-l-12 {
    padding-left: 12px;
  } */
  .input-search {
    padding: 0;
  }
}
.shadow-logo-image {
  background-image: url(../../../public/img/logo_shadow.png);
  background-repeat: no-repeat;
  background-position: 192px 68px !important;
}
#container {
  perspective: 30px;
}

#inner {
  transition: transform 0.5s;
  -webkit-transition: transform 0.5s;
}
#container:hover {
  cursor: pointer;
}
/* .search-input::placeholder{
  font-size: 12px;
} */
</style>
