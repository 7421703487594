<template>
  <div class="preloader" v-if="loading">
    <div class="preloader-content"></div>
  </div>
  <div class="" v-else>
    <HeroCommon v-if="blog" :data="blog.header" />

    <Blogs />
  </div>
</template>

<script>
import { BASE_URL } from "../config.js";
import { Core } from "../api.service";
import Blogs from "../components/blogs/allBlogs.vue";
import HeroCommon from "../components/header/HeroCommon.vue";

export default {
  data() {
    return {
      loading: false,
      scrollpx: 0,
      showDiv: false,
      endpointBlog: `${BASE_URL}blog-section?populate=deep`,

      blog: null,
    };
  },
  components: {
    Blogs,
    HeroCommon,
  },

  created() {
    this.getData();
  },

  methods: {
    async getData() {
      this.loading = true;
      await Core.get(this.endpointBlog).then((res) => {
        this.blog = res.data.attributes;
        this.loading = false;
      });
    },
  },
  computed: {},
};
</script>

<style lang="scss"></style>
